import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Box, Grid } from "@material-ui/core";

import useFormContext from "../../FormContext";

import CustomSelect from "components/CustomSelect/CustomSelect";
import Button from "components/CustomButtons/Button";

import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

function BankDrawer({ open, setOpen, currentItem, options }) {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const { handleChange } = useFormContext();

  const handleSubmit = () => {
    handleChange({
      name: currentItem?.name,
      newValue: value,
      subName: currentItem?.subName,
      id: currentItem?.id,
      pos: currentItem?.pos,
    });
    setOpen(false);
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: classes.drawerPaper }}
    >
      <Box
        style={{
          backgroundColor: "#324149",
          margin: "-15px -15px 15px -15px",
          padding: "15px",
          color: "#fff",
          textAlign: "center",
          position: "relative",
        }}
      >
        <span>בנק אפשרויות</span>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomSelect
            label="הצעות ודוגמאות"
            name="examples"
            autocompleteProps={{
              id: `examples-${currentItem?.id}`,
              options: options,
              getOptionLabel: (option) => option,
              getOptionSelected: (option, value) => option == value,
              onChange: (e, newValue) => {
                setValue(newValue);
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            color="info"
            block={true}
            onClick={handleSubmit}
            disabled={!value}
          >
            <span>אישור</span>
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
}

BankDrawer.propTypes = {
  currentItem: PropTypes.object,
  options: PropTypes.array,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default BankDrawer;
