const stepsStyle = {
  root: {
    width: "100%",
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      fontWeight: "bold",
    },
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      marginTop: "5px",
      fontSize: "0.8rem",
    },
  },
};
const colorlibConnectorStyle = {
  alternativeLabel: {
    top: 15,
    right: "calc(50% + 15px) !important",
    left: "calc(-50% + 15px) !important",
  },
  active: {
    "& $line": {
      backgroundColor: "#324149",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#324149",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#ccc",
    borderRadius: 1,
  },
};

const colorlibStepIconStyles = {
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 30,
    height: 30,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.8rem",
    "& .MuiSvgIcon-root": {
      fontSize: "0.8rem",
    },
  },
  active: {
    background: "#e25050",
  },
  completed: {
    background: "#324149",
  },
};

export { stepsStyle, colorlibConnectorStyle, colorlibStepIconStyles };
