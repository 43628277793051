import React from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function CustomLoader() {
  return (
    <GridContainer
      justifyContent="center"
      alignItems="center"
      style={{ height: "60vh" }}
    >
      <GridItem>
        <CircularProgress />
      </GridItem>
    </GridContainer>
  );
}
