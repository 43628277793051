import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useAxios from "axios-hooks";
import ReactCodeInput from "react-verification-code-input";

import { CircularProgress, Typography } from "@material-ui/core";

import {
  isValidPhone,
  isValidCodeLong,
  validateCaptcha,
} from "helpers/functions.js";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";

export default function LoginSmsTab(props) {
  const { handleComplete, sessionToken, setSessionToken } = props;

  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [isSendPhone, setIsSendPhone] = useState(false);

  useEffect(() => {
    if (isSendPhone) {
      const input_0 = document.getElementById("verification-code-input-0");
      const input_1 = document.getElementById("verification-code-input-1");
      const input_2 = document.getElementById("verification-code-input-2");
      const input_3 = document.getElementById("verification-code-input-3");
      const input_4 = document.getElementById("verification-code-input-4");
      const input_5 = document.getElementById("verification-code-input-5");
      input_0.setAttribute("aria-required", "true");
      input_1.setAttribute("aria-required", "true");
      input_2.setAttribute("aria-required", "true");
      input_3.setAttribute("aria-required", "true");
      input_4.setAttribute("aria-required", "true");
      input_5.setAttribute("aria-required", "true");
    }
  }, [isSendPhone]);

  const [{ loading: loadingSubmitSms }, executeSubmitSms] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user/login/sms/v2",
      method: "POST",
    },
    { manual: true }
  );

  const handlePhoneSubmit = async () => {
    const token = await validateCaptcha("handlePhoneSubmitLoginSms");
    const result = await executeSubmitSms({
      data: {
        phone: phone,
        sessionToken: sessionToken,
        grecaptchaToken: token,
      },
    });
    setSessionToken(result.data.session_token);
    setIsSendPhone(true);
  };

  const [{ loading: loadingVerifyCode }, executeVerifyCode] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user/login/sms/code/v2",
      method: "POST",
    },
    { manual: true }
  );

  const handleCodeSubmit = async () => {
    const token = await validateCaptcha("handleCodeSubmitLoginSms");
    const result = await executeVerifyCode({
      data: {
        code: code,
        sessionToken: sessionToken,
        grecaptchaToken: token,
      },
    });

    setSessionToken(result.data.session_token);
    handleComplete(result.data.session_token);
  };

  return (
    <>
      {!isSendPhone ? (
        <div>
          <p>
            הקלד/י את מספר הטלפון הסלולרי שלך ואנו נדאג לשלוח לך קוד אימות למספר
            זה.
          </p>

          <CustomInput
            labelText="טלפון סלולר (10 ספרות ללא מקף)"
            id="phone"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: phone,
              type: "tel",
              onChange: (e) => {
                setPhone(e.target.value);
              },
              inputProps: {
                "aria-required": true,
              },
            }}
            error={phone != "" && !isValidPhone(phone)}
          />
          <div style={{ textAlign: "center", marginTop: "25px" }}>
            <Button
              disabled={loadingSubmitSms || !isValidPhone(phone)}
              onClick={() => handlePhoneSubmit()}
              color={"danger"}
            >
              <span>שליחה</span>
              {loadingSubmitSms && (
                <CircularProgress size={24} className="buttonProgress" />
              )}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <p>
            יש להזין את קוד 6 הספרות אשר נשלח אליך למספר הטלפון:{" "}
            <span style={{ fontWeight: "bold" }}>{phone}</span>
          </p>

          <Typography
            component="label"
            variant="srOnly"
            htmlFor="verification-code-input-0"
          >
            שדה 1
          </Typography>
          <Typography
            component="label"
            variant="srOnly"
            htmlFor="verification-code-input-1"
          >
            שדה 2
          </Typography>
          <Typography
            component="label"
            variant="srOnly"
            htmlFor="verification-code-input-2"
          >
            שדה 3
          </Typography>
          <Typography
            component="label"
            variant="srOnly"
            htmlFor="verification-code-input-3"
          >
            שדה 4
          </Typography>
          <Typography
            component="label"
            variant="srOnly"
            htmlFor="verification-code-input-3"
          >
            שדה 5
          </Typography>
          <Typography
            component="label"
            variant="srOnly"
            htmlFor="verification-code-input-3"
          >
            שדה 6
          </Typography>

          <ReactCodeInput
            id={"verification-code-input"}
            className={"verification-code-input"}
            fields={6}
            onComplete={(val) => setCode(val)}
            fieldWidth={45}
            fieldHeight={45}
          />

          <div style={{ marginTop: "25px", textAlign: "center" }}>
            <Button
              disabled={loadingVerifyCode || !isValidCodeLong(code)}
              onClick={() => handleCodeSubmit()}
              color={"success"}
            >
              <span>שליחה</span>
              {loadingVerifyCode && (
                <CircularProgress size={24} className="buttonProgress" />
              )}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

LoginSmsTab.propTypes = {
  handleComplete: PropTypes.func,
  sessionToken: PropTypes.string,
  setSessionToken: PropTypes.func,
};
