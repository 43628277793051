import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useAxios from "axios-hooks";
import { useParams } from "react-router";

import { validateCaptcha } from "helpers/functions";

import { CircularProgress } from "@material-ui/core";

import CustomDialog from "components/Custom/CustomDialog";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";

export default function CancelInterviewDialog(props) {
  const { open, setOpen } = props;
  const { id } = useParams();
  const [comment, setComment] = useState("");

  const [{ data, loading }, execute] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "my-processes/step/cancel-interview",
      method: "POST",
    },
    { manual: true }
  );

  useEffect(() => {
    if (data) {
      handleCloseDialog();
    }
  }, [data]);

  const handleCloseDialog = () => {
    setComment("");
    setOpen(false);
  };

  const handleSubmit = async () => {
    const token = await validateCaptcha("CancelInterviewDialoghandleSubmit");
    execute({
      data: {
        comment: comment,
        stepId: id,
        grecaptchaToken: token,
      },
    });
  };

  return (
    <>
      <CustomDialog
        fields={
          <CustomInput
            labelText="סיבה (*)"
            id="update-comment-dialog"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              name: "comment",
              type: "text",
              value: comment,
              onChange: (event) => setComment(event.target.value),
              multiline: true,
              rows: 5,
            }}
          />
        }
        submitButton={
          <Button
            onClick={() => handleSubmit()}
            disabled={!comment || loading}
            color="info"
          >
            <span>שליחה</span>
            {loading && (
              <CircularProgress size={24} className="buttonProgress" />
            )}
          </Button>
        }
        handleCloseDialog={handleCloseDialog}
        open={open}
        title="ביטול ראיון"
        description={<p>פרט/י את סיבת ביטול הראיון.</p>}
      ></CustomDialog>
    </>
  );
}

CancelInterviewDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
