import React from "react";
import PropTypes from "prop-types";
import useFormContext from "../../FormContext";
import CustomFreeSoloSelect from "components/CustomSelect/CustomFreeSoloSelect";

function SelectFieldFreeSolo({ fieldProps, itemProps }) {
  const { handleChange } = useFormContext();
  return (
    <CustomFreeSoloSelect
      label={fieldProps.label}
      name={fieldProps.name}
      autocompleteProps={{
        id: fieldProps.id,
        options: fieldProps.options,
        value: fieldProps.value,
        multiple: fieldProps.multiple,
        onInputChange: (e, newValue) => {
          handleChange({
            name: itemProps.name,
            newValue: newValue,
            subName: fieldProps.name,
            id: itemProps.id,
          });
        },
      }}
    />
  );
}

SelectFieldFreeSolo.propTypes = {
  fieldProps: PropTypes.object,
  itemProps: PropTypes.object,
  autocompleteProps: PropTypes.object,
};

export default SelectFieldFreeSolo;
