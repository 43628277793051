import { useMemo } from "react";
import axios from "axios";
import { useNotify } from "context/Notify";
import { useAuth } from "context/Auth";

const WithAxios = ({ children }) => {
  const { setNotification } = useNotify();
  const { handleLogout } = useAuth();

  useMemo(() => {
    // Add a request interceptor
    axios.interceptors.request.use(
      function (config) {
        //console.log("config", config);
        // Do something before request is sent
        const token = JSON.parse(sessionStorage.getItem("token"));
        //const skipUrls = ["friend/files"];
        //&& !skipUrls.includes(config.url)
        if (token) {
          if (config.data?.sessionToken) {
            config.headers.Authorization = `Bearer ${token},${config.data.sessionToken}`;
            delete config.data.sessionToken;
          } else {
            config.headers.Authorization = `Bearer ${token}`;
          }
        } else if (config.data?.sessionToken) {
          config.headers.Authorization = `Bearer ${config.data.sessionToken}`;
          delete config.data.sessionToken;
        }
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }, []);

  useMemo(() => {
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        // console.log(response.config.url, response.data);
        // Do something with response data like console.log, change header, or as we did here just added a conditional behaviour, to change the route or pop up an alert box, based on the reponse status
        if (response.status === 201) {
          setNotification({
            show: true,
            type: "success",
            msg: response?.data?.data?.message,
            onClose: null,
          });
        }
        return response.data;
      },
      function (error) {
        // Do something with response error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //console.log("location", window.location);
          // console.log("status", error.response.status);
          // console.log("headers", error.response.headers);

          if (error.response.status === 422 || error.response.status === 400) {
            setNotification({
              show: true,
              type: "error",
              msg: error?.response?.data?.data[0]?.message,
              onClose: null,
            });
          } else if (error.response.status === 405) {
            setNotification({
              show: true,
              type: "error",
              msg: error?.response?.data?.data[0]?.message,
              onClose: () => {
                window.location.reload();
              },
            });
          } else if (
            error.response.status === 401 ||
            error.response.status === 403
          ) {
            setNotification({
              show: true,
              type: "error",
              msg: "הסשן הסתיים. יש להתחבר שוב.",
              onClose: handleLogout(window.location.pathname),
            });

            // setToClearTimeout(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          //console.log("error.request", error.request);
          setNotification({
            show: true,
            type: "error",
            msg: "ארעה שגיאת מערכת. יש לנסות שוב מאוחר יותר.",
            onClose: null,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
          // setNotification({
          //   show: true,
          //   type: "error",
          //   msg: "ארעה שגיאת מערכת. יש לנסות שוב מאוחר יותר.",
          //   onClose: null,
          // });
        }
        //console.log(error.config);
        return Promise.reject(error);
      }
    );
  }, []);

  return children;
};

export default WithAxios;
