import React from "react";
import Button from "components/CustomButtons/Button";

export default function CustomButtonBot(props) {
  const { label, ...rest } = props;
  return (
    <Button
      {...rest}
      color="info"
      target="_blank"
      //href="https://wa.me/972524550358?text=היי לוגי, אשמח לעזרתך"
      href="https://api.whatsapp.com/send/?phone=972524550358&text=%D7%94%D7%99%D7%99+%D7%9C%D7%95%D7%92%D7%99%2C+%D7%90%D7%A9%D7%9E%D7%97+%D7%9C%D7%A2%D7%96%D7%A8%D7%AA%D7%9A&app_absent=0"
      title={label}
    >
      {label || "ראיון בוט"}
    </Button>
  );
}
