import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardFooter from "../Card/CardFooter.js";
import CardIcon from "../Card/CardIcon.js";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";

const useStyles = makeStyles(styles);

export default function CustomCardIconSummary(props) {
  const {
    headerTitle,
    headerValue,
    headerIcon,
    color,
    footerText,
    footerIcon,
  } = props;
  const classes = useStyles();

  return (
    <Card>
      <CardHeader color={color} stats icon>
        <CardIcon color={color}>{headerIcon}</CardIcon>
        <p className={classes.cardCategory}>{headerTitle}</p>
        <p
          className={classes.cardTitle}
          style={{ fontSize: "1.825em", lineHeight: "1.5em", margin: "0px" }}
        >
          {headerValue}
        </p>
      </CardHeader>
      {footerText && footerIcon ? (
        <CardFooter stats>
          <div className={classes.stats}>
            {footerIcon} {footerText}
          </div>
        </CardFooter>
      ) : (
        <CardFooter style={{ marginTop: "20px" }}></CardFooter>
      )}
    </Card>
  );
}

CustomCardIconSummary.propTypes = {
  headerTitle: PropTypes.string,
  headerValue: PropTypes.any,
  headerIcon: PropTypes.any,
  color: PropTypes.string,
  footerText: PropTypes.string,
  footerIcon: PropTypes.any,
};
