import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";

import { swr } from "helpers/functions";

import CustomCard from "components/Custom/CustomCard";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CardSkeleton from "components/Skeletons/CardSkeleton";

import AddSecondaryEmail from "./AddSecondaryEmail";
import SecondaryEmailsList from "./SecondaryEmailsList";
import PrimaryEmail from "./PrimaryEmail";

export default function CardEditEmail() {
  const [isDataLoadedFirstTime, setIsDataLoadedirstTime] = useState(true);
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [secondaryEmailsList, setSecondaryEmailsList] = useState([]);
  const [contentSection, setContentSection] = useState();

  const [{ data, loading }, refetch] = useAxios({
    url: process.env.REACT_APP_API_URL + "user-profile/edit-email",
  });

  useEffect(() => {
    if (data) {
      setContentSection(data.wp_content_section);
      setPrimaryEmail(data.cvneto_secondary_emails.primary_email);
      setSecondaryEmailsList(
        data.cvneto_secondary_emails.additional_emails || []
      );
    }
  }, [data]);

  useEffect(() => {
    if (data && isDataLoadedFirstTime) {
      setIsDataLoadedirstTime(false);
      swr(refetch);
    }
  }, [data, isDataLoadedFirstTime]);

  if (loading && isDataLoadedFirstTime) return <CardSkeleton />;

  return (
    <CustomCard
      title={contentSection?.main_title}
      description={contentSection?.subtitle}
    >
      <form>
        <GridContainer>
          <GridItem xs={12}>
            <PrimaryEmail primaryEmail={primaryEmail} />
          </GridItem>

          <SecondaryEmailsList
            setSecondaryEmailsList={setSecondaryEmailsList}
            secondaryEmailsList={secondaryEmailsList}
            setPrimaryEmail={setPrimaryEmail}
            primaryEmail={primaryEmail}
          />
          <GridItem xs={12}>
            <AddSecondaryEmail
              setSecondaryEmailsList={setSecondaryEmailsList}
              secondaryEmailsList={secondaryEmailsList}
            />
          </GridItem>
        </GridContainer>
      </form>
    </CustomCard>
  );
}
