import React, { useState } from "react";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import useAxios from "axios-hooks";

import useFormContext from "../../FormContext";

import MyDocx from "components/Custom/CreateDocx.js";
import CircularProgressButton from "components/Buttons/CircularProgressButton";

import CustomTooltip from "components/Custom/CustomTooltip";

import { makeStyles } from "@material-ui/core/styles";
import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

function DownloadDocxButton() {
  const classes = useStyles();
  const { handleGetState } = useFormContext();
  const [isLoading, setIsloading] = useState(false);

  const [, sendEmail] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "cv/send-email",
      method: "POST",
    },
    { manual: true }
  );

  const handleDownload = async () => {
    setIsloading(true);
    const doc = MyDocx({ data: handleGetState(), type: "download" });
    const blob = await Packer.toBlob(doc);

    let form_data = new FormData();
    form_data.append("file", blob);
    await sendEmail({ data: form_data });

    saveAs(blob, "cv.docx");

    setIsloading(false);
  };

  return (
    <div className={classes.sidebarButtonContainer}>
      <div className={classes.sidebarButtonTooltipContainer}>
        <CustomTooltip
          title={
            "כאן ניתן להוריד למחשבכם האישי את קורות החיים לקובץ מסוג DOCX, הנתמך על ידי הגרסאות האחרונות של Microsoft Word."
          }
          placement="bottom"
        />
      </div>
      <CircularProgressButton
        onClick={() => handleDownload()}
        color="blue"
        block
        isLoading={isLoading}
        title="הורדת קובץ DOCX"
      />
    </div>
  );
}

export default DownloadDocxButton;
