import React from "react";
import PropTypes from "prop-types";

import CustomCard from "components/Custom/CustomCard";

export default function CustomCardContent(props) {
  const { title, description, content, ...rest } = props;

  return (
    <CustomCard title={title} description={description} {...rest}>
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>
    </CustomCard>
  );
}

CustomCardContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.any,
  content: PropTypes.string,
};
