/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Person from "@material-ui/icons/Person";
import ListIcon from "@material-ui/icons/List";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import Fingerprint from "@material-ui/icons/Fingerprint";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";

import MainPage from "views/Main/Main.js";
import LoginPage from "views/Login/Login.js";
import RegisterPage from "views/Register/Register.js";
import UserProfile from "views/UserProfile/UserProfile";
import MyProcesses from "views/MyProcesses/MyProcesses";
import ProcessStep from "views/ProcessStep/ProcessStep";
import Friend from "views/Friend/Friend";
import Interview from "views/Interview/Interview";
import Faq from "views/Faq/Faq";
import CvGenerator from "views/CvGenerator/CvGenerator";
import Affiliate from "views/Affiliate/Affiliate";
import Jobs from "views/Jobs/Jobs";

import { FaUserFriends } from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";
import { GiFairyWand } from "react-icons/gi";

const dashboardRoutes = [
  {
    path: "/main",
    name: "Main Page",
    rtlName: "ראשי",
    icon: Fingerprint,
    component: MainPage,
    layout: "/p",
    fullPage: true,
  },
  {
    path: "/login",
    name: "Login Page",
    rtlName: "התחברות",
    icon: Fingerprint,
    component: LoginPage,
    layout: "/p",
    fullPage: true,
  },
  {
    path: "/register",
    name: "Register Page",
    rtlName: "הרשמה",
    icon: Fingerprint,
    component: RegisterPage,
    layout: "/p",
    fullPage: true,
  },
  {
    path: "/my-processes",
    name: "My Processes",
    rtlName: "התהליכים שלי",
    icon: ListIcon,
    component: MyProcesses,
    layout: "/u",
    sidebar: true,
  },
  {
    path: "/my-process/:id",
    name: "Process Step",
    rtlName: "פרטי התהליך",
    icon: ListIcon,
    component: ProcessStep,
    layout: "/u",
    sidebar: false,
  },
  {
    path: "/user",
    name: "User Profile",
    rtlName: "פרופיל אישי",
    icon: Person,
    component: UserProfile,
    layout: "/u",
    sidebar: true,
  },
  {
    path: "/friend",
    name: "Friend",
    rtlName: "חבר מביא חבר",
    icon: FaUserFriends,
    component: Friend,
    layout: "/u",
    sidebar: true,
  },
  {
    path: "/interview",
    name: "Interview",
    rtlName: "ראיון אחרון עם לוגי",
    icon: BsChatDots,
    component: Interview,
    layout: "/u",
    sidebar: true,
  },
  // {
  //   path: "/jobs",
  //   name: "Jobs",
  //   rtlName: "משרות דומות",
  //   icon: WorkOutlineIcon,
  //   component: Jobs,
  //   layout: "/u",
  //   sidebar: true,
  // },
  {
    path: "/cvgenerator",
    name: "Cv Generator",
    rtlName: "בניית קורות חיים",
    icon: GiFairyWand,
    component: CvGenerator,
    layout: "/u",
    sidebar: true,
  },
  // {
  //   path: "/affiliate",
  //   name: "Affiliate",
  //   rtlName: "תוכנית שותפים",
  //   icon: ThumbsUpDownIcon,
  //   component: Affiliate,
  //   layout: "/u",
  //   sidebar: true,
  // },
  {
    path: "/faq",
    name: "Faq",
    rtlName: "שאלות ותשובות",
    icon: LiveHelpIcon,
    component: Faq,
    layout: "/u",
    sidebar: true,
  },
];

export default dashboardRoutes;
