import {
  Document,
  Paragraph,
  TextRun,
  HeadingLevel,
  AlignmentType,
  SectionType,
} from "docx";
import { format } from "date-fns";

const MyDocx = (props) => {
  const { data, type } = props;

  let sections = [];

  addHeadSection(sections, data, type);

  addEducationsSection(sections, data, type);

  addCoursesSection(sections, data, type);

  addSkillsSection(sections, data, type);

  addWorkExperiencesSection(sections, data, type);

  addMilitaryServicesSection(sections, data, type);

  addVolunteeringSection(sections, data, type);

  addLanguagesSection(sections, data, type);

  addAllowRecommendationsSection(sections, data, type);

  let doc = new Document({
    creator: "Log on",
    description: "CV",
    title: "CV",
    sections: sections,
  });

  return doc;
};

const addHeadSection = (sections, data, type) => {
  let birthdate = "";
  if (data.privateDetails.birthdate.value) {
    const date = new Date(data.privateDetails.birthdate.value);
    birthdate = format(date, "dd/MM/yyyy");
  }

  let familyStatus = "";
  if (data.privateDetails.familyStatus.value)
    familyStatus = data.privateDetails.familyStatus.value;

  if (data.privateDetails.numOfKids.value > 0)
    familyStatus += `+${data.privateDetails.numOfKids.value}`;

  let firstLineArr = [];
  if (data.privateDetails.phone.value)
    firstLineArr.push(data.privateDetails.phone.value);
  if (data.privateDetails.email.value)
    firstLineArr.push(data.privateDetails.email.value);
  if (data.privateDetails.city.value)
    firstLineArr.push(data.privateDetails.city.value);

  const firstLineStr = firstLineArr.length == 0 ? "" : firstLineArr.join(" | ");

  let secondLineArr = [];
  if (familyStatus) secondLineArr.push(familyStatus);
  if (birthdate) secondLineArr.push(birthdate);
  if (
    data.privateDetails.securityClearance.value &&
    data.privateDetails.securityClearance.value != "לא"
  )
    secondLineArr.push(
      `סיווג בטחוני - ${data.privateDetails.securityClearance.value}`
    );
  if (data.privateDetails.idCardNumber.value)
    secondLineArr.push(data.privateDetails.idCardNumber.value);

  if (data.privateDetails.foreignCitizenship.value)
    secondLineArr.push(
      `אזרחות ${data.privateDetails.foreignCitizenship.value}`
    );
  const secondLineStr =
    secondLineArr.length == 0 ? "" : secondLineArr.join(" | ");

  let section = {
    children: [
      new Paragraph({
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.CENTER,
        bidirectional: true,
        spacing: {
          after: 100,
        },
        children: [
          Text({
            text: `${data.privateDetails.firstName.value} ${data.privateDetails.lastName.value}`,
            bold: true,
          }),
        ],
      }),
      new Paragraph({
        bidirectional: true,
        alignment: AlignmentType.CENTER,
        spacing: {
          after: 5,
        },
        children: [
          Text({
            text: firstLineStr,
          }),
        ],
      }),
      new Paragraph({
        bidirectional: true,
        alignment: AlignmentType.CENTER,
        spacing: {
          after: 100,
        },
        children: [
          Text({
            text: secondLineStr,
            rightToLeft: false,
          }),
        ],
      }),
      new Paragraph({
        bidirectional: true,
        alignment: AlignmentType.CENTER,
        spacing: {
          after: 100,
        },
        border: {
          bottom: {
            color: "auto",
            value: "single",
            size: 5,
          },
        },
      }),
      new Paragraph({
        spacing: {
          line: 276,
        },
        bidirectional: true,
        alignment: AlignmentType.CENTER,
        children: [Text({ text: data.privateDetails.summary.value })],
      }),
    ],
  };
  sections.push(section);
};

const addEducationsSection = (sections, data, type) => {
  if (!isEmptyObject(data.educations[0])) {
    let section = {
      properties: {
        type: SectionType.CONTINUOUS,
      },
      children: [],
    };

    const title = createTitle("השכלה");
    const courses = createEducations(data.educations, type);

    section.children.push(title);
    section.children.push(...courses);
    sections.push(section);
  }
};

const addCoursesSection = (sections, data, type) => {
  if (!isEmptyObject(data.courses[0])) {
    let section = {
      properties: {
        type: SectionType.CONTINUOUS,
      },
      children: [],
    };

    const title = createTitle("קורסים והשתלמויות");
    const courses = createCourses(data.courses, type);

    section.children.push(title);
    section.children.push(...courses);
    sections.push(section);
  }
};

const addWorkExperiencesSection = (sections, data, type) => {
  if (!isEmptyObject(data.workExperiences[0])) {
    let section = {
      properties: {
        type: SectionType.CONTINUOUS,
      },
      children: [],
    };

    const title = createTitle("ניסיון תעסוקתי");
    const workExperiences = createWorkExperiences(data.workExperiences, type);

    section.children.push(title);
    section.children.push(...workExperiences);
    sections.push(section);
  }
};

const addMilitaryServicesSection = (sections, data, type) => {
  if (!isEmptyObject(data.militaryService[0])) {
    let section = {
      properties: {
        type: SectionType.CONTINUOUS,
      },
      children: [],
    };

    const title = data.militaryService[0].type.value
      ? createTitle(`שירות ${data.militaryService[0].type.value}`)
      : createTitle(`שירות`);
    const roles = createMilitaryServiceRoles(data.militaryService, type);

    section.children.push(title);
    section.children.push(...roles);
    sections.push(section);
  }
};

// const addMilitaryServicesSection = (sections, data, type) => {
//   if (data.militaryService.roles.length > 0) {
//     let section = {
//       properties: {
//         type: SectionType.CONTINUOUS,
//       },
//       children: [],
//     };

//     const title = createTitle(data.militaryService.type);
//     const roles = createMilitaryServiceRoles(data.militaryService.roles, type);

//     section.children.push(title);
//     section.children.push(...roles);
//     sections.push(section);
//   }
// };

const addVolunteeringSection = (sections, data, type) => {
  if (!isEmptyObject(data.volunteering[0])) {
    let section = {
      properties: {
        type: SectionType.CONTINUOUS,
      },
      children: [],
    };

    const title = createTitle("התנדבות");
    const roles = createVolunteeringRoles(data.volunteering, type);

    section.children.push(title);
    section.children.push(...roles);
    sections.push(section);
  }
};

// const addVolunteeringSection = (sections, data, type) => {
//   if (data.volunteering.roles.length > 0) {
//     let section = {
//       properties: {
//         type: SectionType.CONTINUOUS,
//       },
//       children: [],
//     };

//     const title = createTitle("התנדבות");
//     const roles = createVolunteeringRoles(data.volunteering.roles, type);

//     section.children.push(title);
//     section.children.push(...roles);
//     sections.push(section);
//   }
// };

const addSkillsSection = (sections, data, type) => {
  if (data.privateDetails.skills.value.length > 0) {
    let section = {
      properties: {
        type: SectionType.CONTINUOUS,
      },
      children: [],
    };

    const skills = data.privateDetails.skills.value.join(", ");
    const title = createTitle("כלים וטכנולוגיות");
    const text = new Paragraph({
      bidirectional: true,
      children: [Text({ text: skills })],
    });

    section.children.push(title);
    section.children.push(text);
    sections.push(section);
  }
};

// const addSkillsSection = (sections, data, type) => {
//   if (data.skills.length > 0) {
//     let section = {
//       properties: {
//         type: SectionType.CONTINUOUS,
//       },
//       children: [],
//     };

//     const title = createTitle("כלים וטכנולוגיות");
//     const text = new Paragraph({
//       bidirectional: true,
//       children: [Text({ text: data.skills.join(", ") })],
//     });

//     section.children.push(title);
//     section.children.push(text);
//     sections.push(section);
//   }
// };

const addLanguagesSection = (sections, data, type) => {
  if (!isEmptyObject(data.languages[0]) && data.languages[0].title.value) {
    let section = {
      properties: {
        type: SectionType.CONTINUOUS,
      },
      children: [],
    };

    const title = createTitle("שפות");
    const text = createLanguagesText(data.languages, type);

    section.children.push(title);
    section.children.push(text);
    sections.push(section);
  }
};

const addAllowRecommendationsSection = (sections, data, type) => {
  if (data.privateDetails.allowRecommendations.value) {
    let section = {
      properties: {
        type: SectionType.CONTINUOUS,
      },
      children: [],
    };
    const text = createAllowRecommendationsText();

    section.children.push(text);
    sections.push(section);
  }
};

const createTitle = (text) => {
  return new Paragraph({
    bidirectional: true,
    spacing: {
      before: 200,
      after: 100,
    },
    children: [Text({ text: text, bold: true, underline: true })],
  });
};

const createEducations = (educations, type) => {
  return educations
    .map((education) => {
      const arr = [];

      const years = prepareYears(
        education.startYear.value,
        education.endYear.value
      );

      const text = prepareEducationText(education);

      arr.push(
        createYearsText(Text({ text: years }), Text({ text: text }), type)
      );

      if (!isEmptyAchievements(education.achievements)) {
        arr.push(...createBullets(education.achievements, type));
      }

      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);
};

const createCourses = (courses, type) => {
  return courses
    .map((course) => {
      const arr = [];

      const years = prepareYears(course.startYear.value, course.endYear.value);

      const text = prepareCourseText(course);

      arr.push(
        createYearsTitle(Text({ text: years }), Text({ text: text }), type)
      );

      if (!isEmptyAchievements(course.achievements)) {
        arr.push(...createBullets(course.achievements, type));
      }

      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);
};

const createWorkExperiences = (workExperiences, type) => {
  return workExperiences
    .map((workExperience) => {
      const arr = [];

      const years = prepareYears(
        workExperience.startYear.value,
        workExperience.endYear.value
      );

      const text = prepareWorkExperienceText(workExperience);

      arr.push(
        createYearsTitle(
          Text({ text: years }),
          Text({ text: text, bold: true, underline: true }),
          type
        )
      );

      if (!isEmptyAchievements(workExperience.achievements)) {
        arr.push(...createBullets(workExperience.achievements, type));
      }

      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);
};

// const createWorkExperiences = (workExperiences, type) => {
//   return workExperiences
//     .map((workExperience) => {
//       const arr = [];

//       const years = prepareYears(
//         workExperience.startYear,
//         workExperience.endYear
//       );

//       const text = prepareWorkExperienceText(workExperience);

//       arr.push(
//         createYearsTitle(
//           Text({ text: years, bold: true }),
//           Text({ text: text, bold: true, underline: true }),
//           type
//         )
//       );

//       arr.push(...createWorkExperiencesRoles(workExperience.roles, type));

//       return arr;
//     })
//     .reduce((prev, curr) => prev.concat(curr), []);
// };

const createWorkExperiencesRoles = (roles, type) => {
  return roles
    .map((role) => {
      const arr = [];

      if (roles.length > 1) {
        const years = prepareYears(role.startYear.value, role.endYear.value);

        const text = prepareRoleText(role);

        arr.push(
          createYearsTitle(
            Text({ text: years }),
            Text({ text: text, bold: true, underline: true }),
            type,
            2
          )
        );

        arr.push(...createBullets(role.achievements, type, 2));
      } else {
        arr.push(...createBullets(role.achievements, type));
      }

      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);
};

const createMilitaryServiceRoles = (roles, type) => {
  return roles
    .map((role) => {
      const arr = [];

      const years = prepareYears(role.startYear.value, role.endYear.value);

      const text = prepareMilitaryServiceRoleText(role);

      arr.push(
        createYearsTitle(
          Text({ text: years }),
          Text({ text: text, bold: true, underline: true }),
          type
        )
      );

      if (!isEmptyAchievements(role.achievements)) {
        arr.push(...createBullets(role.achievements, type));
      }

      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);
};

const createVolunteeringRoles = (roles, type) => {
  return roles
    .map((role) => {
      const arr = [];

      const years = prepareYears(role.startYear.value, role.endYear.value);

      const text = prepareVolunteeringRoleText(role);

      arr.push(
        createYearsText(Text({ text: years }), Text({ text: text }), type)
      );

      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);
};

const createLanguagesText = (languages, type) => {
  let children = [];
  languages.map((language, key) => {
    if (language.title.value) {
      if (key != 0) children.push(Text({ text: " | " }));
      children.push(Text({ text: language.title.value, bold: true }));
      if (language.title.value && language.level.value)
        children.push(Text({ text: " - " }));
      children.push(Text({ text: language.level.value }));
    }
  });
  return new Paragraph({
    bidirectional: true,
    children: children,
  });
};

const createAllowRecommendationsText = () => {
  let children = [];

  children.push(Text({ text: "* המלצות יינתנו על פי דרישה." }));

  return new Paragraph({
    bidirectional: true,
    spacing: {
      before: 200,
    },
    children: children,
  });
};

const createYearsTitle = (years, title, type, level = 1) => {
  let indent = type == "preview" ? { right: 0 } : { left: 0 };
  if (level == 2) {
    indent = type == "preview" ? { right: 650 } : { left: 600 };
  }

  return new Paragraph({
    indent: indent,
    bidirectional: true,
    spacing: {
      before: 200,
    },
    children: [years, title],
  });
};

const createYearsText = (years, title, type) => {
  return new Paragraph({
    bidirectional: true,
    spacing: {
      before: 50,
    },
    children: [years, title],
  });
};

const createBullets = (list, type, level = 1) => {
  const arr = [];

  let indent = type == "preview" ? { right: 1400 } : { left: 1600 };
  if (level == 2) {
    indent = type == "preview" ? { right: 2000 } : { left: 2200 };
  }

  list.forEach((item) => {
    if (item.value) {
      const text = type == "preview" ? `- ${item.value}` : `${item.value}`;
      arr.push(createBullet(text, indent));
    }
  });
  return arr;
};

const createBullet = (text, indent) => {
  return new Paragraph({
    indent: indent,
    bidirectional: true,
    bullet: {
      level: 0,
    },
    spacing: {
      before: 50,
    },
    children: [Text({ text: text })],
  });
};

const prepareCourseText = (course) => {
  let text = "";
  text += course.studyType.value ? course.studyType.value : "";
  text += course.specialization.value ? ` ${course.specialization.value}` : "";
  text += course.hours.value ? ` (${course.hours.value} שעות)` : "";
  text += course.institution.value ? `, ${course.institution.value}` : "";
  if (text) text += ".";
  return text;
};

const prepareEducationText = (education) => {
  let text = "";
  text += education.studyType.value ? education.studyType.value : "";
  text += education.degree.value ? ` ${education.degree.value}` : "";
  text += education.specialization.value
    ? ` עם התמחות ב${education.specialization.value}`
    : "";

  if (education.institution.value) {
    if (text) {
      text += `, ${education.institution.value}`;
    } else {
      text += education.institution.value;
    }
  }

  if (text) text += ".";
  return text;
};

const prepareWorkExperienceText = (workExperience) => {
  let text = "";
  text += workExperience.title.value ? " " + workExperience.title.value : "";
  text += workExperience.company.value
    ? ", " + workExperience.company.value
    : "";

  return text;
};

// const prepareWorkExperienceText = (workExperience) => {
//   let text = "";
//   text += workExperience.company;
//   text +=
//     workExperience.roles.length == 1
//       ? `, ${workExperience.roles[0].title}`
//       : "";
//   return text;
// };

const prepareRoleText = (role) => {
  let text = "";
  text += role.title.value;
  return text;
};

const prepareMilitaryServiceRoleText = (role) => {
  let text = "";
  text += role.title.value ? role.title.value : "";
  text += role.unit.value ? `, ${role.unit.value}` : "";
  text += role.degree.value ? ` - שחרור בדרגת ${role.degree.value}` : "";
  return text;
};

const prepareVolunteeringRoleText = (role) => {
  let text = "";
  text += role.organization.value ? role.organization.value : "";
  text += role.title.value ? ` - ${role.title.value}` : "";
  return text;
};

const prepareYears = (startYear, endYear) => {
  let years = "";
  years += startYear ? `${startYear}` : "";
  years += endYear ? ` - ${endYear}` : "";
  years +=
    endYear == "היום"
      ? `${"\xa0\xa0\xa0\xa0\xa0\xa0\xa0"}`
      : !endYear
      ? `${"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"}`
      : `${"\xa0\xa0\xa0\xa0\xa0"}`;
  return years;
};

const Text = (data) => {
  const obj = {
    text: "",
    rightToLeft: true,
  };
  Object.assign(obj, data);
  return new TextRun(obj);
};

const isEmptyAchievements = (achievementsArr) => {
  const filteredAchievementsArr = achievementsArr.filter(
    (achievement) => achievement.value != ""
  );
  return filteredAchievementsArr.length === 0 ? true : false;
};

const isEmptyObject = (obj) => {
  let isEmpty = true;

  Object.entries(obj).forEach(([key, item]) => {
    if (typeof item === "object" && item !== null && !Array.isArray(item)) {
      if (item.value) {
        isEmpty = false;
      }
    }
  });
  return isEmpty;
};

export default MyDocx;
