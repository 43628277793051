import React, { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import Moment from "moment";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { isMobile } from "react-device-detect";

import FetchTable from "components/Table/FetchTable";
import Button from "components/CustomButtons/Button";
import CustomCard from "components/Custom/CustomCard";
import TableSkeleton from "components/Skeletons/TableSkeleton";

export default function MyProcessesTable() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  const [{ data, loading }] = useAxios({
    url: process.env.REACT_APP_API_URL + "my-processes",
  });

  useEffect(() => {
    if (data) {
      setIsDataTableLoading(true);
      const tableDataOrdered = _.orderBy(
        data.cvneto_candidate_process,
        order[0],
        order[1]
      );
      const offset = page * limit;
      const partial = tableDataOrdered.slice(offset, offset + limit);
      setTableData(partial);
      setTotal(data.cvneto_candidate_process.length);
      setIsDataTableLoading(false);
    }
  }, [page, limit, order, data]);

  const updatePagination = async (page, limit) => {
    setLimit(limit);
    setPage(page);
  };

  const tableColumnConfig = [
    {
      Header: "תאריך עדכון",
      accessor: "date",
      sortable: true,
      Cell: ({ cell, column, row }) => {
        return Moment(cell.value).local().format("DD/MM/YYYY HH:MM");
      },
    },
    {
      Header: "שם החברה",
      accessor: "customer_name",
      sortable: true,
    },
    {
      Header: "שם המשרה",
      accessor: "position_name",
      sortable: true,
    },
    {
      Header: "שלב בתהליך",
      accessor: "step_friendly_name",
      sortable: true,
    },
    {
      Header: "פעולות",
      accessor: "actions",
      sortable: true,
      Cell: ({ cell, column, row }) => {
        return (
          <Button
            title={`פרטים נוספים אודות המשרה ${row.original.position_name}`}
            color="info"
            component={RouterLink}
            to={`/u/my-process/${row.original.pkey}`}
          >
            פרטים נוספים
          </Button>
        );
      },
    },
  ];

  if (loading) return <TableSkeleton />;

  return (
    <CustomCard
      titleTag={"h2"}
      title={
        data.cvneto_candidate_process.length == 0
          ? data.processes_table_section.main_title_no_data
          : data.processes_table_section.main_title
      }
      description={
        data.cvneto_candidate_process.length == 0
          ? data.processes_table_section.subtitle_no_data
          : isMobile
          ? data.processes_table_section.subtitle_mobile
          : data.processes_table_section.subtitle
      }
    >
      <FetchTable
        className={"my-processes-table-container"}
        columns={tableColumnConfig}
        total={total}
        data={tableData || []}
        updatePagination={updatePagination}
        page={page}
        limit={limit}
        loading={isDataTableLoading}
        setOrder={setOrder}
        order={order}
        summary={"טבלה המפרטת את התהליכים שעברת עד כה"}
      />
    </CustomCard>
  );
}
