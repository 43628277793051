import React, { useState } from "react";
import PropTypes from "prop-types";
import useAxios from "axios-hooks";

import { CircularProgress } from "@material-ui/core";

import { validateCaptcha } from "helpers/functions.js";

import CustomDialog from "components/Custom/CustomDialog";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";

export default function ContactUsDialog(props) {
  const { open, setOpen } = props;
  const [message, setMessage] = useState("");

  const [{ loading }, execute] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "faq/contact",
      method: "POST",
    },
    { manual: true }
  );

  const handleCloseDialog = () => {
    setMessage("");
    setOpen(false);
  };

  const handleSubmit = async () => {
    const token = await validateCaptcha("handleAddSecondaryEmail");
    await execute({
      data: {
        message: message,
        grecaptchaToken: token,
      },
    });
    handleCloseDialog();
  };

  return (
    <>
      <CustomDialog
        fields={
          <>
            <CustomInput
              labelText="הודעה (*)"
              id="contact-us-message-dialog"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                name: "message",
                type: "text",
                value: message,
                onChange: (event) => setMessage(event.target.value),
                multiline: true,
                rows: 5,
              }}
            />
          </>
        }
        submitButton={
          <Button
            onClick={() => handleSubmit()}
            disabled={!message || loading}
            color="info"
          >
            <span>שליחה</span>
            {loading && (
              <CircularProgress size={24} className="buttonProgress" />
            )}
          </Button>
        }
        handleCloseDialog={handleCloseDialog}
        open={open}
        title="יצירת קשר"
        description={<p>נא לפרט כיצד נוכל לעזור ואנו נדאג להשיב במהרה :)</p>}
      ></CustomDialog>
    </>
  );
}

ContactUsDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
