import { useEffect } from "react";

export const toFormData = (obj, form, namespace) => {
  let fd = form || new FormData();
  let formKey;

  for (let property in obj) {
    if (obj.hasOwnProperty(property) && obj[property]) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File, use recursivity.
      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString());
      } else if (typeof obj[property] === "object" && !(obj[property] instanceof File)) {
        toFormData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }

  return fd;
};

export const validateCaptcha = (action) => {
  return new Promise((res, rej) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute("6LeAy6sbAAAAAITNeBojW6_M41L7VU6SLM4QbJS-", {
          action: action,
        })
        .then((token) => {
          return res(token);
        })
        .catch((error) => {
          return rej(error);
        });
    });
  });
};

export const isValidCode = (value) => {
  var pattern = new RegExp(/^\d{4}$/);
  return !pattern.test(value) ? false : true;
};

export const isValidCodeLong = (value) => {
  var pattern = new RegExp(/^\d{6}$/);
  return !pattern.test(value) ? false : true;
};

export const isEmpty = (value) => {
  return value == "" ? true : false;
};

export const hasNoDigits = (value) => {
  var pattern = new RegExp(/\d/);
  return !pattern.test(value) ? true : false;
};

export const isValidPhone = (value) => {
  var pattern = new RegExp(/^\d{10}$/);
  return !pattern.test(value) ? false : true;
};

export const isValidEmail = (value) => {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  return !pattern.test(value) ? false : true;
};

export const isValidIdNumber = (value) => {
  var pattern = new RegExp(/^\d{9}$/);
  return !pattern.test(value) ? false : true;
};

export const swr = async (refetch) => {
  try {
    await refetch();
  } catch (err) {
    // console.log("refetch error", err);
  }
};

export const isCellPhone = (str) => {
  return /^(\d{3}())?(-)?\d{7}$/.test(str);
};

export const bbb = (props) => {
  console.log("props", props);
  const { data, type, options, ref } = props;

  const html = `<div id="content" direction="rtl" lang="he">
    <p>${data.summary}</p>
    <ul>
      <li>
        הובלת מיזם להקמת מיזם Online לשיווק תכשיטים מאפיון, פיתוח, עיצוב ועד
        השקה, שיווק וניהול.
      </li>
      <li>אחריות כוללת לעיצוב, Usability, בחירת טכנולוגיות פיתוח, תכנות.</li>
      <li>
        שיווק וקידום האתר במנועי חיפוש, Affiliates, אופטימיזציה, קישור למנועי
        ופלטפורמות מכירה.
      </li>
      <li>
        ביצוע Back Office, מיצובו כאתר פעיל, ביצוע מכירות בפועל ורווחיות.
      </li>
      <li>ניהול צוות מתכנתים, מחלקת SEO, Content ועוד.</li>
      <li>ניהול ישיר ומטריציוני של סה"כ 15 איש.</li>
      <li>ביצוע בדיקות ברמת Client, Serve.</li>
    </ul>
  </div>`;
  return html;
};

export const generateYearsOptions = (back = 60) => {
  const year = new Date().getFullYear();
  const years = Array.from({ length: back }, (v, i) => {
    const currentYear = year - back + i + 1;
    return { label: currentYear.toString(), value: year - back + i + 1 };
  });
  years.push({ label: "היום", value: "היום" });
  return years;
};

export const useScript = (url, selector = "head", async = true) => {
  useEffect(() => {
    const element = document.querySelector(selector);
    const script = document.createElement("script");
    script.src = url;
    script.async = async;
    element.appendChild(script);
    return () => {
      element.removeChild(script);
    };
  }, [url]);
};

// import swr function
//import { swr } from "helpers/functions";

// flag for call swr only one time after data loaded
//const [isDataLoadedFirstTime, setIsDataLoadedirstTime] = useState(true);

// call api
// const [{ data, loading }, refetch] = useAxios({
//   url: process.env.REACT_APP_API_URL + "faq/subjects",
// });

// call swr
//useEffect(() => {
//   if (data && !isDataLoadedFirstTime) {
//     setIsDataLoadedirstTime(true);
//     swr(refetch);
//   }
// }, [data, isDataLoadedFirstTime]);

// if (loading && !isDataLoadedFirstTime) return <FaqSubjectsSkeleton />;

// OR just skip cache totally
// const [{ data, loading }] = useAxios(
//   {
//     url: process.env.REACT_APP_API_URL + "interview/chat",
//   },
// { useCache: false } - for skip cache
// );
