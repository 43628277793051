import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import cvGeneratorStyle from "assets/jss/material-dashboard-react/components/circularProgressButtonStyle";
const useStyles = makeStyles(cvGeneratorStyle);

function CircularProgressButton(props) {
  const classes = useStyles();
  const { title, isLoading, ...rest } = props;
  return (
    <Button {...rest} title={title} disabled={isLoading}>
      <span>{title}</span>
      {isLoading && <CircularProgress size={24} className={classes.progress} />}
    </Button>
  );
}

CircularProgressButton.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default CircularProgressButton;
