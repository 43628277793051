import React from "react";
import PropTypes from "prop-types";
import useAxios from "axios-hooks";

import CustomDialog from "components/Custom/CustomDialog";

export default function TermsDialog(props) {
  const { open, setOpen } = props;

  const [{ data }] = useAxios({
    url: process.env.REACT_APP_API_URL + "faq/terms",
  });

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomDialog
        fields={
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: data?.wp_faq_terms || "",
              }}
            ></div>
          </>
        }
        handleCloseDialog={handleCloseDialog}
        open={open}
        title="תנאי השימוש"
        description=""
        closeButtonLabel="סגירה"
      ></CustomDialog>
    </>
  );
}

TermsDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
