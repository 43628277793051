import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";
import customTooltipStyle from "assets/jss/material-dashboard-react/components/customTooltipStyle.js";
const useStyles = makeStyles(customTooltipStyle);

function CustomTooltip({ title, placement, iconButtonStyle }) {
  const classes = useStyles();
  return (
    <Tooltip
      title={title}
      placement={placement || "top"}
      arrow
      leaveTouchDelay={3000}
      enterTouchDelay={50}
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.tooltipArrow,
      }}
    >
      <IconButton aria-label={title} size="small" style={iconButtonStyle}>
        <HelpIcon classes={{ root: classes.svgIconRoot }} />
      </IconButton>
    </Tooltip>
  );
}

CustomTooltip.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.string,
  iconButtonStyle: PropTypes.object,
};

export default CustomTooltip;
