import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

import locale from "date-fns/locale/he";

import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomDatePicker(props) {
  const classes = useStyles();
  const {
    value,
    onChange,
    formControlProps,
    labelText,
    labelProps,
    id,
    inputProps,
    error,
    success,
    ...rest
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined,
  });
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
    >
      {labelText !== undefined ? (
        <InputLabel className={classes.labelRoot} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <DatePicker
        selected={value}
        onChange={(date) => onChange(date)}
        locale={locale}
        dateFormat="dd/MM/yyyy"
        customInput={
          <Input
            style={{
              marginTop: "16px",
              width: "100%",
            }}
            classes={{
              root: marginTop,
              disabled: classes.disabled,
              underline: underlineClasses,
            }}
            id={id}
            {...inputProps}
          />
        }
        {...rest}
      />
    </FormControl>
  );
}

CustomDatePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};
