import React from "react";
import StickyBox from "react-sticky-box";

import CustomCard from "components/Custom/CustomCard";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import SaveButton from "../Buttons/SaveButton";
import DownloadDocxButton from "../Buttons/DownloadDocxButton";
import DownloadPdfButton from "../Buttons/DownloadPdfButton";
import PreviewButton from "../Buttons/PreviewButton";
import ClearButton from "../Buttons/ClearButton";

function FormSidebar() {
  return (
    <StickyBox offsetTop={100}>
      <CustomCard titleTag={"h2"} title={"פעולות"} description={""}>
        <GridContainer>
          <GridItem container justifyContent="flex-end">
            <PreviewButton />
            <SaveButton />
            <DownloadDocxButton />
            <DownloadPdfButton />
            <ClearButton />
          </GridItem>
        </GridContainer>
      </CustomCard>
    </StickyBox>
  );
}

export default FormSidebar;
