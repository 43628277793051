import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useAuth } from "context/Auth";

import CustomLoader from "components/Custom/CustomLoader";

function PrivateRoute({ component: Component, ...rest }) {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() === undefined ? (
          <CustomLoader />
        ) : isAuthenticated() === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/p/main" />
        )
      }
    />
  );
}

export default PrivateRoute;
