import React from "react";
import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

export default function TableSkeleton() {
  return (
    <Box style={{ marginBottom: "30px" }}>
      <Skeleton variant="rect" height={50} />
      <table width="100%" className="table-skeleton">
        <thead>
          <tr>
            <th>
              <Skeleton variant="rect" height={50} />
            </th>
            <th>
              <Skeleton variant="rect" height={50} />
            </th>
            <th>
              <Skeleton variant="rect" height={50} />
            </th>
            <th>
              <Skeleton variant="rect" height={50} />
            </th>
            <th>
              <Skeleton variant="rect" height={50} />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
            <td>
              <Skeleton variant="rect" />
            </td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
}
