import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import useGaTracker from "hooks/useGaTracker";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "context/Auth";

// core components
import GridContainer from "componentsPro/Grid/GridContainer.js";
import GridItem from "componentsPro/Grid/GridItem.js";
import Card from "componentsPro/Card/Card.js";
import CardBody from "componentsPro/Card/CardBody.js";
import CardHeader from "componentsPro/Card/CardHeader.js";
import CardFooter from "componentsPro/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import TermsMsg from "./TermsMsg";
import TermsDialog from "components/Dialogs/TermsDialog.js";
import PrivacyDialog from "components/Dialogs/PrivacyDialog.js";

import RegisterSteps from "./RegisterSteps";

const useStyles = makeStyles(styles);

export default function Register() {
  useGaTracker();
  const { isAuthenticated } = useAuth();
  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [openPrivacyDialog, setOpenPrivacyDialog] = useState(false);
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();
  if (isAuthenticated()) {
    return <Redirect to="/u/my-processes" />;
  }

  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={5}>
          <Card login className={classes[cardAnimaton]}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="success"
            >
              <h1 className={classes.cardTitle}>הרשמה</h1>
              <p>אימות דו-שלבי</p>
            </CardHeader>
            <CardBody>
              <RegisterSteps />
            </CardBody>
            <CardFooter>
              <TermsMsg
                setOpenTerms={setOpenTermsDialog}
                setOpenPrivacy={setOpenPrivacyDialog}
              />
              <TermsDialog
                open={openTermsDialog}
                setOpen={setOpenTermsDialog}
              />
              <PrivacyDialog
                open={openPrivacyDialog}
                setOpen={setOpenPrivacyDialog}
              />
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
