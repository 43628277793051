import React from "react";

import useFormContext from "../../FormContext";
import {
  familyStatusOptions,
  securityClearanceOptions,
  numOfKidsOptions,
} from "../../options";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import DatePickerField from "../Fields/DatePickerField";
import InputField from "../Fields/InputField";
import SelectField from "../Fields/SelectField";

export default function PrivateDetails() {
  const { handleGetState } = useFormContext();
  const { privateDetails } = handleGetState();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <InputField
          fieldProps={{
            label: "שם פרטי (*)",
            name: "firstName",
            id: privateDetails.firstName.id,
            value: privateDetails.firstName.value,
            type: "text",
            required: true,
            error: privateDetails.firstName.error,
          }}
          itemProps={{
            id: privateDetails.id,
            name: "privateDetails",
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <InputField
          fieldProps={{
            label: "שם משפחה (*)",
            name: "lastName",
            id: privateDetails.lastName.id,
            value: privateDetails.lastName.value,
            type: "text",
            required: true,
            error: privateDetails.lastName.error,
          }}
          itemProps={{
            id: privateDetails.id,
            name: "privateDetails",
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <InputField
          fieldProps={{
            label: "כתובת מייל (*)",
            name: "email",
            id: privateDetails.email.id,
            value: privateDetails.email.value,
            type: "email",
            required: true,
            error: privateDetails.email.error,
          }}
          itemProps={{
            id: privateDetails.id,
            name: "privateDetails",
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <InputField
          fieldProps={{
            label: "מספר סלולר (*)",
            name: "phone",
            id: privateDetails.phone.id,
            value: privateDetails.phone.value,
            type: "tel",
            required: true,
            error: privateDetails.phone.error,
          }}
          itemProps={{
            id: privateDetails.id,
            name: "privateDetails",
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <DatePickerField
          fieldProps={{
            label: "תאריך לידה (dd/mm/yyyy)",
            name: "birthdate",
            id: privateDetails.birthdate.id,
            value: privateDetails.birthdate.value,
          }}
          itemProps={{
            id: privateDetails.id,
            name: "privateDetails",
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <InputField
          fieldProps={{
            label: "עיר מגורים",
            name: "city",
            id: privateDetails.city.id,
            value: privateDetails.city.value,
            type: "text",
          }}
          itemProps={{
            id: privateDetails.id,
            name: "privateDetails",
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <InputField
          fieldProps={{
            label: "תעודת זהות",
            name: "idCardNumber",
            id: privateDetails.idCardNumber.id,
            value: privateDetails.idCardNumber.value,
            type: "tel",
            error: privateDetails.idCardNumber.error,
          }}
          itemProps={{
            id: privateDetails.id,
            name: "privateDetails",
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <InputField
          fieldProps={{
            label: "אזרחות זרה",
            name: "foreignCitizenship",
            id: privateDetails.foreignCitizenship.id,
            value: privateDetails.foreignCitizenship.value,
            type: "text",
          }}
          itemProps={{
            id: privateDetails.id,
            name: "privateDetails",
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={4}>
        <SelectField
          fieldProps={{
            label: "מצב משפחתי",
            name: "familyStatus",
            id: privateDetails.familyStatus.id,
            value: privateDetails.familyStatus.value,
            options: familyStatusOptions,
          }}
          itemProps={{
            id: privateDetails.id,
            name: "privateDetails",
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={4}>
        <SelectField
          fieldProps={{
            label: "מספר ילדים",
            name: "numOfKids",
            id: privateDetails.numOfKids.id,
            value: privateDetails.numOfKids.value,
            options: numOfKidsOptions,
          }}
          itemProps={{
            id: privateDetails.id,
            name: "privateDetails",
          }}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={4}>
        <SelectField
          fieldProps={{
            label: "סיווג בטחוני בתוקף",
            name: "securityClearance",
            id: privateDetails.securityClearance.id,
            value: privateDetails.securityClearance.value,
            options: securityClearanceOptions,
          }}
          itemProps={{
            id: privateDetails.id,
            name: "privateDetails",
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
