import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import useFormContext from "../../FormContext";
import { generateYearsOptions } from "../../options";
import CustomTooltip from "components/Custom/CustomTooltip";

import InputField from "../Fields/InputField";
import SelectField from "../Fields/SelectField";
import Repeater from "../Fields/Repeater";
import AddButton from "../Buttons/AddButton";
import RemoveButton from "../Buttons/RemoveButton";

import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

export default function WorkExperiences() {
  const classes = useStyles();
  const { handleGetState } = useFormContext();
  const { workExperiences } = handleGetState();

  return (
    <>
      {workExperiences.map((item, i) => (
        <GridContainer key={i} className={classes.border}>
          {i > 0 && (
            <GridItem xs={12} sm={12}>
              <RemoveButton
                name="workExperiences"
                id={item.id}
                label="נסיון תעסוקתי נוסף"
              />
            </GridItem>
          )}
          <GridItem xs={12} sm={12} md={6}>
            <InputField
              fieldProps={{
                label: "חברה/מקום עבודה",
                name: "company",
                id: item.company.id,
                value: item.company.value,
                type: "text",
              }}
              itemProps={{
                id: item.id,
                name: "workExperiences",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ position: "relative" }}>
            <InputField
              fieldProps={{
                label: "תפקיד",
                name: "title",
                id: item.title.id,
                value: item.title.value,
                type: "text",
              }}
              itemProps={{
                id: item.id,
                name: "workExperiences",
              }}
            />
            <CustomTooltip
              iconButtonStyle={{
                position: "absolute",
                top: "50px",
                left: "-5px",
              }}
              title=" שימו לב! ניתן להתאים את כותרת התפקיד למגדר באמצעות עריכת הטקסט."
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <SelectField
              fieldProps={{
                label: "שנת התחלה",
                name: "startYear",
                id: item.startYear.id,
                value: item.startYear.value,
                options: generateYearsOptions(),
              }}
              itemProps={{
                id: item.id,
                name: "workExperiences",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <SelectField
              fieldProps={{
                label: "שנת סיום",
                name: "endYear",
                id: item.endYear.id,
                value: item.endYear.value,
                options: generateYearsOptions(),
              }}
              itemProps={{
                id: item.id,
                name: "workExperiences",
              }}
            />
          </GridItem>
          <Repeater
            title="תיאור תפקיד"
            items={item.achievements}
            fieldProps={{
              label: "תיאור תפקיד",
              name: "achievements",
              type: "text",
              charsLimit: 100,
            }}
            itemProps={{
              id: item.id,
              name: "workExperiences",
            }}
            tooltipProps={{
              title:
                "סעיף זה הוא המקום למלא פרטים אודות הישגים בתקופת העבודה כגון הצטיינות, התמחויות והרחבות, פרסים, ממוצע ציונים, פרויקטים בולטים ועוד.",
            }}
            addButtonTitle="הוספת תיאור תפקיד"
          />
        </GridContainer>
      ))}

      {workExperiences.length < 10 && (
        <AddButton name="workExperiences" label="הוספת נסיון תעסוקתי" />
      )}
    </>
  );
}
