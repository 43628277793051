import React, { useState } from "react";
import useAxios from "axios-hooks";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import EmailIcon from "@material-ui/icons/Email";
import SmsIcon from "@material-ui/icons/Sms";

import { useAuth } from "context/Auth";

import tabsStyle from "assets/jss/material-dashboard-react/components/tabsStyle";

import LoginEmailTab from "./LoginEmailTab";
import LoginSmsTab from "./LoginSmsTab";

const useStyles = makeStyles(tabsStyle);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ padding: "30px 10px 10px 10px" }}>{children}</div>
      )}
    </div>
  );
}

export default function LoginTabs() {
  const classes = useStyles();
  const [sessionToken, setSessionToken] = useState(null);
  const { handleLogin } = useAuth();
  const [value, setValue] = React.useState(0);

  const [, executeLoginByEmail] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user/login/connect/email",
      method: "POST",
    },
    { manual: true }
  );

  const [, executeLoginByPhone] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user/login/connect/phone",
      method: "POST",
    },
    { manual: true }
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLoginByEmail = async (token) => {
    const result = await executeLoginByEmail({
      data: { sessionToken: token },
    });
    handleLogin(result.data.token, result.data.user);
  };

  const handleLoginByPhone = async (token) => {
    const result = await executeLoginByPhone({
      data: { sessionToken: token },
    });
    handleLogin(result.data.token, result.data.user);
  };

  return (
    <div className={classes.root}>
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="התחברות באמצעות אימייל או סמס"
        >
          <Tab icon={<EmailIcon />} label="אימייל" {...a11yProps(0)} />
          <Tab icon={<SmsIcon />} label="סמס" {...a11yProps(1)} />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <LoginEmailTab
          handleComplete={handleLoginByEmail}
          sessionToken={sessionToken}
          setSessionToken={setSessionToken}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LoginSmsTab
          handleComplete={handleLoginByPhone}
          sessionToken={sessionToken}
          setSessionToken={setSessionToken}
        />
      </TabPanel>
    </div>
  );
}
