import React from "react";
import useAxios from "axios-hooks";
import Banners from "components/Custom/Banners";
import SidebarSkeleton from "components/Skeletons/SidebarSkeleton";

export default function Sidebar() {
  const [{ data, loading }] = useAxios({
    url: process.env.REACT_APP_API_URL + "user-profile/sidebar",
  });

  if (loading) return <SidebarSkeleton />;

  return <Banners banners={data.wp_banners_sidebar} />;
}
