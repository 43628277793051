import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import useFormContext from "../../FormContext";
import { generateYearsOptions } from "../../options";

import InputField from "../Fields/InputField";
import SelectField from "../Fields/SelectField";
import AddButton from "../Buttons/AddButton";
import RemoveButton from "../Buttons/RemoveButton";

import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

export default function Volunteering() {
  const classes = useStyles();
  const { handleGetState } = useFormContext();
  const { volunteering } = handleGetState();

  return (
    <>
      {volunteering.map((item, i) => (
        <GridContainer key={i} className={classes.border}>
          {i > 0 && (
            <GridItem xs={12} sm={12}>
              <RemoveButton
                name="volunteering"
                id={item.id}
                label="התנדבות נוספת"
              />
            </GridItem>
          )}

          <GridItem xs={12} sm={12} md={6}>
            <InputField
              fieldProps={{
                label: "מסגרת/ארגון",
                name: "organization",
                id: item.organization.id,
                value: item.organization.value,
                type: "text",
              }}
              itemProps={{
                id: item.id,
                name: "volunteering",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <InputField
              fieldProps={{
                label: "תפקיד",
                name: "title",
                id: item.title.id,
                value: item.title.value,
                type: "text",
              }}
              itemProps={{
                id: item.id,
                name: "volunteering",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <SelectField
              fieldProps={{
                label: "שנת התחלה",
                name: "startYear",
                id: item.startYear.id,
                value: item.startYear.value,
                options: generateYearsOptions(),
              }}
              itemProps={{
                id: item.id,
                name: "volunteering",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <SelectField
              fieldProps={{
                label: "שנת סיום",
                name: "endYear",
                id: item.endYear.id,
                value: item.endYear.value,
                options: generateYearsOptions(),
              }}
              itemProps={{
                id: item.id,
                name: "volunteering",
              }}
            />
          </GridItem>
        </GridContainer>
      ))}

      {volunteering.length < 3 && (
        <AddButton name="volunteering" label="הוספת התנדבות" />
      )}
    </>
  );
}
