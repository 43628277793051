import React, { useState } from "react";
import useAxios from "axios-hooks";

import { CircularProgress } from "@material-ui/core";

import { toFormData, validateCaptcha } from "helpers/functions";

import CustomCard from "components/Custom/CustomCard";
import Button from "components/CustomButtons/Button";
import CustomDropZone from "components/Custom/CustomDropzone";

import DropzoneSkeleton from "components/Skeletons/DropzoneSkeleton";

export default function FriendDropzone() {
  const [files, setFiles] = useState([]);

  const [{ data, loading }] = useAxios({
    url: process.env.REACT_APP_API_URL + "friend/files",
  });

  const [{ loading: postLoading }, execute] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "friend/files",
      method: "POST",
    },
    { manual: true }
  );

  const handleSendFiles = async () => {
    const token = await validateCaptcha("handleSendFiles");

    let form_data = new FormData();
    form_data = toFormData(files, form_data);
    form_data.append("grecaptchaToken", token);
    const result = await execute({
      data: form_data,
    });
    setFiles([]);
  };

  if (loading) return <DropzoneSkeleton />;

  const headerActions = (
    <Button
      onClick={() => handleSendFiles()}
      disabled={files.length == 0 || postLoading}
      color="info"
      aria-label="שליחה"
      size="lg"
    >
      <span>שליחה</span>
      {postLoading && <CircularProgress size={24} className="buttonProgress" />}
    </Button>
  );

  return (
    <CustomCard
      titleTag={"h3"}
      title={data.wp_upload_file_section.main_title}
      description={data.wp_upload_file_section.subtitle}
      headerActions={headerActions}
      color="success"
    >
      <CustomDropZone files={files} setFiles={setFiles} />
    </CustomCard>
  );
}
