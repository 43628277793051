import React, { forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { CircularProgress } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import modalStyle from "../../assets/jss/material-dashboard-react/modalStyle";
import Button from "../CustomButtons/Button.js";

const useModalStyle = makeStyles(modalStyle);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useButtonStyle = makeStyles(() => ({
  button: {
    position: "relative",
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function CustomVerifyDeleteDialog(props) {
  const buttonClasses = useButtonStyle();
  const classes = useModalStyle();

  const {
    open,
    handleSubmit,
    handleCloseDialog,
    Description,
    title,
    isLoading,
    disableBackdropClick,
    disableEscapeKeyDown,
    ...rest
  } = props;

  const handleClose = (event, reason) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return false;
    }

    if (disableEscapeKeyDown && reason === "escapeKeyDown") {
      return false;
    }

    if (typeof handleCloseDialog === "function") {
      handleCloseDialog();
    }
  };

  return (
    <>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        maxWidth="xs"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        {...rest}
      >
        <DialogTitle disableTypography className={classes.modalHeader}>
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => handleCloseDialog()}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>{title}</h4>
        </DialogTitle>
        <DialogContent className={classes.modalBody}>
          <Box style={{ marginBottom: "15px" }}>{<Description />}</Box>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => handleSubmit()}
            disabled={isLoading}
            color="success"
          >
            <span>אישור</span>
            {isLoading && (
              <CircularProgress
                size={24}
                className={buttonClasses.buttonProgress}
              />
            )}
          </Button>

          <Button onClick={() => handleCloseDialog()} color="danger">
            ביטול
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
