import React, { useState, createContext, useContext } from "react";

/*
 * show : whether show or hide the message block - true | false
 * type : what theme is the message block - 'error' | 'info' | 'success' | 'warning';
 * msg : message displaying on the message block - "" | "Demo str..."
 *
 * You can add more field if you want
 */
const initialState = {
  show: false,
  type: "success",
  msg: "",
  onClose: null,
};

export const NotifyContext = createContext();

export const NotifyProvider = ({ children }) => {
  const [notificationState, setNotificationState] = useState(initialState);

  const setNotification = (val) => {
    setNotificationState(val);
  };

  const getNotification = () => {
    return notificationState;
  };

  const onClose = () => {
    if (typeof notificationState.onClose == "function") {
      notificationState.onClose();
    }
    setNotification({ ...initialState, type: notificationState.type });
  };

  return (
    <NotifyContext.Provider
      value={{ setNotification, getNotification, onClose }}
    >
      {children}
    </NotifyContext.Provider>
  );
};

export const useNotify = () => {
  return useContext(NotifyContext);
};
