import React, { useState } from "react";
import PropTypes from "prop-types";
import useAxios from "axios-hooks";

import { CircularProgress } from "@material-ui/core";

import { isEmpty, hasNoDigits, validateCaptcha } from "helpers/functions.js";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";

export default function RegisterProfile(props) {
  const { handleComplete, sessionToken, setSessionToken } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [{ loading: loadingSubmit }, executeSubmit] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user/register/profile",
      method: "POST",
    },
    { manual: true }
  );

  const handleSubmit = async () => {
    const token = await validateCaptcha("handleSubmitRegisterProfile");
    const result = await executeSubmit({
      data: {
        firstName: firstName,
        lastName: lastName,
        sessionToken: sessionToken,
        grecaptchaToken: token,
      },
    });

    setSessionToken(result.data.session_token);
    handleComplete(result.data.session_token);
  };

  return (
    <>
      <div>
        <p>רק כמה פרטים אישיים שנדע לפנות אליך :)</p>

        <CustomInput
          labelText="שם פרטי"
          id="firstName"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: firstName,
            type: "text",
            onChange: (e) => {
              setFirstName(e.target.value);
            },
            inputProps: {
              "aria-required": true,
            },
          }}
        />

        <CustomInput
          labelText="שם משפחה"
          id="lastName"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: lastName,
            type: "text",
            onChange: (e) => {
              setLastName(e.target.value);
            },
            inputProps: {
              "aria-required": true,
            },
          }}
        />

        <div style={{ marginTop: "25px", textAlign: "center" }}>
          <Button
            disabled={
              loadingSubmit ||
              isEmpty(firstName) ||
              isEmpty(lastName) ||
              !hasNoDigits(firstName) ||
              !hasNoDigits(lastName)
            }
            onClick={() => handleSubmit()}
            color={"success"}
          >
            <span>כניסה</span>
            {loadingSubmit && (
              <CircularProgress size={24} className="buttonProgress" />
            )}
          </Button>
        </div>
      </div>
    </>
  );
}
RegisterProfile.propTypes = {
  handleComplete: PropTypes.func,
  sessionToken: PropTypes.string,
  setSessionToken: PropTypes.func,
};
