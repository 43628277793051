import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import useFormContext from "../../FormContext";
import { languageTitleOptions, languageLevelOptions } from "../../options";

import SelectField from "../Fields/SelectField";
import SelectFieldFreeSolo from "../Fields/SelectFieldFreeSolo";
import AddButton from "../Buttons/AddButton";
import RemoveButton from "../Buttons/RemoveButton";

import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

export default function Languages() {
  const classes = useStyles();
  const { handleGetState } = useFormContext();
  const { languages } = handleGetState();

  return (
    <>
      {languages.map((item, i) => (
        <GridContainer key={i} className={classes.border}>
          {i > 0 && (
            <GridItem xs={12} sm={12}>
              <RemoveButton name="languages" id={item.id} label="שפה נוספת" />
            </GridItem>
          )}

          <GridItem xs={12} sm={12} md={6}>
            <SelectFieldFreeSolo
              fieldProps={{
                label: "שפה",
                name: "title",
                id: item.title.id,
                value: item.title.value,
                options: languageTitleOptions,
              }}
              itemProps={{
                id: item.id,
                name: "languages",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <SelectField
              fieldProps={{
                label: "רמה",
                name: "level",
                id: item.level.id,
                value: item.level.value,
                options: languageLevelOptions,
                multiple: true,
              }}
              itemProps={{
                id: item.id,
                name: "languages",
              }}
            />
          </GridItem>
        </GridContainer>
      ))}

      {languages.length < 10 && (
        <AddButton name="languages" label="הוספת שפה" />
      )}
    </>
  );
}
