import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";

import { useTable, usePagination } from "react-table";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { heIL } from "@material-ui/core/locale";

const useStyles = makeStyles(styles);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const theme = createTheme(
  {
    direction: "rtl",
  },
  heIL
);

function FetchTable(props) {
  const classes = useStyles();
  const [data, setData] = useState(props.data);

  const [orderBy, orderDirection] = props.order;
  // const [orderBy, setOrderBy] = useState(props.data);
  // const [orderDirection, setOrderDirection] = useState(props.data);

  useEffect(() => {
    // if (props.data.length > 0)
    {
      setData(props.data);
    }
  }, [props.data]);

  const tableContainerClasses = classNames({
    [classes.tableResponsive]: true,
    [props.className]: props.className !== undefined,
  });

  const columns = useMemo(() => props.columns, []);

  const { handleUpdateData } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data: data,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      handleUpdateData,
    },
    usePagination
  );

  const handleChangePage = (event, newPage) => {
    props.updatePagination(newPage, props.limit);
  };

  const handleChangeRowsPerPage = (event) => {
    props.updatePagination(0, Number(event.target.value));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && orderDirection === "desc";
    const direction = isAsc ? "asc" : "desc";

    props.setOrder([property, direction]);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={tableContainerClasses}>
        <Table
          className={classes.table}
          {...getTableProps()}
          summary={props.summary}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow
                className={classes.tableHeadRow}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => {
                  //   console.log("column", column);
                  return (
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                      {...column.getHeaderProps()}
                    >
                      <TableSortLabel
                        hideSortIcon={column.sortable === false}
                        active={orderBy === column.id}
                        direction={
                          orderBy === column.id ? orderDirection : "desc"
                        }
                        onClick={(e) => {
                          if (column.sortable !== false) {
                            handleRequestSort(e, column.id);
                          }
                        }}
                      >
                        {column.render("Header")}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  className={classes.tableBodyRow}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        className={classes.tableCell}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>

      {props.loading ? (
        <div
          style={{
            textAlign: "left",
            height: "52px",
            lineHeight: "52px",
            position: "relative",
            left: "45px",
          }}
        >
          <CircularProgress
            style={{ color: "rgba(0, 0, 0, 0.3)" }}
            size={15}
            disableShrink
          />
        </div>
      ) : (
        <TablePagination
          component="div"
          count={props.total}
          page={props.page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25, 50]}
          rowsPerPage={props.limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </ThemeProvider>
  );
}

export default FetchTable;
