import React, { useState } from "react";
import useAxios from "axios-hooks";
import PropTypes from "prop-types";

import { Box } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import CustomVerifyDeleteDialog from "components/Custom/CustomVerifyDeleteDialog";

export default function SecondaryEmailsList({
  setSecondaryEmailsList,
  secondaryEmailsList,
  primaryEmail,
  setPrimaryEmail,
}) {
  const [newPrimaryEmail, setNewPrimaryEmail] = useState("");
  const [removeEmail, setRemoveEmail] = useState("");
  const [isOpenVerifyDialog, setIsOpenVerifyDialog] = useState(false);

  const [{ loading: loadingSetEmail }, executeSetEmail] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user-profile/edit-email/set",
      method: "POST",
    },
    { manual: true }
  );

  const handleSetPrimaryEmail = async (email) => {
    setNewPrimaryEmail(email);

    await executeSetEmail({
      data: {
        email: email,
      },
    });

    var filtered = secondaryEmailsList.filter(function (value) {
      return value != email;
    });
    setSecondaryEmailsList([...filtered, primaryEmail]);
    setPrimaryEmail(email);
  };

  const [{ loading: loadingRemoveEmail }, executeRemoveEmail] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user-profile/edit-email/remove",
      method: "POST",
    },
    { manual: true }
  );

  const handleSubmitVerifyDeleteDialog = async () => {
    await executeRemoveEmail({
      data: {
        email: removeEmail,
      },
    });

    var filtered = secondaryEmailsList.filter(function (value) {
      return value != removeEmail;
    });
    setSecondaryEmailsList([...filtered]);
    setIsOpenVerifyDialog(false);
  };

  const handleRemoveEmail = (email) => {
    setRemoveEmail(email);
    setIsOpenVerifyDialog(true);
  };

  const handleCloseVerifyDeleteDialog = () => {
    setIsOpenVerifyDialog(false);
  };

  const DescriptionVerifyDeleteDialog = () => {
    return (
      <>
        האם להסיר את המייל:{" "}
        <span style={{ textDecoration: "underline" }}>{removeEmail}</span>?
      </>
    );
  };

  return (
    <>
      {secondaryEmailsList?.map((email, i) => (
        <GridItem key={email} xs={12}>
          <GridContainer alignItems="flex-end">
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="כתובת מייל משנית"
                id={`email-${i}`}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  name: "email-" + i,
                  value: email,
                  type: "email",
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <Box style={{ paddingBottom: "5px" }}>
                <Button
                  title={email}
                  type="button"
                  color="info"
                  size="sm"
                  onClick={() => handleSetPrimaryEmail(email)}
                  disabled={loadingSetEmail && newPrimaryEmail === email}
                >
                  <span>עדכון לראשי</span>
                  {loadingSetEmail && newPrimaryEmail === email && (
                    <CircularProgress size={24} className="buttonProgress" />
                  )}
                </Button>
                <Button
                  title={email}
                  type="button"
                  color="danger"
                  size="sm"
                  onClick={() => handleRemoveEmail(email)}
                >
                  <span>הסרה</span>
                  {loadingRemoveEmail && removeEmail === email && (
                    <CircularProgress size={24} className="buttonProgress" />
                  )}
                </Button>
              </Box>
            </GridItem>
          </GridContainer>
        </GridItem>
      ))}
      <CustomVerifyDeleteDialog
        open={isOpenVerifyDialog}
        title="הסרת אימייל"
        Description={DescriptionVerifyDeleteDialog}
        handleSubmit={handleSubmitVerifyDeleteDialog}
        handleCloseDialog={handleCloseVerifyDeleteDialog}
        isLoading={loadingRemoveEmail}
      ></CustomVerifyDeleteDialog>
    </>
  );
}
SecondaryEmailsList.propTypes = {
  secondaryEmailsList: PropTypes.array,
  setSecondaryEmailsList: PropTypes.func,
  setPrimaryEmail: PropTypes.func,
  primaryEmail: PropTypes.string,
};
