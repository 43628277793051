import React, { useState } from "react";
import PropTypes from "prop-types";
import useAxios from "axios-hooks";
import { useParams } from "react-router";
import moment from "moment";

import { CircularProgress } from "@material-ui/core";

import { validateCaptcha } from "helpers/functions";

// import DatePicker from "components/Custom/DatePicker";
import CustomDialog from "components/Custom/CustomDialog";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import CustomDatePicker from "components/Custom/CustomDatePicker";

export default function DelayInterviewDialog(props) {
  const { open, setOpen } = props;
  const { id } = useParams();
  const [comment, setComment] = useState("");
  const [preferredDate, setPreferredDate] = useState("");

  const [{ loading }, execute] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "my-processes/step/delay-interview",
      method: "POST",
    },
    { manual: true }
  );

  const handleCloseDialog = () => {
    setComment("");
    setPreferredDate("");
    setOpen(false);
  };

  const handleSubmit = async () => {
    const token = await validateCaptcha("CancelInterviewDialoghandleSubmit");
    await execute({
      data: {
        comment: comment,
        preferredDate: moment(preferredDate).format("DD/MM/YYYY"),
        stepId: id,
        grecaptchaToken: token,
      },
    });
    handleCloseDialog();
  };

  const handleChangeDate = (date) => {
    setPreferredDate(date ? date : "");
  };

  return (
    <>
      <CustomDialog
        fields={
          <>
            <CustomDatePicker
              minDate={new Date()}
              value={preferredDate || null}
              onChange={handleChangeDate}
              formControlProps={{
                fullWidth: true,
              }}
              id="preferredDate"
              labelText="מועד חלופי מועדף (*)"
              inputProps={{
                name: "preferredDate",
              }}
            />

            <CustomInput
              labelText="סיבה (*)"
              id="update-comment-dialog"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                name: "comment",
                type: "text",
                value: comment,
                onChange: (event) => setComment(event.target.value),
                multiline: true,
                rows: 10,
              }}
            />
          </>
        }
        submitButton={
          <Button
            onClick={() => handleSubmit()}
            disabled={!comment || !preferredDate || loading}
            color="info"
          >
            <span>שליחה</span>
            {loading && (
              <CircularProgress size={24} className="buttonProgress" />
            )}
          </Button>
        }
        handleCloseDialog={handleCloseDialog}
        open={open}
        title="שינוי מועד ראיון"
        description={
          <p>פרט/י את סיבת שינוי מועד הראיון ומהו המועד החלופי המועדף.</p>
        }
      ></CustomDialog>
    </>
  );
}

DelayInterviewDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
