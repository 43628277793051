import React from "react";
import PropTypes from "prop-types";

import CustomDialog from "components/Custom/CustomDialog";
import Button from "components/CustomButtons/Button.js";

export default function SessionTimeoutDialog(props) {
  const { open, countdown, onLogout, onContinue } = props;

  return (
    <CustomDialog
      submitButton={
        <>
          <Button onClick={onContinue} color="info">
            <span>להמשיך</span>
          </Button>
          <Button onClick={onLogout} color="danger">
            <span>התנתקות</span>
          </Button>
        </>
      }
      handleCloseDialog={onContinue}
      open={open}
      title="סיום סשן"
      description={
        <>
          הסשן הנוכחי עומד להסתיים בעוד{" "}
          <span style={{ fontWeight: "bold" }}>{countdown}</span> שניות.{" "}
          <br></br>
          האם להמשיך את הסשן?
        </>
      }
      hideCancelButton={true}
    ></CustomDialog>
  );
}

SessionTimeoutDialog.propTypes = {
  /**
   * indicator whether the dialog is open/close
   */
  open: PropTypes.bool.isRequired,
  /**
   * the countdown timer.
   */
  countdown: PropTypes.number.isRequired,
  /**
   * callback function to handle closing action
   */
  onLogout: PropTypes.func.isRequired,
  /**
   * callback function to handle confirm action.
   */
  onContinue: PropTypes.func.isRequired,
};
