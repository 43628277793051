import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import { useAuth } from "context/Auth";
import { useNotify } from "context/Notify";

import GridContainer from "componentsPro/Grid/GridContainer.js";
import GridItem from "componentsPro/Grid/GridItem.js";
import Card from "componentsPro/Card/Card.js";
import CardBody from "componentsPro/Card/CardBody.js";
import CardHeader from "componentsPro/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import LoginTabs from "./LoginTabs";
import LoginSteps from "./LoginSteps";

const useStyles = makeStyles(styles);

export default function Login() {
  const classes = useStyles();
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const { isAuthenticated, getRefUrl, isTimedOut, handleLogout } = useAuth();
  const { setNotification } = useNotify();

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  useEffect(() => {
    if (isTimedOut()) {
      setNotification({
        show: true,
        type: "error",
        msg: "הסשן הסתיים. יש להתחבר שוב.",
        onClose: handleLogout("", false),
      });
    }
  }, []);

  if (isAuthenticated()) {
    const refUrl = getRefUrl();
    if (refUrl !== "" && refUrl != "/p/login") return <Redirect to={refUrl} />;
    else return <Redirect to="/u/my-processes" />;
  }

  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={4}>
          <Card login className={classes[cardAnimaton]}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="success"
            >
              <h1 className={classes.cardTitle}>התחברות</h1>
              <p>באמצעות אימייל או סמס</p>
            </CardHeader>
            <CardBody>
              <LoginTabs />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
