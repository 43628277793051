import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import useFormContext from "../../FormContext";
import SelectTagsField from "../Fields/SelectTagsField";

export default function Skills() {
  const { handleGetState } = useFormContext();
  const [suggestions, setSuggestions] = useState([]);

  const [, getSuggestions] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "cv/skills-tags",
    },
    { manual: true }
  );

  const { privateDetails } = handleGetState();

  useEffect(() => {
    async function loadSuggestions() {
      const result = await getSuggestions();
      const options = result.data.wp_skills_tags_data?.map((item) => {
        return item["title"];
      });
      setSuggestions(options);
    }
    loadSuggestions();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <SelectTagsField
          fieldProps={{
            label: "כלים וטכנולוגיות",
            name: "skills",
            id: privateDetails.skills.id,
            value: privateDetails.skills.value,
            options: suggestions,
            tagsLimit: 30,
          }}
          itemProps={{
            id: privateDetails.id,
            name: "privateDetails",
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
