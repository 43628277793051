import React from "react";
import PropTypes from "prop-types";

import CustomCard from "components/Custom/CustomCard";

export default function CustomCardVideo(props) {
  const { title, description, youtube_id, ...rest } = props;

  return (
    <CustomCard title={title} description={description} {...rest}>
      <div
        className="video"
        style={{
          position: "relative",
          paddingBottom: "56.25%" /* 16:9 */,
          paddingTop: 25,
          height: 0,
        }}
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          src={`https://www.youtube.com/embed/${youtube_id}`}
          frameBorder="0"
        />
      </div>
    </CustomCard>
  );
}

CustomCardVideo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  youtube_id: PropTypes.any,
};
