import React from "react";
import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

export default function DropzoneSkeleton() {
  return (
    <Box style={{ marginBottom: "30px" }}>
      <Skeleton variant="rect" height={85} style={{ marginBottom: "5px" }} />
      <Skeleton variant="rect" height={300} />
    </Box>
  );
}
