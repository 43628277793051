import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";
import { isMobile } from "react-device-detect";

import CustomCard from "components/Custom/CustomCard";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomButtonBot from "components/Custom/CustomButtonBot";
import CustomInput from "components/CustomInput/CustomInput";
import CardSkeleton from "components/Skeletons/CardSkeleton";

const getJobAreaInputProps = (area) => {
  if (isMobile) {
    return {
      multiline: true,
      rows: 2,
      value: area,
      disabled: true,
    };
  } else {
    return {
      value: area,
      disabled: true,
    };
  }
};

export default function CardWorkExperience() {
  const [contentSection, setContentSection] = useState();
  const [workExperience, setWorkExperience] = useState({
    prof: "",
    job: "",
    sal: "",
    sec: "",
    area: "",
  });

  const [{ data, loading }] = useAxios({
    url: process.env.REACT_APP_API_URL + "user-profile/work-experience",
  });

  useEffect(() => {
    if (data) {
      if (data.cvneto_work_experience) {
        setWorkExperience({
          prof: data.cvneto_work_experience.prof || "",
          job: data.cvneto_work_experience.job || "",
          sal: data.cvneto_work_experience.sal || "",
          sec: data.cvneto_work_experience.sec || "",
          area: data.cvneto_work_experience.area || "",
        });
      }
      setContentSection(data.wp_experience_section);
    }
  }, [data]);

  if (loading) return <CardSkeleton />;

  return (
    <CustomCard
      titleTag={"h2"}
      title={contentSection?.main_title}
      description={contentSection?.subtitle}
    >
      <form>
        <GridContainer>
          {/* profession */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="תחום עיסוק"
              id="profession"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: workExperience?.prof,
                disabled: true,
              }}
            />
          </GridItem>

          {/* role */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="תפקיד נוכחי"
              id="role"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: workExperience?.job,
                disabled: true,
              }}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          {/* salaryExpectations */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="ציפיות שכר"
              id="salary-expectations"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: workExperience?.sal,
                disabled: true,
              }}
            />
          </GridItem>

          {/* securityClearance */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="סיווג בטחוני"
              id="security-clearance"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: workExperience?.sec,
                disabled: true,
              }}
            />
          </GridItem>

          {/* jobArea */}
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="איזור גאוגרפי לחיפוש עבודה"
              id="job-area"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={getJobAreaInputProps(workExperience?.area)}
            />
          </GridItem>

          {!(workExperience?.lenght > 0) && (
            <GridItem container justifyContent="flex-end">
              <CustomButtonBot
                label={"עדכון פרטים באמצעות לוגי"}
                style={{ marginTop: "25px" }}
              />
            </GridItem>
          )}
        </GridContainer>
      </form>
    </CustomCard>
  );
}
