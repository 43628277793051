import React, { useState } from "react";
import PropTypes from "prop-types";
import Moment from "moment";

import Grid from "@material-ui/core/Grid";
import AccessTime from "@material-ui/icons/AccessTime";

import { FaInfo } from "react-icons/fa";

import CustomCardIcon from "components/Custom/CustomCardIcon";
import Button from "components/CustomButtons/Button.js";

import CancelInterviewDialog from "./CancelInterviewDialog";
import DelayInterviewDialog from "./DelayInterviewDialog";

export default function ProcessStepStatus(props) {
  const {
    title,
    description,
    update_datetime,
    forms,
    step_friendly_name,
    interview_datetime,
  } = props;
  const [openCancelInterviewDialog, setOpenCancelInterviewDialog] = useState(
    false
  );
  const [openDelayInterviewDialog, setOpenDelayInterviewDialog] = useState(
    false
  );
  const handleOpenCancelInterviewDialog = () => {
    setOpenCancelInterviewDialog(true);
  };

  const handleOpenDelayInterviewDialog = () => {
    setOpenDelayInterviewDialog(true);
  };

  const Stats = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          {update_datetime && (
            <>
              <AccessTime />{" "}
              {Moment(update_datetime).local().format("DD/MM/YYYY HH:mm")}
            </>
          )}
        </Grid>
        <Grid item>
          {forms.includes("delay_interview") && (
            <Button
              title={`${title} שינוי מועד ראיון`}
              type="button"
              color="info"
              onClick={(e) => handleOpenDelayInterviewDialog(e)}
            >
              שינוי מועד ראיון
            </Button>
          )}
          {forms.includes("cancel_interview") && (
            <Button
              title={`${title} ביטול ראיון`}
              type="button"
              color="info"
              onClick={(e) => handleOpenCancelInterviewDialog(e)}
            >
              ביטול ראיון
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  const PrepareDescription = () => {
    if (
      interview_datetime &&
      (forms.includes("delay_interview") || forms.includes("cancel_interview"))
    ) {
      const [, timeStr] = interview_datetime.split("T");
      const date = Moment(interview_datetime).local().format("DD/MM/YYYY");

      let time = "";
      if (timeStr != "00:00:00") {
        time = Moment(interview_datetime).local().format("HH:mm");
      }

      return (
        <>
          <div className="description">{description}</div>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
            style={{ marginTop: "12px" }}
          >
            <Grid item>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#000",
                }}
              >
                <span>תאריך ראיון: </span>
                <span style={{ color: "#009999" }}>{date}</span>
              </p>
            </Grid>
            {timeStr != "00:00:00" && (
              <Grid item>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                  }}
                >
                  <span>שעת ראיון: </span>
                  <span style={{ color: "#009999" }}>{time}</span>
                </p>
              </Grid>
            )}
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <div className="description">{description}</div>
        </>
      );
    }
  };

  return (
    <>
      <div className="process-card-status">
        <CustomCardIcon
          title={`${title} - ${step_friendly_name}`}
          description={<PrepareDescription />}
          icon={<FaInfo />}
          stats={<Stats style={{ width: "100%" }} />}
        />
      </div>

      <CancelInterviewDialog
        open={openCancelInterviewDialog}
        setOpen={setOpenCancelInterviewDialog}
      ></CancelInterviewDialog>

      <DelayInterviewDialog
        open={openDelayInterviewDialog}
        setOpen={setOpenDelayInterviewDialog}
      ></DelayInterviewDialog>
    </>
  );
}

ProcessStepStatus.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  update_datetime: PropTypes.string,
  interview_datetime: PropTypes.string,
  style: PropTypes.any,
  forms: PropTypes.any,
  step_friendly_name: PropTypes.string,
};
