export const familyStatusOptions = [
  "אלמן",
  "אלמנה",
  "גרוש",
  "גרושה",
  "נשוי",
  "נשואה",
  "רווק",
  "רווקה",
];

export const securityClearanceOptions = [
  "לא",
  "רמה 1",
  "רמה 2",
  "רמה 3",
  "רמה 4",
];

export const numOfKidsOptions = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];

export const educationStudyTypeOptions = [
  "השכלה תיכונית בגרות מלאה",
  "הנדסאי",
  "תואר ראשון",
  "תואר שני",
  "דוקטור",
  "פרופסורה",
  "לימודים תורניים/ישיבה",
];

export const courseStudyTypeOptions = [
  "קורס",
  "השתלמות",
  "לימודי תעודה",
  "דיפלומה",
];

export const generateYearsOptions = (back = 60) => {
  const year = new Date().getFullYear();
  const years = Array.from({ length: back }, (v, i) => {
    const currentYear = year - back + i + 1;
    return currentYear.toString();
  });
  years.push("היום");
  return years;
};

export const militaryServiceTypeOptions = ["צבאי", "לאומי"];

export const languageTitleOptions = [
  "עברית",
  "אנגלית",
  "ערבית",
  "רוסית",
  "צרפתית",
  "ספרדית",
];

export const languageLevelOptions = [
  "שפת אם",
  "רמה טובה מאוד",
  "רמה טובה",
  "רמה בינונית",
  "רמה בסיסית",
];
