import React, { useState } from "react";
import useAxios from "axios-hooks";
import PropTypes from "prop-types";

import { Box } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import CustomVerifyDeleteDialog from "components/Custom/CustomVerifyDeleteDialog";

export default function SecondaryPhonesList({
  setSecondaryPhonesList,
  secondaryPhonesList,
  primaryPhone,
  setPrimaryPhone,
}) {
  const [newPrimaryPhone, setNewPrimaryPhone] = useState("");
  const [removePhone, setRemovePhone] = useState("");
  const [isOpenVerifyDialog, setIsOpenVerifyDialog] = useState(false);

  const [{ loading: loadingSetPhone }, executeSetPhone] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user-profile/edit-phone/set",
      method: "POST",
    },
    { manual: true }
  );

  const handleSetPrimaryPhone = async (phone) => {
    setNewPrimaryPhone(phone);

    await executeSetPhone({
      data: {
        phone: phone,
      },
    });

    var filtered = secondaryPhonesList.filter(function (value) {
      return value != phone;
    });
    setSecondaryPhonesList([...filtered, primaryPhone]);
    setPrimaryPhone(phone);
  };

  const [{ loading: loadingRemovePhone }, executeRemovePhone] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user-profile/edit-phone/remove",
      method: "POST",
    },
    { manual: true }
  );

  const handleSubmitVerifyDeleteDialog = async () => {
    await executeRemovePhone({
      data: {
        phone: removePhone,
      },
    });

    var filtered = secondaryPhonesList.filter(function (value) {
      return value != removePhone;
    });
    setSecondaryPhonesList([...filtered]);
    setIsOpenVerifyDialog(false);
  };

  const handleRemovePhone = (phone) => {
    setRemovePhone(phone);
    setIsOpenVerifyDialog(true);
  };

  const handleCloseVerifyDeleteDialog = () => {
    setIsOpenVerifyDialog(false);
  };

  const DescriptionVerifyDeleteDialog = () => {
    return (
      <>
        האם להסיר את הטלפון:{" "}
        <span style={{ textDecoration: "underline" }}>{removePhone}</span>?
      </>
    );
  };

  return (
    <>
      {secondaryPhonesList.map((phone, i) => (
        <GridItem key={phone} xs={12}>
          <GridContainer alignItems="flex-end">
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="מספר טלפון משני"
                id={`phone-${i}`}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  name: "phone-" + i,
                  value: phone,
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <Box style={{ paddingBottom: "5px" }}>
                <Button
                  title={phone}
                  type="button"
                  color="info"
                  size="sm"
                  onClick={() => handleSetPrimaryPhone(phone)}
                  disabled={loadingSetPhone && newPrimaryPhone === phone}
                >
                  <span>עדכון לראשי</span>
                  {loadingSetPhone && newPrimaryPhone === phone && (
                    <CircularProgress size={24} className="buttonProgress" />
                  )}
                </Button>
                <Button
                  title={phone}
                  type="button"
                  color="danger"
                  size="sm"
                  onClick={() => handleRemovePhone(phone)}
                >
                  <span>הסרה</span>
                  {loadingRemovePhone && removePhone === phone && (
                    <CircularProgress size={24} className="buttonProgress" />
                  )}
                </Button>
              </Box>
            </GridItem>
          </GridContainer>
        </GridItem>
      ))}
      <CustomVerifyDeleteDialog
        open={isOpenVerifyDialog}
        title="הסרת טלפון"
        Description={DescriptionVerifyDeleteDialog}
        handleSubmit={handleSubmitVerifyDeleteDialog}
        handleCloseDialog={handleCloseVerifyDeleteDialog}
        isLoading={loadingRemovePhone}
      ></CustomVerifyDeleteDialog>
    </>
  );
}
SecondaryPhonesList.propTypes = {
  secondaryPhonesList: PropTypes.array,
  setSecondaryPhonesList: PropTypes.func,
  setPrimaryPhone: PropTypes.func,
  primaryPhone: PropTypes.string,
};
