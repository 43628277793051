import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import useFormContext from "../../FormContext";
import CustomSelectTags from "components/CustomSelect/CustomSelectTags";
import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

function SelectTagsField({ fieldProps, itemProps }) {
  const classes = useStyles();
  const { handleChange } = useFormContext();
  const [tagsCount, setTagsCount] = useState(0);

  const handleTagsLimit = useCallback(
    (args) => {
      const { newValue } = args;
      if (newValue.length > fieldProps.tagsLimit) {
        setTagsCount(fieldProps.tagsLimit);
      } else {
        setTagsCount(newValue.length);
        handleChange({
          name: itemProps.name,
          newValue: newValue,
          subName: fieldProps.name,
          id: itemProps.id,
        });
      }
    },
    [setTagsCount]
  );

  useEffect(() => {
    setTagsCount(fieldProps.value.length);
  }, [fieldProps.value]);

  return (
    <>
      <CustomSelectTags
        label={fieldProps.label}
        name={fieldProps.name}
        autocompleteProps={{
          id: fieldProps.id,
          options: fieldProps.options,
          value: fieldProps.value,
          onChange: (e, newValue) => handleTagsLimit({ newValue: newValue }),
        }}
      />
      <p className={classes.textAlignRight}>
        <span
          className={`${
            tagsCount == fieldProps.tagsLimit ? classes.limitAlert : ""
          }`}
        >
          {tagsCount}
        </span>
        /<span>{fieldProps.tagsLimit}</span>
      </p>
    </>
  );
}

SelectTagsField.propTypes = {
  fieldProps: PropTypes.object,
  itemProps: PropTypes.object,
  autocompleteProps: PropTypes.object,
};

export default SelectTagsField;
