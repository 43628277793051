const modalStyle = {
  modal: {
    borderRadius: "6px",
    position: "relative",
    fontSize: ".875rem",
    minWidth: "300px",
    "@media (min-width: 1024px)": {
      minWidth: "400px",
    },
  },
  modalHeader: {
    borderRadius: "3px",
    background: "#324149",
    padding: "15px",
    borderBottom: "none",
    minHeight: "16.43px",
  },
  modalTitle: {
    margin: "0",
    lineHeight: "1.42857143",
    color: "#fff",
  },
  modalCloseButton: {
    color: "#fff",
    position: "absolute",
    right: "10px",
    top: "10px",
    WebkitAppearance: "none",
    padding: "0",
    cursor: "pointer",
    background: "0 0",
    border: "0",
    fontSize: "inherit",
    opacity: "1",
    textShadow: "none",
    fontWeight: "700",
    lineHeight: "1",
  },
  modalClose: {
    width: "16px",
    height: "16px",
  },
  modalBody: {
    padding: "15px",
    position: "relative",
    "& p": {
      marginBottom: "0px",
      "& a": {
        color: "#e25050",
        textDecoration: "underline",
      },
    },
  },
  modalFooter: {
    padding: "15px",
    textAlign: "right",
    paddingTop: "0",
    margin: "0",
    "& button": {
      zIndex: "1",
      "& span": {
        zIndex: "2",
        position: "relative",
      },
    },
    justifyContent: "space-between",
  },
  modalFooterCenter: {
    marginLeft: "auto",
    marginRight: "auto",
  },
};

export default modalStyle;
