import React from "react";
import useAxios from "axios-hooks";
import useFormContext from "../../FormContext";
import CircularProgressButton from "components/Buttons/CircularProgressButton";
import CustomTooltip from "components/Custom/CustomTooltip";

import { makeStyles } from "@material-ui/core/styles";
import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

function SaveButton() {
  const classes = useStyles();
  const { handleValidateForm, handleGetState } = useFormContext();

  const [{ loading }, saveData] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "cv",
      method: "POST",
    },
    { manual: true }
  );

  const handleSave = async () => {
    handleValidateForm();
    let form_data = new FormData();
    form_data.append("cv_data", JSON.stringify(handleGetState()));
    await saveData({ data: form_data });
  };

  return (
    <div className={classes.sidebarButtonContainer}>
      <div className={classes.sidebarButtonTooltipContainer}>
        <CustomTooltip
          title={"בלחיצה על 'שמירה' תוכלו לצאת ולהמשיך מהנקודה בה הפסקתם."}
          placement="bottom"
        />
      </div>
      <CircularProgressButton
        onClick={() => handleSave()}
        color="info"
        block
        title="שמירה"
        isLoading={loading}
      />
    </div>
  );
}

export default SaveButton;
