import React from "react";
import PropTypes from "prop-types";

export default function TermsMsg({ setOpenTerms, setOpenPrivacy }) {
  const terms = (
    <a
      onClick={(e) => {
        e.preventDefault();
        setOpenTerms(true);
      }}
      style={{
        fontSize: "14px",
        color: "#009999",
        textDecoration: "underline",
        ":hover": {
          cusor: "pointer",
        },
      }}
      href="#"
    >
      תנאי השימוש
    </a>
  );

  const privacy = (
    <a
      onClick={(e) => {
        e.preventDefault();
        setOpenPrivacy(true);
      }}
      style={{
        fontSize: "14px",
        color: "#009999",
        textDecoration: "underline",
        ":hover": {
          cusor: "pointer",
        },
      }}
      href="#"
    >
      מדיניות הפרטיות
    </a>
  );

  return (
    <div
      className="term"
      style={{
        fontSize: "14px",
        color: "#000",
        textAlign: "center",
      }}
    >
      ברישומך למערכת הינך מאשר/ת את {terms} ואת {privacy} של חברת לוג-און תוכנה
      בע״מ
    </div>
  );
}
