import React from "react";
import ReactDOMServer from "react-dom/server";
import { format } from "date-fns";

const MyDocument = ({ data }) => {
  let birthdate = "";
  if (data.privateDetails.birthdate.value) {
    const date = new Date(data.privateDetails.birthdate.value);
    birthdate = format(date, "dd/MM/yyyy");
  }

  let familyStatus = "";
  if (data.privateDetails.familyStatus.value)
    familyStatus = data.privateDetails.familyStatus.value;

  if (data.privateDetails.numOfKids.value > 0)
    familyStatus += `+${data.privateDetails.numOfKids.value}`;

  let firstLineArr = [];
  if (data.privateDetails.phone.value)
    firstLineArr.push(data.privateDetails.phone.value);
  if (data.privateDetails.email.value)
    firstLineArr.push(data.privateDetails.email.value);
  if (data.privateDetails.city.value)
    firstLineArr.push(data.privateDetails.city.value);

  const firstLineStr = firstLineArr.length == 0 ? "" : firstLineArr.join(" | ");

  let secondLineArr = [];
  if (familyStatus) secondLineArr.push(familyStatus);
  if (birthdate) secondLineArr.push(birthdate);
  if (
    data.privateDetails.securityClearance.value &&
    data.privateDetails.securityClearance.value != "לא"
  )
    secondLineArr.push(
      `סיווג בטחוני - ${data.privateDetails.securityClearance.value}`
    );
  if (data.privateDetails.idCardNumber.value)
    secondLineArr.push(data.privateDetails.idCardNumber.value);
  if (data.privateDetails.foreignCitizenship.value)
    secondLineArr.push(
      `אזרחות ${data.privateDetails.foreignCitizenship.value}`
    );
  const secondLineStr =
    secondLineArr.length == 0 ? "" : secondLineArr.join(" | ");

  return (
    <div id="content" direction="rtl" lang="he">
      <section className="headerSection">
        <h1>
          {`${data.privateDetails.firstName.value} ${data.privateDetails.lastName.value}`}{" "}
        </h1>
        <p>{firstLineStr}</p>
        <p>{secondLineStr}</p>
        <p className="summary">{data.privateDetails.summary.value}</p>
      </section>

      {addEducationsSection(data)}
      {addCoursesSection(data)}
      {addSkillsSection(data)}
      {addWorkExperiencesSection(data)}
      {addMilitaryServiceSection(data)}
      {addVolunteeringSection(data)}
      {addLanguagesSection(data)}
      {addAllowRecommendationsSection(data)}
    </div>
  );
};

const prepareYears = (startYear, endYear) => {
  let years = "";
  years += startYear ? `${startYear}` : "";
  years += endYear ? ` - ${endYear}` : "";
  years +=
    endYear == "היום"
      ? `${"\xa0\xa0\xa0\xa0\xa0\xa0\xa0"}`
      : !endYear
      ? `${"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"}`
      : `${"\xa0\xa0\xa0\xa0\xa0"}`;
  return years;
};

const addEducationsSection = (data) => {
  if (!isEmptyObject(data.educations[0])) {
    const items = data.educations.map((education, key) => {
      const years = prepareYears(
        education.startYear.value,
        education.endYear.value
      );
      const text = prepareEducationText(education);

      let achievements = null;
      if (!isEmptyAchievements(education.achievements)) {
        achievements = education.achievements.map((achievement, key) => {
          if (achievement.value) return <li key={key}>{achievement.value}</li>;
        });
      }

      return (
        <div key={key}>
          <h3>
            {years} {text}
          </h3>
          {achievements && <ul>{achievements}</ul>}
        </div>
      );
    });
    return (
      <section className="educationsSection">
        <h2>השכלה</h2>
        <ul>{items}</ul>
      </section>
    );
  }
};

const prepareEducationText = (education) => {
  const studyType = education.studyType.value ? education.studyType.value : "";
  const degree = education.degree.value ? (
    <span>
      <span dir="rtl"> </span>
      {education.degree.value}
    </span>
  ) : (
    ""
  );
  const specialization = education.specialization.value ? (
    <span>
      <span dir="rtl"> עם התמחות ב</span>
      {education.specialization.value}
    </span>
  ) : (
    ""
  );

  let institution = "";
  if (education.institution.value) {
    if (studyType || degree || specialization) {
      institution = <span>, {education.institution.value}</span>;
    } else {
      institution = education.institution.value;
    }
  }

  const dot = studyType || degree || specialization || institution ? "." : "";

  return (
    <span>
      {studyType}
      {degree}
      {specialization}
      {institution}
      {dot}
    </span>
  );
};

const addCoursesSection = (data) => {
  if (!isEmptyObject(data.courses[0])) {
    const items = data.courses.map((course, key) => {
      const years = prepareYears(course.startYear.value, course.endYear.value);
      const text = prepareCourseText(course);

      let achievements = null;
      if (!isEmptyAchievements(course.achievements)) {
        achievements = course.achievements.map((achievement, key) => {
          if (achievement.value) return <li key={key}>{achievement.value}</li>;
        });
      }

      return (
        <div key={key}>
          <h3>
            {years} {text}
          </h3>
          {achievements && <ul>{achievements}</ul>}
        </div>
      );
    });
    return (
      <section className="coursesSection">
        <h2>קורסים והשתלמויות</h2>
        <ul>{items}</ul>
      </section>
    );
  }
};

const prepareCourseText = (course) => {
  const studyType = course.studyType.value ? (
    <span> {course.studyType.value}</span>
  ) : (
    ""
  );
  const specialization = course.specialization.value ? (
    <span> {course.specialization.value}</span>
  ) : (
    ""
  );

  const hours = course.hours.value ? (
    <span dir="rtl">
      <span> )</span>
      <span>{course.hours.value}</span>
      <span> שעות</span>
      <span>(</span>
    </span>
  ) : (
    ""
  );

  const institution = course.institution.value ? (
    <span>, {course.institution.value}</span>
  ) : (
    ""
  );

  const dot = studyType || specialization || hours || institution ? "." : "";

  return (
    <span>
      {studyType}
      {specialization}
      {hours}
      {institution}
      {dot}
    </span>
  );
};

const addWorkExperiencesSection = (data) => {
  if (!isEmptyObject(data.workExperiences[0])) {
    const items = data.workExperiences.map((workExperience, key) => {
      const years = prepareYears(
        workExperience.startYear.value,
        workExperience.endYear.value
      );
      const text = prepareWorkExperienceText(workExperience);

      let achievements = null;
      if (!isEmptyAchievements(workExperience.achievements)) {
        achievements = workExperience.achievements.map((achievement, key) => {
          if (achievement.value) return <li key={key}>{achievement.value}</li>;
        });
      }

      return (
        <div key={key}>
          <h3>
            {years} {text}
          </h3>
          {achievements && <ul>{achievements}</ul>}
        </div>
      );
    });
    return (
      <section className="workExperiencesSection">
        <h2>ניסיון תעסוקתי</h2>
        <ul>{items}</ul>
      </section>
    );
  }
};

// const addWorkExperiencesSection = (data) => {
//   if (data.workExperiences.length > 0) {
//     const items = data.workExperiences.map((workExperience, key) => {
//       const years = prepareYears(
//         workExperience.startYear,
//         workExperience.endYear
//       );
//       const text = prepareWorkExperienceText(workExperience);
//       const roles = createWorkExperiencesRoles(workExperience.roles);
//       return (
//         <div key={key}>
//           <h3>
//             <span className="yearsLevelOne">{years}</span> <span>{text}</span>
//           </h3>
//           {roles}
//         </div>
//       );
//     });
//     return (
//       <section className="workExperiencesSection">
//         <h2>ניסיון תעסוקתי</h2>
//         <ul>{items}</ul>
//       </section>
//     );
//   }
// };

const prepareWorkExperienceText = (workExperience) => {
  let text = "";
  text += workExperience.title.value ? " " + workExperience.title.value : "";
  text += workExperience.company.value
    ? ", " + workExperience.company.value
    : "";
  return <span className="textLevelOne">{text}</span>;
};

// const prepareWorkExperienceText = (workExperience) => {
//   let text = workExperience.company ? workExperience.company : "";
//   text +=
//     workExperience.roles.length == 1
//       ? `, ${workExperience.roles[0].title}`
//       : "";

//   return <span className="textLevelOne">{text}</span>;
// };

// const createWorkExperiencesRoles = (roles) => {
//   if (roles.length > 1) {
//     return roles.map((role, key) => {
//       const years = prepareYears(role.startYear, role.endYear);
//       const text = role.title;
//       const achievements = role.achievements.map((achievement, key) => {
//         return <li key={key}>{achievement}</li>;
//       });
//       return (
//         <div key={key} className={"levelTwo"}>
//           <h3>
//             <span className="years">{years}</span>{" "}
//             <span className="text">{text}</span>
//           </h3>
//           <ul>{achievements}</ul>
//         </div>
//       );
//     });
//   } else {
//     const achievements = roles[0].achievements.map((achievement, key) => {
//       return <li key={key}>{achievement}</li>;
//     });
//     return <ul>{achievements}</ul>;
//   }
// };

const addMilitaryServiceSection = (data) => {
  if (!isEmptyObject(data.militaryService[0])) {
    const roles = createMilitaryServiceRoles(data.militaryService);
    const title = data.militaryService[0].type.value
      ? `שירות ${data.militaryService[0].type.value}`
      : `שירות`;
    return (
      <section className="militaryServiceSection">
        <h2>{title}</h2>
        <ul>{roles}</ul>
      </section>
    );
  }
};

// const addMilitaryServiceSection = (data) => {
//   if (data.militaryService.roles.length > 0) {
//     const roles = createMilitaryServiceRoles(data.militaryService.roles);

//     return (
//       <section className="militaryServiceSection">
//         <h2>{data.militaryService.type}</h2>
//         <ul>{roles}</ul>
//       </section>
//     );
//   }
// };

const createMilitaryServiceRoles = (roles) => {
  return roles.map((role, key) => {
    const years = prepareYears(role.startYear.value, role.endYear.value);
    const text = role.title.value;

    let achievements = null;
    if (!isEmptyAchievements(role.achievements)) {
      achievements = role.achievements.map((achievement, key) => {
        if (achievement.value) return <li key={key}>{achievement.value}</li>;
      });
    }

    return (
      <div key={key}>
        <h3>
          <span className="years">{years}</span>{" "}
          <span className="text">{text}</span>
        </h3>
        {achievements && <ul>{achievements}</ul>}
      </div>
    );
  });
};

const addVolunteeringSection = (data) => {
  if (!isEmptyObject(data.volunteering[0])) {
    const roles = createVolunteeringRoles(data.volunteering);

    return (
      <section className="volunteeringSection">
        <h2>התנדבות</h2>
        {roles}
      </section>
    );
  }
};

// const addVolunteeringSection = (data) => {
//   if (data.volunteering.roles.length > 0) {
//     const roles = createVolunteeringRoles(data.volunteering.roles);

//     return (
//       <section className="volunteeringSection">
//         <h2>התנדבות</h2>
//         {roles}
//       </section>
//     );
//   }
// };

const createVolunteeringRoles = (roles) => {
  return roles.map((role, key) => {
    const years = prepareYears(role.startYear.value, role.endYear.value);
    const text = prepareVolunteeringRoleText(role);
    return (
      <div key={key}>
        <h3>
          <span className="years">{years}</span>{" "}
          <span className="text">{text}</span>
        </h3>
      </div>
    );
  });
};

const prepareVolunteeringRoleText = (role) => {
  let text = "";
  text += role.organization.value ? role.organization.value : "";
  text += role.title.value ? ` - ${role.title.value}` : "";
  return text;
};

const addSkillsSection = (data) => {
  if (data.privateDetails.skills.value.length > 0) {
    const skills = data.privateDetails.skills.value.join(", ");
    return (
      <section className="skillsSection">
        <h2>כלים וטכנולוגיות</h2>
        <p>{skills}</p>
      </section>
    );
  }
};

// const addSkillsSection = (data) => {
//   if (data.skills.length > 0) {
//     return (
//       <section className="skillsSection">
//         <h2>כלים וטכנולוגיות</h2>
//         <ul>{[...data.skills].join(", ")}</ul>
//       </section>
//     );
//   }
// };

const addLanguagesSection = (data) => {
  if (!isEmptyObject(data.languages[0]) && data.languages[0].title.value) {
    const languages = createLanguagesText(data.languages);

    return (
      <section className="languagesSection">
        <h2>שפות</h2>
        <div>{languages}</div>
      </section>
    );
  }
};

const createLanguagesText = (languages) => {
  return languages.map((language, key) => {
    const divider = key != 0 ? " | " : "";
    const title = <span className="title">{language.title.value}</span>;
    const mid = language.title.value && language.level.value ? " - " : "";
    const level = <span>{language.level.value}</span>;

    return (
      <span key={key}>
        {divider}
        {title}
        {mid}
        {level}
      </span>
    );
  });
};

const addAllowRecommendationsSection = (data) => {
  if (data.privateDetails.allowRecommendations.value) {
    const text = "* המלצות יינתנו על פי דרישה.";
    return (
      <section className="allowRecommendationsSection">
        <div>{text}</div>
      </section>
    );
  }
};

const isEmptyAchievements = (achievementsArr) => {
  const filteredAchievementsArr = achievementsArr.filter(
    (achievement) => achievement.value != ""
  );
  return filteredAchievementsArr.length === 0 ? true : false;
};

const isEmptyObject = (obj) => {
  let isEmpty = true;

  Object.entries(obj).forEach(([key, item]) => {
    if (typeof item === "object" && item !== null && !Array.isArray(item)) {
      if (item.value) {
        isEmpty = false;
      }
    }
  });
  return isEmpty;
};

export default MyDocument;
