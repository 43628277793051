import React from "react";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import SidebarSkeleton from "components/Skeletons/SidebarSkeleton";
import CardSkeleton from "components/Skeletons/CardSkeleton";

export default function ProcessStepSkeleton() {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={9}>
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <SidebarSkeleton />
      </GridItem>
    </GridContainer>
  );
}
