import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Box } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

export default function EmptyViewSkeleton() {
  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <Box style={{ marginBottom: "30px" }}>
            <Skeleton variant="rect" height={50} />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem sm={9}>
          <Skeleton variant="rect" height={500} />
        </GridItem>
        <GridItem sm={3}>
          <Skeleton variant="rect" height={500} />
        </GridItem>
      </GridContainer>
    </>
  );
}
