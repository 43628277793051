const cvGeneratorStyle = {
  downloadButton: {
    fontSize: "14px",
  },
  switchLabel: {
    marginTop: "25px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  switchButton: {},

  limitAlert: {
    color: "#e25050",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  textAlignRight: {
    textAlign: "right",
  },
  subTitleContainer: {
    width: "100%",
  },
  subTitle: {
    paddingRight: "15px",
    marginTop: "27px",
    marginLeft: "10px",
    color: "#324149",
    textDecoration: "underline",
    float: "left",
  },

  border: {
    marginBottom: "15px",
    marginTop: "30px",
    width: "250px",
    marginRight: "auto",
    marginLeft: "auto",
    borderBottom: "1px dashed #324149",
    textAlign: "center",
    borderRadius: "5px",
    background: "#324149",
    color: "#fff",
  },
  borderInner: {},
  borderTitle: {
    display: "inline-block",
  },
  addButton: {
    marginTop: "30px",
    fontSize: "14px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  removeButton: {
    display: "inline-block",
    "&:hover": {
      cursor: "pointer",
    },
  },
  drawerPaper: {
    width: 300,
    padding: 15,
  },
  repeaterAddButton: {
    float: "right",
    marginTop: "20px",
    marginRight: "15px",
    fontSize: "12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  repeaterRemoveButton: {
    position: "absolute",
    top: "46px",
    left: "-4px",
    width: "16px !important",
    height: "16px !important",
    minWidth: "5px !important",
    padding: "3px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  repeaterRemoveButtonSvgicon: {
    width: "10px !important",
    height: "10px !important",
  },
  sidebarButtonContainer: {
    position: "relative",
    width: "100%",
  },
  sidebarButtonTooltipContainer: {
    position: "absolute",
    left: "-20px",
  },
};

export default cvGeneratorStyle;
