import React from "react";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import useFormContext from "../../FormContext";

import SwitchField from "../Fields/SwitchField";

export default function Recommendations() {
  const { handleGetState } = useFormContext();
  const { privateDetails } = handleGetState();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <SwitchField
          fieldProps={{
            label: "המלצות יינתנו על פי דרישה",
            name: "allowRecommendations",
            id: privateDetails.allowRecommendations.id,
            value: privateDetails.allowRecommendations.value,
            type: "text",
          }}
          itemProps={{
            id: privateDetails.id,
            name: "privateDetails",
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
