import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import useFormContext from "../../FormContext";

import Button from "components/CustomButtons/Button";

import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

function RemoveButton({ name, id, label }) {
  const classes = useStyles();
  const { handleRemove } = useFormContext();
  return (
    <div className={classes.border}>
      <div className={classes.borderInner}>
        <span className={classes.borderTitle}>{label}</span>
        {" - "}
        <Button
          className={classes.removeButton}
          type="button"
          color="danger"
          size="sm"
          onClick={() => handleRemove({ name: name, id: id })}
        >
          <span>הסרה</span>
        </Button>
      </div>
    </div>
  );
}

RemoveButton.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
};

export default RemoveButton;
