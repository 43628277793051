import React from "react";
import useAxios from "axios-hooks";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import FriendSkeleton from "./FriendSkeleton";
import FriendEmpty from "./Empty/FriendEmpty";
import FriendSummary from "./Summary/FriendSummary";
import FriendTable from "./Table/FriendTable";
import FriendDropzone from "./Dropzone/FriendDropzone";
import useGaTracker from "hooks//useGaTracker";

export default function Friend() {
  useGaTracker();
  const [{ data, loading }] = useAxios({
    url: process.env.REACT_APP_API_URL + "friend/view",
  });

  if (loading) return <FriendSkeleton />;

  if (data.view_mode == "empty") return <FriendEmpty />;

  return (
    <>
      <FriendSummary />

      <GridContainer>
        <GridItem xs={12}>
          <FriendTable />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <FriendDropzone />
        </GridItem>
      </GridContainer>
    </>
  );
}
