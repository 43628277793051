import React, { useState } from "react";
import useAxios from "axios-hooks";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Typography from "@material-ui/core/Typography";

import EmailIcon from "@material-ui/icons/Email";
import SmsIcon from "@material-ui/icons/Sms";

import { useAuth } from "context/Auth";

import ColorlibStepIcon from "components/Custom/ColorlibStepIcon.js";
import {
  stepsStyle,
  colorlibConnectorStyle,
} from "assets/jss/material-dashboard-pro-react/components/stepsStyle.js";

import LoginEmailStep from "./LoginEmailStep";
import LoginSmsStep from "./LoginSmsStep";

const ColorlibConnector = withStyles(colorlibConnectorStyle)(StepConnector);

const useStyles = makeStyles(stepsStyle);

export default function LoginSteps() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [sessionToken, setSessionToken] = useState(null);
  const { handleLogin } = useAuth();

  const [, execute] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user/login/connect",
      method: "POST",
    },
    { manual: true }
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleLoginComplete = async (token) => {
    const result = await execute({
      data: { sessionToken: token },
    });
    handleLogin(result.data.token, result.data.user);
  };

  const steps = [
    {
      label: "אימות באמצעות אימייל",
      content: (
        <LoginEmailStep
          handleComplete={handleNext}
          sessionToken={sessionToken}
          setSessionToken={setSessionToken}
        />
      ),
      icon: <EmailIcon role="tab" titleAccess={"אימות באמצעות אימייל"} />,
    },
    {
      label: "אימות באמצעות סמס",
      content: (
        <LoginSmsStep
          handleComplete={handleLoginComplete}
          sessionToken={sessionToken}
          setSessionToken={setSessionToken}
        />
      ),
      icon: <SmsIcon titleAccess={"אימות באמצעות סמס"} />,
    },
  ];

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        style={{
          padding: "10px 0",
          margin: "15px -20px 30px",
          background: "#eee",
        }}
      >
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={ColorlibStepIcon} icon={step.icon}>
              <span role="tabpanel" aria-controls="tabpanel">
                {step.label}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
          </div>
        ) : (
          <div>{steps[activeStep].content}</div>
        )}
      </div>
    </div>
  );
}
