import React from "react";
import PropTypes from "prop-types";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";

export default function PrimaryEmail({ primaryEmail }) {
  return (
    <GridContainer alignItems="flex-end">
      <GridItem xs={12} sm={12} md={6}>
        <CustomInput
          labelText="כתובת מייל ראשית"
          id="primaryEmail"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            disabled: true,
            name: "primaryEmail",
            value: primaryEmail,
            type: "email",
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
PrimaryEmail.propTypes = {
  primaryEmail: PropTypes.string,
};
