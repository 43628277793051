import { v4 as uuidv4 } from "uuid";

const privateDetails = {
  id: uuidv4(),
  firstName: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  lastName: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  city: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  idCardNumber: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  email: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  phone: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  familyStatus: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  numOfKids: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  birthdate: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  securityClearance: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  foreignCitizenship: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  allowRecommendations: {
    id: uuidv4(),
    value: false,
    error: null,
  },
  summary: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  skills: {
    id: uuidv4(),
    value: [],
    error: null,
  },
};

const education = {
  id: uuidv4(),
  startYear: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  endYear: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  institution: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  studyType: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  degree: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  specialization: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  achievements: [
    {
      id: uuidv4(),
      value: "",
      error: null,
    },
  ],
};

const course = {
  id: uuidv4(),
  startYear: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  endYear: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  institution: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  studyType: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  specialization: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  hours: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  achievements: [
    {
      id: uuidv4(),
      value: "",
      error: null,
    },
  ],
};

const workExperiences = {
  id: uuidv4(),
  startYear: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  endYear: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  company: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  title: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  achievements: [
    {
      id: uuidv4(),
      value: "",
      error: null,
    },
  ],
};

const militaryService = {
  id: uuidv4(),
  type: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  startYear: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  endYear: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  title: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  unit: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  degree: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  achievements: [
    {
      id: uuidv4(),
      value: "",
      error: null,
    },
  ],
};

const volunteering = {
  id: uuidv4(),
  startYear: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  endYear: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  organization: {
    id: uuidv4(),
    value: "",
    error: null,
  },
  title: {
    id: uuidv4(),
    value: "",
    error: null,
  },
};

const languages = {
  id: uuidv4(),
  title: {
    id: uuidv4(),
    value: null,
    error: null,
  },
  level: {
    id: uuidv4(),
    value: null,
    error: null,
  },
};

export const DEFAULT_STATE = {
  privateDetails: { ...privateDetails },
  educations: [{ ...education }],
  courses: [{ ...course }],
  workExperiences: [{ ...workExperiences }],
  militaryService: [{ ...militaryService }],
  volunteering: [{ ...volunteering }],
  languages: [{ ...languages }],
};

export const FormReducer = (state, { action, payload }) => {
  switch (action) {
    case "SET": {
      state.privateDetails = { ...payload.privateDetails };
      state.educations = [...payload.educations];
      state.courses = [...payload.courses];
      state.workExperiences = [...payload.workExperiences];
      state.militaryService = [...payload.militaryService];
      state.volunteering = [...payload.volunteering];
      state.languages = [...payload.languages];
      return;
    }
    case "UPDATE": {
      const isArray = Array.isArray(state[payload.name]);

      if (isArray) {
        const currentItem = state[payload.name].find(
          (item) => item.id == payload.id
        );
        if (payload.pos !== undefined) {
          const currentSubItem = currentItem[payload.subName].find(
            (subItem) => subItem.id == payload.pos
          );
          currentSubItem.value = payload.newValue;
          currentSubItem.error = payload.error;
          //currentItem[payload.subName][payload.pos].value = payload.newValue;
          //currentItem[payload.subName][payload.pos].error = payload.error;
        } else {
          currentItem[payload.subName].value = payload.newValue;
          currentItem[payload.subName].error = payload.error;
        }
      } else {
        state[payload.name][payload.subName].value = payload.newValue;
        state[payload.name][payload.subName].error = payload.error;
      }

      return;
    }
    case "ADD": {
      if (payload.id !== undefined && payload.subName !== undefined) {
        const currentItem = state[payload.name].find(
          (item) => item.id == payload.id
        );
        currentItem[payload.subName].push({
          id: uuidv4(),
          value: "",
          error: null,
        });
      } else {
        state[payload.name].push({
          ...DEFAULT_STATE[payload.name][0],
          id: uuidv4(),
        });
      }
      return;
    }
    case "REMOVE": {
      if (payload.subName !== undefined && payload.subNameId !== undefined) {
        const currentItem = state[payload.name].find(
          (item) => item.id == payload.id
        );
        const index = currentItem[payload.subName].findIndex(
          (item) => item.id == payload.subNameId
        );
        currentItem[payload.subName].splice(index, 1);
      } else {
        const index = state[payload.name].findIndex(
          (item) => item.id == payload.id
        );
        state[payload.name].splice(index, 1);
      }
      return;
    }
    case "CLEAR": {
      state.privateDetails = { ...privateDetails };
      state.educations = [{ ...education }];
      state.courses = [{ ...course }];
      state.workExperiences = [{ ...workExperiences }];
      state.militaryService = [{ ...militaryService }];
      state.volunteering = [{ ...volunteering }];
      state.languages = [{ ...languages }];
      return;
    }
  }
};
