import React from "react";
import useAxios from "axios-hooks";
import useGaTracker from "hooks//useGaTracker";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import InterviewSkeleton from "./InterviewSkeleton";
import InterviewEmpty from "./Empty/InterviewEmpty";
import InterviewChat from "./Chat/InterviewChat";
import InterviewSidebar from "./Sidebar/InterviewSidebar";

export default function Interview() {
  useGaTracker();

  const [{ data, loading }] = useAxios({
    url: process.env.REACT_APP_API_URL + "interview/view",
  });

  if (loading) return <InterviewSkeleton />;

  if (data.view_mode == "empty") return <InterviewEmpty />;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={9}>
        <InterviewChat />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <InterviewSidebar />
      </GridItem>
    </GridContainer>
  );
}
