import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import useFormContext from "../../FormContext";
import {
  generateYearsOptions,
  militaryServiceTypeOptions,
} from "../../options";

import InputField from "../Fields/InputField";
import SelectField from "../Fields/SelectField";
import Repeater from "../Fields/Repeater";
import AddButton from "../Buttons/AddButton";
import RemoveButton from "../Buttons/RemoveButton";

import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

export default function MilitaryService() {
  const classes = useStyles();
  const { handleGetState } = useFormContext();
  const { militaryService } = handleGetState();

  return (
    <>
      {militaryService.map((item, i) => (
        <GridContainer key={i} className={classes.border}>
          {i > 0 && (
            <GridItem xs={12} sm={12}>
              <RemoveButton
                name="militaryService"
                id={item.id}
                label="שירות צבאי/לאומי נוסף"
              />
            </GridItem>
          )}

          <GridItem xs={12} sm={12} md={6}>
            <SelectField
              fieldProps={{
                label: "שירות צבאי/לאומי",
                name: "type",
                id: item.type.id,
                value: item.type.value,
                options: militaryServiceTypeOptions,
              }}
              itemProps={{
                id: item.id,
                name: "militaryService",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <InputField
              fieldProps={{
                label: "תפקיד",
                name: "title",
                id: item.title.id,
                value: item.title.value,
                type: "text",
              }}
              itemProps={{
                id: item.id,
                name: "militaryService",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <InputField
              fieldProps={{
                label: "יחידה",
                name: "unit",
                id: item.unit.id,
                value: item.unit.value,
                type: "text",
              }}
              itemProps={{
                id: item.id,
                name: "militaryService",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <InputField
              fieldProps={{
                label: "דרגת שחרור",
                name: "degree",
                id: item.degree.id,
                value: item.degree.value,
                type: "text",
              }}
              itemProps={{
                id: item.id,
                name: "militaryService",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <SelectField
              fieldProps={{
                label: "שנת התחלה",
                name: "startYear",
                id: item.startYear.id,
                value: item.startYear.value,
                options: generateYearsOptions(),
              }}
              itemProps={{
                id: item.id,
                name: "militaryService",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <SelectField
              fieldProps={{
                label: "שנת סיום",
                name: "endYear",
                id: item.endYear.id,
                value: item.endYear.value,
                options: generateYearsOptions(),
              }}
              itemProps={{
                id: item.id,
                name: "militaryService",
              }}
            />
          </GridItem>

          <Repeater
            title="תיאור תפקיד"
            items={item.achievements}
            fieldProps={{
              label: "תיאור תפקיד",
              name: "achievements",
              type: "text",
              charsLimit: 100,
            }}
            itemProps={{
              id: item.id,
              name: "militaryService",
            }}
            tooltipProps={{
              title:
                "סעיף זה הוא המקום למלא פרטים אודות הישגים בתקופת השירות כגון הצטיינות, התמחויות והרחבות, פרסים, ממוצע ציונים, פרויקטים בולטים ועוד.",
            }}
            addButtonTitle="הוספת תיאור תפקיד"
          />
        </GridContainer>
      ))}

      {militaryService.length < 10 && (
        <AddButton name="militaryService" label="הוספת שירות צבאי/לאומי" />
      )}
    </>
  );
}
