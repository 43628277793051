import React from "react";
import PropTypes from "prop-types";
import useFormContext from "../../FormContext";
import CustomInput from "components/CustomInput/CustomInput";

function InputField({ fieldProps, itemProps }) {
  const { handleChange } = useFormContext();

  return (
    <CustomInput
      labelText={fieldProps.label}
      id={fieldProps.id}
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        name: fieldProps.name,
        type: fieldProps.type,
        value: fieldProps.value,
        onChange: (e) =>
          handleChange({
            name: itemProps.name,
            newValue: e.target.value,
            subName: fieldProps.name,
            id: itemProps.id,
            pos: fieldProps.pos,
          }),
        inputProps: {
          "aria-required": !!fieldProps.required,
        },
        endAdornment: fieldProps.endAdornment ? fieldProps.endAdornment : false,
      }}
      error={!!fieldProps.error}
    />
  );
}

InputField.propTypes = {
  fieldProps: PropTypes.object,
  itemProps: PropTypes.object,
};

export default InputField;
