import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";

export const CssTextField = withStyles({
  root: {
    margin: "27px 0 0 0",
    position: "relative",
    paddingBottom: "10px",
    verticalAlign: "unset",
    "& label.MuiInputLabel-formControl": {
      color: "#AAAAAA",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#eee",
    },
    "& .MuiInputBase-input": {
      fontWeight: 300,
      fontSize: "1rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& .MuiFormLabel-root": {
      fontWeight: 300,
      fontSize: ".875rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#eee",
      },
      "&:hover fieldset": {
        borderColor: "#eee",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#eee",
      },
    },
  },
})(TextField);

function CustomFreeSoloSelect(props) {
  const { label, autocompleteProps } = props;
  return (
    <Autocomplete
      freeSolo
      renderInput={(params) => <CssTextField {...params} label={label} />}
      forcePopupIcon
      {...autocompleteProps}
    />
  );
}

export default CustomFreeSoloSelect;
