import React from "react";
import PropTypes from "prop-types";
import useFormContext from "../../FormContext";
import CustomSelect from "components/CustomSelect/CustomSelect";

function SelectField({ fieldProps, itemProps }) {
  const { handleChange } = useFormContext();
  return (
    <CustomSelect
      label={fieldProps.label}
      name={fieldProps.name}
      autocompleteProps={{
        id: fieldProps.id,
        options: fieldProps.options,
        getOptionLabel: (option) => option,
        getOptionSelected: (option, value) => {
          return option == value;
        },
        value: fieldProps.value,
        onChange: (e, newValue) =>
          handleChange({
            name: itemProps.name,
            newValue: newValue,
            subName: fieldProps.name,
            id: itemProps.id,
          }),
      }}
    />
  );
}

SelectField.propTypes = {
  fieldProps: PropTypes.object,
  itemProps: PropTypes.object,
  autocompleteProps: PropTypes.object,
};

export default SelectField;
