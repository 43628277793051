import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import useFormContext from "../../FormContext";

import Button from "components/CustomButtons/Button";

import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

function RepeaterAddButton({ name, id, subName, label }) {
  const classes = useStyles();
  const { handleAdd } = useFormContext();
  return (
    <Button
      className={classes.repeaterAddButton}
      type="button"
      color="success"
      size="sm"
      onClick={() =>
        handleAdd({
          name: name,
          id: id,
          subName: subName,
        })
      }
    >
      <span>{label}</span>
    </Button>
  );
}

RepeaterAddButton.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  subName: PropTypes.string,
  label: PropTypes.string,
};

export default RepeaterAddButton;
