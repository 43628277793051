const tabsStyle = {
  root: {
    flexGrow: 1,
    maxWidth: 500,
    "& .MuiTab-labelIcon.Mui-selected": {
      color: "#e25050",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#e25050",
    },
  },

  tab: {},
};

export default tabsStyle;
