import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { FacebookProvider, Page } from "react-facebook";

const MyComponents = {
  BannerImage: function BannerImage(props) {
    const { banner } = props;
    return (
      <div className="banner">
        {banner.link_type == "external_link" ? (
          <a
            href={banner.external_link}
            target="_blank"
            rel="noopener noreferrer"
            title={banner.image.alt + " - נפתח בלשונית חדשה"}
          >
            <img
              style={{ maxWidth: "100%", height: "auto" }}
              src={banner.image.url}
              alt={banner.image.alt}
            />
          </a>
        ) : banner.link_type == "internal_link" ? (
          <Link to={banner.internal_link}>
            <img
              style={{ maxWidth: "100%", height: "auto" }}
              src={banner.image.url}
              alt={banner.image.alt}
            />
          </Link>
        ) : (
          <img
            style={{ maxWidth: "100%", height: "auto" }}
            src={banner.image.url}
            alt={banner.image.alt}
          />
        )}
      </div>
    );
  },
  BannerFacebook: function BannerFacebook(props) {
    return (
      <div className="banner">
        <FacebookProvider appId={props.appId}>
          <Page
            height={70}
            href="https://www.facebook.com/TheBasketballFans/"
            tabs="timeline"
          />
        </FacebookProvider>
      </div>
    );
  },
};

export default function Banners({ banners }) {
  const listBanners = useMemo(
    () => (
      <div style={{ paddingTop: "10px", textAlign: "center" }}>
        {banners &&
          banners.map((banner, index) => {
            switch (banner.type) {
              case "image":
                return <MyComponents.BannerImage key={index} banner={banner} />;
              case "facebook":
                //const appId = "2085778448353871"; //'788676134514877' //= '2085778448353871';
                const appId = "";
                return (
                  <MyComponents.BannerFacebook key={index} appId={appId} />
                );
            }
          })}
      </div>
    ),
    [banners]
  );

  if (banners == false) return null;

  return listBanners;
}
