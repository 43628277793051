import headerLinksStyle from "assetskit/jss/material-kit-pro-react/components/headerLinksStyle.js";
import {
  container,
  mrAuto,
  title,
  blackColor,
  whiteColor,
  hexToRgb,
} from "assetskit/jss/material-kit-pro-react.js";

const headersSection = (theme) => ({
  ...headerLinksStyle(theme),
  sectionBlank: {
    height: "70px",
    display: "block",
  },
  container: {
    ...container,
    zIndex: "3",
    position: "relative",
    "& h1, & h4, & h6": {
      color: whiteColor,
    },
  },
  conatinerHeader2: {
    ...container,
    zIndex: "2",
    position: "relative",
    "& h1, & h4, & h6": {
      color: whiteColor,
    },
    paddingTop: "25vh",
  },
  title: {
    fontWeight: "bold",
    padding: "0",
    margin: "0",
    lineHeight: "1em",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
  },
  desc: {
    fontSize: "20px",
    lineHeight: "1.4em",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1em",
      marginBottom: "30px",
    },
  },
  pageHeader: {
    position: "relative",
    height: "100vh",
    maxHeight: "1600px",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.5)",
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
    },
  },
  video: {
    position: "relative",
    paddingBottom: "56.25%" /* 16:9 */,
    paddingTop: 25,
    height: 0,

    [theme.breakpoints.down("sm")]: {
      margin: "35px 0 70px",
    },
  },
  iframeContainer: {
    "& > iframe": {
      width: "100%",
      boxShadow:
        "0 16px 38px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.56), 0 4px 25px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)",
    },
  },
  mrAuto,
  textCenter: {
    textAlign: "center",
  },
  card: {
    marginTop: "60px",
  },
  formControl: {
    margin: "0",
    padding: "8px 0 0 0",
  },
  textRight: {
    textAlign: "right",
  },
  button: {
    margin: "0 !important",
  },
});

export default headersSection;
