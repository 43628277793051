import React, { useState } from "react";
import useAxios from "axios-hooks";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import CustomCardVideo from "components/Custom/CustomCardVideo";
import CustomCardContent from "components/Custom/CustomCardContent";
import Banners from "components/Custom/Banners";

import FriendDropzone from "../Dropzone/FriendDropzone";
import EmptyViewSkeleton from "components/Skeletons/EmptyViewSkeleton";
import FriendTermsDialog from "components/Dialogs/FriendTermsDialog";

export default function FriendEmpty() {
  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [{ data, loading }] = useAxios({
    url: process.env.REACT_APP_API_URL + "friend/empty",
  });

  const terms = (
    <a
      onClick={(e) => {
        e.preventDefault();
        setOpenTermsDialog(true);
      }}
      style={{
        fontSize: "14px",
        color: "rgba(255, 255, 255,.62)",
        textDecoration: "underline",
        ":hover": {
          cusor: "pointer",
        },
      }}
      href="#"
    >
      לתקנון המבצע לחץ/י כאן.
    </a>
  );

  if (loading) return <EmptyViewSkeleton />;

  const description = (
    <>
      {data.content_section.sub_title} {terms}
    </>
  );

  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <CustomCardContent
            titleTag={"h2"}
            title={data.content_section.main_title}
            description={description}
            content={data.content_section.content}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <FriendDropzone />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem sm={9}>
          <CustomCardVideo
            title={data.video_section.main_title}
            description={data.video_section.sub_title}
            youtube_id={data.video_section.youtube_id}
          />
        </GridItem>
        <GridItem sm={3}>
          <Banners banners={data.banners_sidebar} />
        </GridItem>
      </GridContainer>

      <FriendTermsDialog open={openTermsDialog} setOpen={setOpenTermsDialog} />
    </>
  );
}
