import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import useFormContext from "../../FormContext";

import CustomInput from "components/CustomInput/CustomInput";

import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

function TextareaField({ fieldProps, itemProps }) {
  const classes = useStyles();
  const { handleChange } = useFormContext();
  const [charCount, setCharCount] = useState(0);

  const handleCharLimit = useCallback(
    (args) => {
      const { newValue } = args;
      if (newValue.length > fieldProps.charsLimit) {
        setCharCount(fieldProps.charsLimit);
      } else {
        setCharCount(newValue.length);
        handleChange({
          name: itemProps.name,
          newValue: newValue,
          subName: fieldProps.name,
          id: itemProps.id,
          pos: fieldProps.pos,
        });
      }
    },
    [setCharCount]
  );

  useEffect(() => {
    setCharCount(fieldProps.value.length);
  }, [fieldProps.value]);

  return (
    <>
      <CustomInput
        labelText={fieldProps.label}
        id={fieldProps.id}
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          name: fieldProps.name,
          type: fieldProps.type,
          value: fieldProps.value,
          onChange: (e) => handleCharLimit({ newValue: e.target.value }),
          inputProps: {
            "aria-required": !!fieldProps.required,
          },
          multiline: true,
          rows: fieldProps.rows,
        }}
        error={!!fieldProps.error}
      />
      <p className={classes.textAlignRight}>
        <span
          className={`${
            charCount == fieldProps.charsLimit ? classes.limitAlert : ""
          }`}
        >
          {charCount}
        </span>
        /<span>{fieldProps.charsLimit}</span>
      </p>
    </>
  );
}

TextareaField.propTypes = {
  fieldProps: PropTypes.object,
  itemProps: PropTypes.object,
};

export default TextareaField;
