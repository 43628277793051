import React, { useEffect } from "react";
import useAxios from "axios-hooks";

import useFormContext from "../../FormContext";

import CustomCard from "components/Custom/CustomCard";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import Summary from "../Sections/Summary";
import PrivateDetails from "../Sections/PrivateDetails";
import Educations from "../Sections/Educations";
import Courses from "../Sections/Courses";
import WorkExperiences from "../Sections/WorkExperiences";
import MilitaryService from "../Sections/MilitaryService";
import Volunteering from "../Sections/Volunteering";
import Languages from "../Sections/Languages";
import Skills from "../Sections/Skills";
import Recommendations from "../Sections/Recommendations";

function FormMain() {
  const { handleSet } = useFormContext();

  const [, getData] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "cv",
      method: "GET",
    },
    { manual: true }
  );

  useEffect(() => {
    async function loadData() {
      const result = await getData();
      if (result.data.cvneto_cv_data) {
        handleSet(result.data.cvneto_cv_data);
      }
    }
    loadData();
  }, []);

  return (
    <form>
      <GridContainer>
        <GridItem xs={12}>
          <CustomCard
            titleTag={"h2"}
            title={"תמצית"}
            description="התמצית אמורה להיות פסקה קצרה ועניינית בין 3-4 שורות, המסכמת את הניסיון והכישורים שלכם, תכונות האופי שלכם ומה יתרונותיכם."
          >
            <Summary />
          </CustomCard>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <CustomCard
            titleTag={"h2"}
            title={"פרטים אישיים"}
            description="בסקשן זה תמלאו פרטי מידע בסיסיים הנדרשים לקורות החיים, שימו לב מה הם סעיפי החובה ומהם האופציונאליים."
          >
            <PrivateDetails />
          </CustomCard>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <CustomCard
            titleTag={"h2"}
            title={"השכלה"}
            description="בסקשן זה תמלאו את פרטי ההשכלה התיכונית ו/או האקדמאית. <span style='font-weight:bold'>יש למלא את ההשכלה בסדר כרונולוגי, על פי שנים מהמאוחרת למוקדמת (השכלה אקדמית ואז השכלה תיכונית).</span>"
          >
            <Educations />
          </CustomCard>
        </GridItem>
        <GridItem xs={12}>
          <CustomCard
            titleTag={"h2"}
            title={"קורסים והשתלמויות"}
            description="בסקשן זה תמלאו את פרטי הקורסים השונים וההשתלמויות שביצעתם, כולל הסמכות ומספר שעות לימוד. <span style='font-weight:bold'>יש למלא את הסעיפים בסדר כרונולוגי, על פי שנים מהמאוחר למוקדם.</span>"
          >
            <Courses />
          </CustomCard>
        </GridItem>

        <GridItem xs={12}>
          <CustomCard
            titleTag={"h2"}
            title={"כלים וטכנולוגיות"}
            description="בסקשן זה תמלאו את הכלים והטכנולוגיות שאתם מתמחים בהם, כגון: HTML, JAVA, PHP... ניתן לבחור מהרשימה ו\או להוסיף על ידי הקלדה ולחיצה על אנטר."
          >
            <Skills />
          </CustomCard>
        </GridItem>

        <GridItem xs={12}>
          <CustomCard
            titleTag={"h2"}
            title={"נסיון תעסוקתי"}
            description="בסקשן זה עליכם לתאר את הניסיון התעסוקתי שלכם. <span style='font-weight:bold'>יש למלא את הניסיון התעסוקתי בסדר כרונולוגי, על פי שנים מהנוכחי או האחרון לקודם.</span>"
          >
            <WorkExperiences />
          </CustomCard>
        </GridItem>
        <GridItem xs={12}>
          <CustomCard
            titleTag={"h2"}
            title={"שירות צבאי/לאומי"}
            description='בסקשן זה תמלאו את תיאור השירות הצבאי/ שירות לאומי/ התנדבות במסגרת צה"ל.'
          >
            <MilitaryService />
          </CustomCard>
        </GridItem>
        <GridItem xs={12}>
          <CustomCard
            titleTag={"h2"}
            title={"התנדבות"}
            description="בסקשן זה יש לתאר פעילויות התנדבות במידה וביצעתם."
          >
            <Volunteering />
          </CustomCard>
        </GridItem>
        <GridItem xs={12}>
          <CustomCard
            titleTag={"h2"}
            title={"שפות"}
            description="בסקשן זה תוכלו למלא את רמת השליטה בשפות שונות."
          >
            <Languages />
          </CustomCard>
        </GridItem>
        <GridItem xs={12}>
          <CustomCard
            titleTag={"h2"}
            title={"המלצות"}
            description="בסקשן זה תוכלו לאשר האם לציין כי המלצות יינתנו על פי דרישה."
          >
            <Recommendations />
          </CustomCard>
        </GridItem>
      </GridContainer>
    </form>
  );
}

export default FormMain;
