/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import { configure } from "axios-hooks";
import axios from "axios";
import LRU from "lru-cache";
import App from "./App";
import "assets/scss/material-kit-react.scss?v=1.9.0";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
//axios.defaults.headers.get["Accept"] = "application/json";
//axios.defaults.headers.post["Accept"] = "application/json";

const cache = new LRU({ maxAge: 1000 * 60 * 60 });

configure({ axios, cache });

ReactDOM.render(<App />, document.getElementById("root"));
