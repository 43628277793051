import React, { useState } from "react";
import useAxios from "axios-hooks";
import PropTypes from "prop-types";

import { CircularProgress } from "@material-ui/core";
import { Box } from "@material-ui/core";

import { isValidPhone, validateCaptcha } from "helpers/functions.js";

import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import CustomVerifyCodeDialog from "components/Custom/CustomVerifyCodeDialog";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

export default function AddSecondaryPhone({
  setSecondaryPhonesList,
  secondaryPhonesList,
}) {
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [isOpenVerifyDialog, setIsOpenVerifyDialog] = useState(false);
  const [sessionToken, setSessionToken] = useState();

  const [{ loading: loadingAddPhone }, executeAddPhone] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user-profile/edit-phone/add",
      method: "POST",
    },
    { manual: true }
  );

  const handleAddSecondaryPhone = async () => {
    const token = await validateCaptcha("handleAddSecondaryPhone");

    const result = await executeAddPhone({
      data: {
        phone: phone,
        grecaptchaToken: token,
      },
    });

    setSessionToken(result.data.session_token);
    setIsOpenVerifyDialog(true);
  };

  const [{ loading: loadingVerifyCode }, executeVerifyCode] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user-profile/edit-phone/verify",
      method: "POST",
    },
    { manual: true }
  );

  const handleSubmitVerifyCodeDialog = async () => {
    const token = await validateCaptcha("handleSubmitVerifyCodeDialog");
    await executeVerifyCode({
      data: {
        code: code,
        sessionToken: sessionToken,
        grecaptchaToken: token,
      },
    });
    setSecondaryPhonesList([...secondaryPhonesList, phone]);
    setPhone("");
    setCode("");
    setIsOpenVerifyDialog(false);
  };

  const DescriptionVerifyCodeDialog = () => {
    return (
      <>
        יש להזין את קוד 4 הספרות אשר נשלח אליך לטלפון:{" "}
        <span style={{ fontWeight: "bold" }}>{phone}</span>
      </>
    );
  };

  const handleCloseVerifyCodeDialog = () => {
    setCode("");
    setIsOpenVerifyDialog(false);
  };

  return (
    <GridContainer alignItems="flex-end">
      <GridItem xs={12} sm={12} md={6}>
        <CustomInput
          labelText="הוספת טלפון משני (10 ספרות ללא מקף)"
          id="newPhone"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: "phone",
            value: phone,
            type: "tel",
            onChange: (e) => setPhone(e.target.value),
            inputProps: {
              "aria-required": true,
            },
          }}
          error={phone != "" && !isValidPhone(phone)}
        />

        <CustomVerifyCodeDialog
          open={isOpenVerifyDialog}
          title="קוד אימות"
          Description={DescriptionVerifyCodeDialog}
          handleSubmit={handleSubmitVerifyCodeDialog}
          handleCloseDialog={handleCloseVerifyCodeDialog}
          code={code}
          setCode={setCode}
          isLoading={loadingVerifyCode}
          disableBackdropClick
          disableEscapeKeyDown
        ></CustomVerifyCodeDialog>
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <Box style={{ paddingBottom: "5px" }}>
          <Button
            type="button"
            color="info"
            size="sm"
            disabled={!isValidPhone(phone) || loadingAddPhone}
            onClick={() => handleAddSecondaryPhone()}
          >
            <span>אימות</span>
            {loadingAddPhone && (
              <CircularProgress size={24} className="buttonProgress" />
            )}
          </Button>
        </Box>
      </GridItem>
    </GridContainer>
  );
}

AddSecondaryPhone.propTypes = {
  secondaryPhonesList: PropTypes.array,
  setSecondaryPhonesList: PropTypes.func,
};
