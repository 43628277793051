import React from "react";
import useAxios from "axios-hooks";

import { Receipt } from "@material-ui/icons";
import { IoIosSend } from "react-icons/io";
import { GiFireworkRocket, GiTrophy } from "react-icons/gi";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomCardIconSummary from "components/Custom/CustomCardIconSummary";

import SummarySkeleton from "components/Skeletons/SummarySkeleton";

export default function FriendSummary() {
  const [{ data, loading }] = useAxios({
    url: process.env.REACT_APP_API_URL + "friend/summary",
  });

  if (loading) return <SummarySkeleton />;

  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={3}>
        <CustomCardIconSummary
          headerTitle={"מועמדים ששלחת"}
          headerValue={data.candidate_count}
          headerIcon={<IoIosSend />}
          color={"warning"}
        />
      </GridItem>

      <GridItem xs={12} sm={6} md={3}>
        <CustomCardIconSummary
          headerTitle={"מועמדים על שמך"}
          headerValue={data.registered_candidate_count}
          headerIcon={<Receipt />}
          color={"danger"}
        />
      </GridItem>

      <GridItem xs={12} sm={6} md={3}>
        <CustomCardIconSummary
          headerTitle={"מועמדים בתהליך"}
          headerValue={data.inprocess_candidate_count}
          headerIcon={<GiFireworkRocket />}
          color={"success"}
        />
      </GridItem>

      <GridItem xs={12} sm={6} md={3}>
        <CustomCardIconSummary
          headerTitle={"מועמדים שהתקבלו"}
          headerValue={data.employee_count}
          headerIcon={<GiTrophy />}
          color={"info"}
        />
      </GridItem>
    </GridContainer>
  );
}
