import React from "react";
import { useNotify } from "../../context/Notify";
import Slide from "@material-ui/core/Slide";
import { IoMdAlert } from "react-icons/io";
import { IoHappyOutline } from "react-icons/io5";
import Snackbar from "components/Snackbar/Snackbar.js";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export default function CustomSnackBar() {
  const { getNotification, onClose } = useNotify();
  const notification = getNotification();
  return (
    <Snackbar
      place="bl"
      color={notification?.type == "error" ? "danger" : "success"}
      message={notification?.msg}
      open={notification?.show}
      onClose={onClose}
      autoHideDuration={5000}
      TransitionComponent={SlideTransition}
      className="customSnackbar"
      icon={notification?.type == "error" ? IoMdAlert : IoHappyOutline}
    />
  );
}
