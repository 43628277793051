import React from "react";
import useAxios from "axios-hooks";

import CustomAccordion from "components/CustomAccordion/CustomAccordion";
import CustomCard from "components/Custom/CustomCard";

import CardSkeleton from "components/Skeletons/CardSkeleton";

export default function FaqSubjects() {
  const [{ data, loading }] = useAxios({
    url: process.env.REACT_APP_API_URL + "faq/subjects",
  });

  if (loading)
    return (
      <>
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
      </>
    );

  return (
    <>
      {data.wp_faq_subjects.map((faqSubject, key) => {
        let h = key > 4 ? "h6" : `h${key + 2}`; // h2,h3,h4,h5,h6,...,h6
        return (
          <CustomCard
            style={{ marginBottom: "50px" }}
            key={key}
            titleTag={h}
            title={faqSubject.main_title}
            description={faqSubject.sub_title}
          >
            <CustomAccordion items={faqSubject.items} />
          </CustomCard>
        );
      })}
    </>
  );
}
