const customTooltipStyle = {
  tooltip: {
    backgroundColor: "#324149",
    color: "#fff",
    fontSize: "12px",
    padding: "10px",
  },
  tooltipArrow: {
    color: "#324149",
  },
  iconButtonRoot: {
    position: "absolute",
    top: "45px",
    right: "5px",
  },
  svgIconRoot: {
    color: "#324149",
    fontSize: "1rem",
  },
};

export default customTooltipStyle;
