import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";

import { swr } from "helpers/functions";

import CardSkeleton from "components/Skeletons/CardSkeleton";
import CustomCard from "components/Custom/CustomCard";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import PrimaryPhone from "./PrimaryPhone";
import SecondaryPhonesList from "./SecondaryPhonesList";
import AddSecondaryPhone from "./AddSecondaryPhone";

export default function CardEditPhone() {
  const [isDataLoadedFirstTime, setIsDataLoadedirstTime] = useState(true);
  const [primaryPhone, setPrimaryPhone] = useState("");
  const [secondaryPhonesList, setSecondaryPhonesList] = useState([]);
  const [contentSection, setContentSection] = useState();

  const [{ data, loading }, refetch] = useAxios({
    url: process.env.REACT_APP_API_URL + "user-profile/edit-phone",
  });

  useEffect(() => {
    if (data) {
      setContentSection(data.wp_content_section);
      setPrimaryPhone(data.cvneto_secondary_phones.primary_phone);
      setSecondaryPhonesList(
        data.cvneto_secondary_phones.additional_phones || []
      );
    }
  }, [data]);

  useEffect(() => {
    if (data && isDataLoadedFirstTime) {
      setIsDataLoadedirstTime(false);
      swr(refetch);
    }
  }, [data, isDataLoadedFirstTime]);

  if (loading && isDataLoadedFirstTime) return <CardSkeleton />;

  return (
    <CustomCard
      title={contentSection?.main_title}
      description={contentSection?.subtitle}
    >
      <form>
        <GridContainer>
          <GridItem xs={12}>
            <PrimaryPhone primaryPhone={primaryPhone} />
          </GridItem>

          <SecondaryPhonesList
            setSecondaryPhonesList={setSecondaryPhonesList}
            secondaryPhonesList={secondaryPhonesList}
            setPrimaryPhone={setPrimaryPhone}
            primaryPhone={primaryPhone}
          />
          <GridItem xs={12}>
            <AddSecondaryPhone
              setSecondaryPhonesList={setSecondaryPhonesList}
              secondaryPhonesList={secondaryPhonesList}
            />
          </GridItem>
        </GridContainer>
      </form>
    </CustomCard>
  );
}
