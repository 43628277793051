import React from "react";
import SummarySkeleton from "components/Skeletons/SummarySkeleton";
import TableSkeleton from "components/Skeletons/TableSkeleton";
import DropzoneSkeleton from "components/Skeletons/DropzoneSkeleton";

export default function FriendSkeleton() {
  return (
    <>
      <SummarySkeleton />
      <TableSkeleton />
      <DropzoneSkeleton />
    </>
  );
}
