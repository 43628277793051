import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import useFormContext from "../../FormContext";

import Button from "components/CustomButtons/Button";

import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

function AddButton({ name, label }) {
  const classes = useStyles();
  const { handleAdd } = useFormContext();
  return (
    <Button
      className={classes.addButton}
      type="button"
      color="info"
      size="sm"
      onClick={() =>
        handleAdd({
          name: name,
        })
      }
    >
      <span>{label}</span>
    </Button>
  );
}

AddButton.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default AddButton;
