import React from "react";
import useGaTracker from "hooks//useGaTracker";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import FaqSubjects from "./Subjects/FaqSubjects";
import FaqSidebar from "./Sidebar/FaqSidebar";

export default function Faq() {
  useGaTracker();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={9}>
        <FaqSubjects />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <FaqSidebar />
      </GridItem>
    </GridContainer>
  );
}
