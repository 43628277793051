import React, { forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle";
import Button from "components/CustomButtons/Button.js";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const useModalStyle = makeStyles(modalStyle);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CustomDialog(props) {
  /*------------------------------------*\
       STYLES
    \*------------------------------------*/
  const classes = useModalStyle();

  /*------------------------------------*\
       STATES
    \*------------------------------------*/
  const {
    open,
    title,
    description,
    fields,
    submitButton,
    handleCloseDialog,
    closeButtonLabel,
    hideCancelButton,
    ...rest
  } = props;

  const themeRtl = createTheme({
    direction: "rtl",
  });

  return (
    <ThemeProvider theme={themeRtl}>
      <Dialog
        className="dialog-container"
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseDialog()}
        aria-labelledby="update-comment-dialog-title"
        aria-describedby="update-comment-dialog-description"
        {...rest}
      >
        <DialogTitle disableTypography className={classes.modalHeader}>
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => handleCloseDialog()}
            style={{ left: "10px", right: "auto" }}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>{title}</h4>
        </DialogTitle>
        <DialogContent className={classes.modalBody}>
          {description}

          {fields}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {submitButton}

          {!hideCancelButton && (
            <Button onClick={() => handleCloseDialog()} color="danger">
              {closeButtonLabel || "ביטול"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
