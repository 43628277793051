import React, { useState } from "react";
import useAxios from "axios-hooks";
import ReactDOMServer from "react-dom/server";
import { Packer } from "docx";
import * as html2pdf from "html2pdf.js";

import useFormContext from "../../FormContext";

import MyDocx from "components/Custom/CreateDocx.js";
import MyDocument from "components/Custom/CreatePdf.js";
import CircularProgressButton from "components/Buttons/CircularProgressButton";

import CustomTooltip from "components/Custom/CustomTooltip";

import { makeStyles } from "@material-ui/core/styles";
import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

function DownloadPdfButton() {
  const classes = useStyles();
  const { handleGetState } = useFormContext();
  const [isLoading, setIsloading] = useState(false);

  const [, sendEmail] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "cv/send-email",
      method: "POST",
    },
    { manual: true }
  );

  const handleDownload = async () => {
    setIsloading(true);

    let html = ReactDOMServer.renderToStaticMarkup(
      <MyDocument data={handleGetState()} />
    );
    html = html.replace(/(\.|!|:|,|-)/g, "<span dir='ltr'>$1</span>");

    // const opt = {
    //   filename: "cv.pdf",
    //   image: { type: "jpeg", quality: 1 },
    //   jsPDF: {
    //     format: "a4",
    //     orientation: "portrait",
    //   },
    // };

    const opt = {
      margin: [15, 15],
      filename: "cv.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
      //pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    // send docx via email
    const doc = MyDocx({ data: handleGetState(), type: "download" });
    const blob = await Packer.toBlob(doc);
    let form_data = new FormData();
    form_data.append("file", blob);
    await sendEmail({ data: form_data });

    // download pdf
    html2pdf().from(html).set(opt).toPdf().get("pdf").save();

    setIsloading(false);
  };

  return (
    <div className={classes.sidebarButtonContainer}>
      <div className={classes.sidebarButtonTooltipContainer}>
        <CustomTooltip
          title={
            "כאן ניתן להוריד למחשבכם האישי את קורות החיים לקובץ מסוג PDF, הנתמך על ידי התוכנות הייעודיות לכך."
          }
          placement="bottom"
        />
      </div>
      <CircularProgressButton
        onClick={() => handleDownload()}
        color="danger"
        block
        title="הורדת קובץ PDF"
        isLoading={isLoading}
      />
    </div>
  );
}

export default DownloadPdfButton;
