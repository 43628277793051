import React from "react";
import useAxios from "axios-hooks";
import useGaTracker from "hooks//useGaTracker";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import MyProcessesEmpty from "./Empty/MyProcessesEmpty";
import MyProcessesTable from "./Table/MyProcessesTable";
import MyProcessesSkeleton from "./MyProcessesSkeleton";

export default function MyProcesses() {
  useGaTracker();
  const [{ data, loading }] = useAxios({
    url: process.env.REACT_APP_API_URL + "my-processes/view",
  });

  if (loading) return <MyProcessesSkeleton />;

  if (data.view_mode == "empty") return <MyProcessesEmpty />;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <MyProcessesTable />
      </GridItem>
    </GridContainer>
  );
}
