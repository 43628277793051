import React, { useState } from "react";
import useAxios from "axios-hooks";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { Box } from "@material-ui/core";

import { isValidEmail, validateCaptcha } from "helpers/functions.js";

import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import CustomVerifyCodeDialog from "components/Custom/CustomVerifyCodeDialog";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

const useStyles = makeStyles(() => ({
  button: {
    position: "relative",
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function AddSecondaryEmail({
  setSecondaryEmailsList,
  secondaryEmailsList,
}) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [isOpenVerifyDialog, setIsOpenVerifyDialog] = useState(false);
  const [sessionToken, setSessionToken] = useState();

  const [{ loading: loadingAddEmail }, executeAddEmail] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user-profile/edit-email/add",
      method: "POST",
    },
    { manual: true }
  );

  const handleAddSecondaryEmail = async () => {
    const token = await validateCaptcha("handleAddSecondaryEmail");

    const result = await executeAddEmail({
      data: {
        email: email,
        grecaptchaToken: token,
      },
    });
    setSessionToken(result.data.session_token);
    setCode(result.data.code);
    setIsOpenVerifyDialog(true);
  };

  const [{ loading: loadingVerifyCode }, executeVerifyCode] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user-profile/edit-email/verify",
      method: "POST",
    },
    { manual: true }
  );

  const handleSubmitVerifyCodeDialog = async () => {
    const token = await validateCaptcha("handleSubmitVerifyCodeDialog");
    await executeVerifyCode({
      data: {
        code: code,
        sessionToken: sessionToken,
        grecaptchaToken: token,
      },
    });
    setSecondaryEmailsList([...secondaryEmailsList, email]);
    setEmail("");
    setCode("");
    setIsOpenVerifyDialog(false);
  };

  const handleCloseVerifyCodeDialog = () => {
    setCode("");
    setIsOpenVerifyDialog(false);
  };

  const DescriptionVerifyCodeDialog = () => {
    return (
      <>
        יש להזין את קוד 4 הספרות אשר נשלח אליך לכתובת המייל:{" "}
        <span style={{ fontWeight: "bold" }}>{email}</span>
      </>
    );
  };

  return (
    <GridContainer alignItems="flex-end">
      <GridItem xs={12} sm={12} md={6}>
        <CustomInput
          labelText="הוספת כתובת מייל משנית"
          id="newEmail"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: "email",
            value: email,
            type: "email",
            onChange: (e) => setEmail(e.target.value),
            inputProps: {
              "aria-required": true,
            },
          }}
        />

        <CustomVerifyCodeDialog
          open={isOpenVerifyDialog}
          title="קוד אימות"
          Description={DescriptionVerifyCodeDialog}
          handleSubmit={handleSubmitVerifyCodeDialog}
          handleCloseDialog={handleCloseVerifyCodeDialog}
          code={code}
          setCode={setCode}
          isLoading={loadingVerifyCode}
          disableBackdropClick
          disableEscapeKeyDown
        ></CustomVerifyCodeDialog>
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        <Box style={{ paddingBottom: "5px" }}>
          <Button
            className={classes.button}
            type="button"
            color="info"
            size="sm"
            disabled={!isValidEmail(email) || loadingAddEmail}
            onClick={() => handleAddSecondaryEmail()}
          >
            <span>אימות</span>
            {loadingAddEmail && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </Box>
      </GridItem>
    </GridContainer>
  );
}

AddSecondaryEmail.propTypes = {
  secondaryEmailsList: PropTypes.array,
  setSecondaryEmailsList: PropTypes.func,
};
