import React from "react";
import PropTypes from "prop-types";
import useFormContext from "../../FormContext";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";

function DatePickerField({ fieldProps, itemProps }) {
  const { handleChange } = useFormContext();
  return (
    <CustomDatePicker
      datePickerProps={{
        selected: fieldProps.value ? new Date(fieldProps.value) : null,
        onChange: (newValue) => {
          const formattedDate = newValue ? newValue.toISOString() : "";
          handleChange({
            name: itemProps.name,
            newValue: formattedDate,
            subName: fieldProps.name,
            id: itemProps.id,
          });
        },
        showMonthDropdown: true,
        showYearDropdown: true,
        dropdownMode: "select",
      }}
      formControlProps={{
        fullWidth: true,
      }}
      labelText={fieldProps.label}
      inputProps={{
        name: fieldProps.name,
        id: fieldProps.id,
      }}
    />
  );
}

DatePickerField.propTypes = {
  fieldProps: PropTypes.object,
  itemProps: PropTypes.object,
};

export default DatePickerField;
