import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";
import ChatBubble from "react-chat-bubble";
import Moment from "moment";

import CustomCard from "components/Custom/CustomCard";
import ChatSkeleton from "components/Skeletons/ChatSkeleton";

import avatar from "assets/img/faces/avatar.png";
import logi from "assets/img/logi-square.png";

import AccessTime from "@material-ui/icons/AccessTime";

export default function InterviewChat() {
  const [chatMessages, setChatMessages] = useState([]);

  const [{ data, loading }] = useAxios({
    url: process.env.REACT_APP_API_URL + "interview/chat",
  });

  useEffect(() => {
    if (data) {
      let messages_filtered = [];
      data.cvneto_interview.Interview_details.map((message) => {
        const question = {
          type: 0,
          image: logi,
          text: (
            <div
              dangerouslySetInnerHTML={{
                __html: message.Question,
              }}
            ></div>
          ),
        };

        const answer = {
          type: 1,
          image: avatar,
          text: (
            <div
              dangerouslySetInnerHTML={{
                __html: message.Answer,
              }}
            ></div>
          ),
        };

        messages_filtered.push(question);
        messages_filtered.push(answer);
      });
      setChatMessages(messages_filtered);
    }
  }, [data]);

  const handleOnNewMessage = () => {};

  if (loading) return <ChatSkeleton />;

  return (
    <CustomCard
      title={data.wp_chat_section.main_title}
      description={data.wp_chat_section.subtitle}
      footerActions={
        <>
          <AccessTime />{" "}
          {Moment(data.cvneto_interview.Interview_date)
            .local()
            .format("DD/MM/YYYY HH:mm")}
        </>
      }
    >
      <ChatBubble messages={chatMessages} onNewMessage={handleOnNewMessage} />
    </CustomCard>
  );
}
