import React from "react";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import SidebarSkeleton from "components/Skeletons/SidebarSkeleton";
import ChatSkeleton from "components/Skeletons/ChatSkeleton";

export default function InterviewSkeleton() {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={9}>
        <ChatSkeleton />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <SidebarSkeleton />
      </GridItem>
    </GridContainer>
  );
}
