import React from "react";

import { FormProvider } from "./FormContext";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import FormMain from "./Components/Layouts/FormMain";
import FormContent from "./Components/Layouts/FormContent";
import FormSidebar from "./Components/Layouts/FormSidebar";

export default function CvGenerator() {
  return (
    <FormProvider>
      <FormContent />
      <GridContainer>
        <GridItem xs={12} sm={12} md={9}>
          <FormMain />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <FormSidebar />
        </GridItem>
      </GridContainer>
    </FormProvider>
  );
}
