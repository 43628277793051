import React, { useState } from "react";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import Moment from "moment";
import { CircularProgress } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import _ from "lodash";

import { Checkbox } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import Button from "components/CustomButtons/Button";

import { infoColor } from "assets/jss/material-dashboard-react";

import { Box, Grid } from "@material-ui/core";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomCheckbox = withStyles({
  root: {
    color: "#009999",
    "&$checked": {
      color: "#009999",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: infoColor[0],
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: infoColor[0],
    },
    "& .MuiInputBase-input": {
      fontWeight: 300,
      fontSize: ".875rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& .MuiFormLabel-root": {
      fontWeight: 300,
      fontSize: ".875rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: infoColor[0],
      },
      "&:hover fieldset": {
        borderColor: infoColor[0],
      },
      "&.Mui-focused fieldset": {
        borderColor: infoColor[0],
      },
    },
  },
})(TextField);

export const useStyles = makeStyles(() => ({
  drawerPaper: {
    width: 300,
    padding: 15,
  },
  button: {
    position: "relative",
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const isAssignOptions = [
  { label: "כן", value: "כן" },
  { label: "לא", value: "לא" },
];

const statusOptions = [
  { label: "קורות החיים התקבלו במערכת", value: 24 },
  { label: "בתהליך מיון", value: 23 },
  { label: "תהליך המיון של המועמד הופסק", value: 20 },
  { label: "התקבל", value: 5 },

  // { label: "ראיון פרונטאלי", value: 2 },
  // { label: "זימון ללקוח", value: 3 },

  // { label: "נחשף", value: 7 },
  // { label: "הצעת שכר", value: 9 },
  // { label: "הפניה לדרישה", value: 10 },
  // { label: "ראיון שני", value: 11 },
  // { label: "מ.ה/אמינות", value: 12 },
  // { label: "הצעת שכר", value: 19 },
  // { label: "נפסל", value: 20 },
  // { label: "משרה נסגרה", value: 21 },
  // { label: "התקבל – זכאי למענק", value: 22 },
];

const dateOptions = [
  {
    label: "אתמול",
    value: [
      Moment().subtract(1, "days").format("YYYY-MM-DD"),
      Moment().subtract(1, "days").format("YYYY-MM-DD"),
    ],
  },
  {
    label: "7 ימים אחרונים",
    value: [
      Moment().subtract(6, "days").format("YYYY-MM-DD"),
      Moment().format("YYYY-MM-DD"),
    ],
  },
  {
    label: "30 ימים אחרונים",
    value: [
      Moment().subtract(29, "days").format("YYYY-MM-DD"),
      Moment().format("YYYY-MM-DD"),
    ],
  },
  {
    label: "מתחילת החודש",
    value: [
      Moment().startOf("month").format("YYYY-MM-DD"),
      Moment().endOf("month").format("YYYY-MM-DD"),
    ],
  },
  {
    label: "חודש שעבר",
    value: [
      Moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
      Moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
    ],
  },
  {
    label: "3 חודשים אחרונים",
    value: [
      Moment().subtract(3, "month").startOf("month").format("YYYY-MM-DD"),
      Moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
    ],
  },
];

export default function FriendTableDrawer(props) {
  const {
    filter,
    setFilter,
    setIsDrawerOpen,
    isDrawerOpen,
    setPage,
    loading,
  } = props;
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState(filter.search);
  const [isAssignValue, setIsAssignValue] = useState(filter.isAssign);
  const [statusValue, setStatusValue] = useState(filter.status);
  const [dateValue, setDateValue] = useState(filter.date);

  const handleSubmit = () => {
    setPage(0);

    const search = searchValue;
    const isAssign =
      isAssignValue == null ? "" : isAssignValue.map((v) => v.value);
    const status = statusValue == null ? "" : statusValue.map((v) => v.label);
    const date = dateValue == null ? "" : dateValue.value;

    if (
      search == filter.search &&
      _.isEqual(isAssign, filter.isAssign) &&
      _.isEqual(status, filter.status) &&
      _.isEqual(date, filter.date)
    ) {
      setIsDrawerOpen(false);
    } else {
      setFilter({
        search: search,
        isAssign: isAssign,
        status: status,
        date: date,
      });
    }
  };

  const handleClear = () => {
    setSearchValue("");
    setIsAssignValue([]);
    setStatusValue([]);
    setDateValue([]);
  };

  return (
    <Drawer
      classes={{ paper: classes.drawerPaper }}
      anchor="right"
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
    >
      <Box
        style={{
          backgroundColor: "#324149",
          margin: "-15px -15px 15px -15px",
          padding: "15px",
          color: "#fff",
          textAlign: "center",
          position: "relative",
        }}
      >
        <span>סינון</span>
        <Button
          variant="outlined"
          size="sm"
          color="danger"
          onClick={() => handleClear()}
          style={{ position: "absolute", left: "15px", top: "8px" }}
        >
          ניקוי
        </Button>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CssTextField
            id="outlined-basic"
            label="שם המועמד"
            variant="outlined"
            fullWidth={true}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            id="combo-box-demo"
            options={dateOptions}
            getOptionLabel={(option) => (option.label ? option.label : "")}
            renderInput={(params) => (
              <CssTextField
                {...params}
                label="תאריך שליחה"
                variant="outlined"
              />
            )}
            onChange={(event, newValue) => {
              setDateValue(newValue);
            }}
            value={dateValue}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            fullWidth={true}
            multiple
            id="checkboxes-is-assign"
            options={isAssignOptions}
            disableCloseOnSelect
            getOptionLabel={(option) => (option.label ? option.label : "")}
            onChange={(event, newValue) => {
              setIsAssignValue(newValue);
            }}
            value={isAssignValue}
            renderOption={(option, { selected }) => (
              <>
                <CustomCheckbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </>
            )}
            renderInput={(params) => (
              <CssTextField
                {...params}
                variant="outlined"
                label="האם נרשם על שמך?"
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            fullWidth={true}
            multiple
            id="checkboxes-status"
            options={statusOptions}
            disableCloseOnSelect
            getOptionLabel={(option) => (option.label ? option.label : "")}
            onChange={(event, newValue) => {
              setStatusValue(newValue);
            }}
            value={statusValue}
            renderOption={(option, { selected }) => (
              <>
                <CustomCheckbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </>
            )}
            renderInput={(params) => (
              <CssTextField
                {...params}
                variant="outlined"
                label="סטטוס התקדמות המועמד"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Button color="info" block={true} onClick={handleSubmit}>
            <span>אישור</span>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
}

FriendTableDrawer.propTypes = {
  filter: PropTypes.any,
  setFilter: PropTypes.any,
  setIsDrawerOpen: PropTypes.any,
  isDrawerOpen: PropTypes.bool,
  setPage: PropTypes.any,
  loading: PropTypes.bool,
};
