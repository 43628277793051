import React from "react";
import { Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { grayColor } from "assets/jss/material-dashboard-react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomCheckbox = withStyles({
  root: {
    color: "#009999",
    "&$checked": {
      color: "#009999",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const CssTextField = withStyles({
  root: {
    margin: "27px 0 0 0",
    position: "relative",
    paddingBottom: "10px",
    verticalAlign: "unset",
    "& label.MuiInputLabel-formControl": {
      color: "#AAAAAA",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#eee",
    },
    "& .MuiInputBase-input": {
      fontWeight: 300,
      fontSize: "1rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& .MuiFormLabel-root": {
      fontWeight: 300,
      fontSize: ".875rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#eee",
      },
      "&:hover fieldset": {
        borderColor: "#eee",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#eee",
      },
    },
  },
})(TextField);

export default function CustomSelect(props) {
  const { label, autocompleteProps } = props;

  return (
    <Autocomplete
      renderInput={(params) => <CssTextField {...params} label={label} />}
      {...autocompleteProps}
    />
  );
}
