import React from "react";
import PropTypes from "prop-types";

import InputAdornment from "@material-ui/core/InputAdornment";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

function Endorment({ onClick }) {
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="בחירת מבנק אפשרויות"
        onClick={() => {
          onClick();
        }}
        edge="end"
      >
        <EditIcon />
      </IconButton>
    </InputAdornment>
  );
}

Endorment.propTypes = {
  onClick: PropTypes.func,
};

export default Endorment;
