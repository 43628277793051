const VALIDATION_FUNCTIONS = {
  required(val, errMsg) {
    return val ? null : errMsg;
  },
  pattern(pattern, val, errMsg) {
    return val && (pattern.test(val) ? null : errMsg);
  },
};

const VALIDATION_LIST = {
  privateDetails: {
    firstName: {
      required: (val) => VALIDATION_FUNCTIONS.required(val, "שדה חובה."),
      pattern: (val) =>
        VALIDATION_FUNCTIONS.pattern(
          /^[a-zA-Zא-ת\s-'"״]{2,30}$/,
          val,
          "שם פרטי לא תקין."
        ),
    },
    lastName: {
      required: (val) => VALIDATION_FUNCTIONS.required(val, "שדה חובה."),
      pattern: (val) =>
        VALIDATION_FUNCTIONS.pattern(
          /^[a-zA-Zא-ת\s-'"״]{2,30}$/,
          val,
          "שם משפחה לא תקין."
        ),
    },
    idCardNumber: {
      pattern: (val) =>
        VALIDATION_FUNCTIONS.pattern(
          /^\d{9}$/,
          val,
          "מספר תעודת זהות לא תקין."
        ),
    },
    phone: {
      required: (val) => VALIDATION_FUNCTIONS.required(val, "שדה חובה."),
      pattern: (val) =>
        VALIDATION_FUNCTIONS.pattern(/^\d{10}$/, val, "מספר סלולר לא תקין."),
    },
    email: {
      required: (val) => VALIDATION_FUNCTIONS.required(val, "שדה חובה."),
      pattern: (val) =>
        VALIDATION_FUNCTIONS.pattern(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
          val,
          "אימייל לא תקין."
        ),
    },
    summary: {
      required: (val) => VALIDATION_FUNCTIONS.required(val, "שדה חובה."),
    },
  },
};

export function validateInput(inputName, subName, val) {
  const validations =
    VALIDATION_LIST[inputName] !== undefined &&
    VALIDATION_LIST[inputName][subName] !== undefined
      ? VALIDATION_LIST[inputName][subName]
      : false;
  if (validations) {
    for (const rule of Object.values(validations)) {
      const isInvalid = rule(val);
      if (isInvalid) {
        return isInvalid;
      }
    }
  }
  return null;
}

export function validateForm(state, dispatch) {
  let isFormValid = true;
  for (const key in state) {
    const isArray = Array.isArray(state[key]);
    if (!isArray) {
      const inputName = key;
      for (const subKey in state[key]) {
        if (state[key] instanceof Object) {
          const subName = subKey;
          const value = state[key][subKey].value;

          const validations =
            VALIDATION_LIST[inputName] !== undefined &&
            VALIDATION_LIST[inputName][subName] !== undefined
              ? VALIDATION_LIST[inputName][subName]
              : false;
          if (validations) {
            for (const rule of Object.values(validations)) {
              const error = rule(value);
              if (error) {
                isFormValid = false;
                dispatch({
                  action: "UPDATE",
                  payload: {
                    error: error,
                    name: inputName,
                    subName: subName,
                    newValue: value,
                  },
                });
              }
            }
          }
        }
      }
    }
  }

  return isFormValid;
}
