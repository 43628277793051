import React from "react";

import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import PublicLayout from "layouts/Public.js";
import RtlLayout from "layouts/RTL.js";

import PrivateRoute from "helpers/PrivateRoute";
import { AuthProvider } from "context/Auth";

import "assets/css/material-dashboard-react.css?v=1.9.0";

import { create } from "jss";
import rtl from "jss-rtl";
import moment from "moment";
import "moment/locale/he";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { NotifyProvider } from "context/Notify";
import CustomSnackBar from "components/Custom/CustomSnackBar";
import WithAxios from "axios/WithAxios";
import { useScript } from "helpers/functions";

moment.locale("he");

function App() {
  useScript("https://cdn.popt.in/pixel.js?id=9143592ac5d90", "head", true);

  // jss
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  return (
    <StylesProvider jss={jss}>
      <NotifyProvider>
        <AuthProvider>
          <WithAxios>
            <CustomSnackBar />
            <Router>
              <Switch>
                <Route path="/p" component={PublicLayout} />
                <PrivateRoute path="/u" component={RtlLayout} />
                <Redirect from="/" to="/u/my-processes" />
              </Switch>
            </Router>
          </WithAxios>
        </AuthProvider>
      </NotifyProvider>
    </StylesProvider>
  );
}

export default App;
