import React, { forwardRef, useEffect, useRef } from "react";
import ReactCodeInput from "react-verification-code-input";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { CircularProgress } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import modalStyle from "../../assets/jss/material-dashboard-react/modalStyle";
import Button from "../CustomButtons/Button.js";

const useModalStyle = makeStyles(modalStyle);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useButtonStyle = makeStyles(() => ({
  button: {
    position: "relative",
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function CustomVerifyCodeDialog(props) {
  const buttonClasses = useButtonStyle();
  const classes = useModalStyle();
  const codeRef = useRef(undefined);
  const {
    open,
    handleSubmit,
    handleCloseDialog,
    setCode,
    code,
    Description,
    title,
    isLoading,
    disableBackdropClick,
    disableEscapeKeyDown,
    ...rest
  } = props;

  useEffect(() => {
    // if value was cleared, set key to re-render the element
    if (code?.length === 0) {
      clear();
      return;
    }

    // do something with the pin
  }, [code]);

  const clear = () => {
    if (codeRef?.current !== undefined) {
      codeRef.current.state.values[0] = "";
      codeRef.current.state.values[1] = "";
      codeRef.current.state.values[2] = "";
      codeRef.current.state.values[3] = "";
    }
  };

  // const insert = () => {
  //   if (codeRef?.current !== undefined) {
  //     codeRef.current.state.values = code.split("");
  //     return;
  //   }
  // };

  const handleClose = (event, reason) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return false;
    }

    if (disableEscapeKeyDown && reason === "escapeKeyDown") {
      return false;
    }

    if (typeof handleCloseDialog === "function") {
      handleCloseDialog();
    }
  };

  return (
    <>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        maxWidth="xs"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        {...rest}
      >
        <DialogTitle disableTypography className={classes.modalHeader}>
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => handleCloseDialog()}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>{title}</h4>
        </DialogTitle>
        <DialogContent className={classes.modalBody}>
          <Box style={{ marginBottom: "15px" }}>{<Description />}</Box>

          <ReactCodeInput
            ref={codeRef}
            className={"verification-code-input"}
            fields={4}
            onChange={(val) => setCode(val)}
            value={code}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => handleSubmit()}
            disabled={code?.length < 4 || isLoading}
            color="success"
          >
            <span>שליחה</span>
            {isLoading && (
              <CircularProgress
                size={24}
                className={buttonClasses.buttonProgress}
              />
            )}
          </Button>

          <Button onClick={() => handleCloseDialog()} color="danger">
            ביטול
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
