import React, { useState } from "react";
import CircularProgressButton from "components/Buttons/CircularProgressButton";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import useFormContext from "../../FormContext";
import CustomTooltip from "components/Custom/CustomTooltip";

import { makeStyles } from "@material-ui/core/styles";
import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

function ClearButton() {
  const classes = useStyles();
  const { handleClear } = useFormContext();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleConfirm = () => {
    handleClear();
    setOpenConfirmDialog(false);
  };

  return (
    <div className={classes.sidebarButtonContainer}>
      <div className={classes.sidebarButtonTooltipContainer}>
        <CustomTooltip
          title={"כאן תוכלו למחוק את הפרטים מכל השדות שזה עתה מילאתם."}
          placement="bottom"
        />
      </div>
      <CircularProgressButton
        onClick={() => setOpenConfirmDialog(true)}
        block
        title="ניקוי"
        isLoading={false}
      />
      <ConfirmDialog
        title="האם לנקות את הטופס?"
        description="במידה ואכן יש לנקות את הטופס נא ללחוץ על אישור."
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        handleConfirm={handleConfirm}
      />
    </div>
  );
}

export default ClearButton;
