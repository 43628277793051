import React from "react";
import PropTypes from "prop-types";

import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { MdCall, MdEmail } from "react-icons/md";
import { ImWhatsapp } from "react-icons/im";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardAvatar from "components/Card/CardAvatar.js";

import CustomFab from "./CustomFab";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle";
import avatar from "assets/img/faces/avatar.png";

const useStyles = makeStyles(styles);

export default function CustomCardProfile(props) {
  const { name, role, src, email, phone, whatsapp, cell } = props;

  const classes = useStyles();

  return (
    <Card profile>
      <CardAvatar profile>
        <img src={src || avatar} alt={name} />
      </CardAvatar>
      <CardBody profile style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <h6
          style={{ textTransform: "none", fontSize: "13px" }}
          className={classes.cardCategory}
        >
          {role}
        </h6>
        <h4 className={classes.cardTitle}>{name}</h4>

        {phone && (
          <Tooltip
            arrow
            id="tooltip-phone"
            title={`מספר הטלפון ${phone} של ${name} ${role}`}
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <CustomFab
              target="_blank"
              href={`tel:${phone}`}
              justIcon
              round
              color="info"
            >
              <MdCall />
            </CustomFab>
          </Tooltip>
        )}

        {email && (
          <Tooltip
            arrow
            id="tooltip-email"
            title={`כתובת המייל ${email} של ${name} ${role}`}
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <CustomFab
              target="_blank"
              href={`mailto:${email}`}
              justIcon
              round
              color="info"
            >
              <MdEmail />
            </CustomFab>
          </Tooltip>
        )}

        {cell && (
          <Tooltip
            arrow
            id="tooltip-whatsapp"
            title={`מספר הוואטסאפ ${cell} של ${name} ${role}`}
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <CustomFab
              target="_blank"
              href={`https://wa.me/${cell}?text=${whatsapp}`}
              justIcon
              round
              color="info"
            >
              <ImWhatsapp />
            </CustomFab>
          </Tooltip>
        )}
      </CardBody>
    </Card>
  );
}

CustomCardProfile.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  src: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  whatsapp: PropTypes.string,
  cell: PropTypes.string,
};
