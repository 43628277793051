import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
import HomeIcon from "@material-ui/icons/Home";

// core components
import Button from "components/CustomButtons/Button";

import logo from "assets/img/log-on.png";

// styles
import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";

const useStyles = makeStyles(styles);

export default function PublicNavbar(props) {
  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };
  const classes = useStyles();
  const { color } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  var list = (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <NavLink
          to={"/p/main"}
          className={cx(classes.navLink, {
            [classes.navLinkActive]: activeRoute("/p/main"),
          })}
          onClick={() => (isMobile ? handleDrawerToggle() : "")}
        >
          <HomeIcon className={classes.listItemIcon} />
          <ListItemText
            primary={"ראשי"}
            disableTypography={true}
            className={classes.listItemText}
          />
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          to={"/p/login"}
          className={cx(classes.navLink, {
            [classes.navLinkActive]: activeRoute("/p/login"),
          })}
          onClick={() => (isMobile ? handleDrawerToggle() : "")}
        >
          <Fingerprint className={classes.listItemIcon} />
          <ListItemText
            primary={"התחברות"}
            disableTypography={true}
            className={classes.listItemText}
          />
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          to={"/p/register"}
          className={cx(classes.navLink, {
            [classes.navLinkActive]: activeRoute("/p/register"),
          })}
          onClick={() => (isMobile ? handleDrawerToggle() : "")}
        >
          <PersonAdd className={classes.listItemIcon} />
          <ListItemText
            primary={"הרשמה"}
            disableTypography={true}
            className={classes.listItemText}
          />
        </NavLink>
      </ListItem>
    </List>
  );
  const logoUrl = process.env.REACT_APP_BASE_URL;
  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar
        className={classes.container}
        style={{ flexDirection: "row-reverse" }}
      >
        <Hidden smDown>
          <div className={classes.flex} style={{ textAlign: "left" }}>
            <a href="/p/main">
              <div className={classes.logoImage}>
                <img src={logo} alt="דף הבית לוג און" className={classes.img} />
              </div>
            </a>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={cx(classes.flex, classes.right)}>
            <Link to="/p/main">
              <div className={classes.logoImage}>
                <img src={logo} alt="דף הבית לוג און" className={classes.img} />
              </div>
            </Link>
          </div>
        </Hidden>
        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={"left"}
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {list}
            </Drawer>
          </Hidden>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

PublicNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
};
