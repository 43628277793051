import {
  defaultFont,
  container,
  infoColor,
  grayColor,
} from "assets/jss/material-dashboard-react.js";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "15px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    ...defaultFont,
    fontWeight: "500",
    fontSize: "12px",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    fontSize: "14px",
    float: "right!important",
  },
  center: {
    textAlign: "center",
    margin: "0px",
    padding: "0px",
  },
  footer: {
    bottom: "0",
    borderTop: "1px solid " + grayColor[11],
    padding: "15px 0",
    background: "#151b1e",
    color: "#fff",
    ...defaultFont,
  },
  a: {
    color: "#fff",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&:hover": {
      color: "#fff",
    },
  },
  copyright: {
    color: infoColor[1],
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
};
export default footerStyle;
