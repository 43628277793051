import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useAxios from "axios-hooks";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem";

import RepeaterField from "./RepeaterField";
import RepeaterAddButton from "../Buttons/RepeaterAddButton";
import RepeaterRemoveButton from "../Buttons/RepeaterRemoveButton";

import CustomTooltip from "components/Custom/CustomTooltip";
import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

function Repeater({
  title,
  items,
  fieldProps,
  itemProps,
  tooltipProps,
  addButtonTitle,
}) {
  const classes = useStyles();

  const [suggestions, setSuggestions] = useState([]);

  const [{ loading }, getSuggestions] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "cv/suggestions",
      params: {
        subject: itemProps.name,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    async function loadSuggestions() {
      const result = await getSuggestions();
      const options = result.data.wp_suggestions_data?.map((item) => {
        return item["suggestion"];
      });
      setSuggestions(options);
    }
    loadSuggestions();
  }, []);

  return (
    <>
      {items.length > 0 && (
        <div className={classes.subTitleContainer}>
          <div className={classes.subTitle}>
            <CustomTooltip title={tooltipProps.title} />
            {title}
          </div>
          {items.length < 10 && (
            <RepeaterAddButton
              name={itemProps.name}
              id={itemProps.id}
              subName={fieldProps.name}
              label={addButtonTitle}
            />
          )}
        </div>
      )}
      {items.map((item, key) => (
        <GridItem
          key={item.id}
          xs={12}
          sm={12}
          md={12}
          style={{ position: "relative" }}
        >
          <RepeaterField
            item={item}
            fieldProps={fieldProps}
            itemProps={itemProps}
            suggestions={suggestions}
          />
          {key > 0 && (
            <RepeaterRemoveButton
              name={itemProps.name}
              nameId={itemProps.id}
              subName={fieldProps.name}
              subNameId={item.id}
            />
          )}
        </GridItem>
      ))}
    </>
  );
}

Repeater.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  fieldProps: PropTypes.object,
  itemProps: PropTypes.object,
  tooltipProps: PropTypes.object,
  addButtonTitle: PropTypes.string,
};

export default Repeater;
