import React, { useRef, useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useAuth } from "context/Auth";
import SessionTimeoutDialog from "components/Dialogs/SessionTimeoutDialog.js";

export default function SessionTimeout() {
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const { isAuthenticated, handleLogout } = useAuth();
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const timer = useRef();

  const handleOnIdle = (event) => {
    if (isAuthenticated && !timeoutModalOpen) {
      let countDown = 120;
      setTimeoutModalOpen(true);
      setTimeoutCountdown(countDown);

      timer.current = setInterval(() => {
        if (countDown > 0) {
          setTimeoutCountdown(--countDown);
        } else {
          handleLogoutUser();
        }
      }, 1000);
    }
  };

  useEffect(() => {
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const handleLogoutUser = () => {
    setTimeoutModalOpen(false);
    clearInterval(timer.current);
    handleLogout("", true);
  };

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearInterval(timer.current);
  };

  return (
    <>
      <SessionTimeoutDialog
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onLogout={handleLogoutUser}
        open={timeoutModalOpen}
      />
    </>
  );
}
