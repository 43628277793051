import React from "react";
import useGaTracker from "hooks//useGaTracker";

import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";

import CardPrivate from "./PrivateData/CardPrivate";
import CardWorkExperience from "./WorkExperience/CardWorkExperience";

import CardEditEmail from "./EditEmail/CardEditEmail";
import CardEditPhone from "./EditPhone/CardEditPhone";
import Sidebar from "./Sidebar/Sidebar";

export default function MyProfile() {
  useGaTracker();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={9}>
        <GridContainer>
          <GridItem xs={12}>
            <CardPrivate />
          </GridItem>
          <GridItem xs={12}>
            <CardWorkExperience />
          </GridItem>
          <GridItem xs={12}>
            <CardEditEmail />
          </GridItem>
          <GridItem xs={12}>
            <CardEditPhone />
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem xs={12} sm={12} md={3}>
        <Sidebar />
      </GridItem>
    </GridContainer>
  );
}
