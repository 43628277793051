import React, { useState } from "react";
import PropTypes from "prop-types";
import ChartLimitInputField from "./ChartLimitInputField";
import Endorment from "../Buttons/Endorment";
import BankDrawer from "./BankDrawer";

function RepeaterField({ item, fieldProps, itemProps, suggestions }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <ChartLimitInputField
        fieldProps={{
          label: fieldProps.label,
          name: fieldProps.name,
          id: item.id,
          value: item.value,
          type: fieldProps.type,
          pos: item.id,
          ...fieldProps,
          endAdornment: (
            <Endorment
              onClick={() => {
                setIsDrawerOpen(true);
              }}
            />
          ),
        }}
        itemProps={{
          id: itemProps.id,
          name: itemProps.name,
        }}
      />

      <BankDrawer
        open={isDrawerOpen}
        setOpen={setIsDrawerOpen}
        currentItem={{
          name: itemProps.name,
          subName: fieldProps.name,
          id: itemProps.id,
          pos: item.id,
        }}
        options={suggestions}
      />
    </>
  );
}

RepeaterField.propTypes = {
  item: PropTypes.object,
  fieldProps: PropTypes.object,
  itemProps: PropTypes.object,
  suggestions: PropTypes.array,
};

export default RepeaterField;
