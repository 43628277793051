import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import PublicNavbar from "components/Navbars/PublicNavbar.js";
import PublicFooter from "components/Footer/PublicFooter.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import register from "assets/img/mountains.jpg";
import login from "assets/img/river.jpg";
import main from "assets/img/ring.jpg";

import "assetskit/scss/material-kit-pro-react.scss?v=1.10.0";

const overrides = {
  MuiSvgIcon: {
    root: {
      "body[dir=rtl] &": {
        transform: "scaleX(-1)",
      },
    },
  },
};

const themeRtl = createTheme({
  direction: "rtl",
  overrides,
});

const useStyles = makeStyles(styles);

export default function Public(props) {
  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/p") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBgImage = () => {
    if (window.location.pathname.indexOf("/p/register") !== -1) {
      return register;
    } else if (window.location.pathname.indexOf("/p/login") !== -1) {
      return login;
    } else if (window.location.pathname.indexOf("/p/main") !== -1) {
      return main;
    }
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i];
        }
      }
    }
    return activeRoute;
  };

  document.body.setAttribute("dir", "rtl");

  const activeRoute = getActiveRoute(routes);
  if (activeRoute.fullPage)
    return (
      <ThemeProvider theme={themeRtl}>
        <PublicNavbar brandText="זירת המועמדים" {...rest} />

        <div className={classes.wrapper} ref={wrapper}>
          <div
            className={classes.fullPage}
            style={{ backgroundImage: "url(" + getBgImage() + ")" }}
          >
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/p" to="/p/main" />
            </Switch>
            <PublicFooter rtlActive white />
          </div>
        </div>
      </ThemeProvider>
    );

  return (
    <ThemeProvider theme={themeRtl}>
      {/* <PublicNavbar brandText="זירת המועמדים" {...rest} /> */}
      <main ref={wrapper}>
        <Switch>
          {getRoutes(routes)}
          <Redirect from="/p" to="/p/main" />
        </Switch>
      </main>
      {/* <PublicFooter rtlActive white /> */}
    </ThemeProvider>
  );
}

Public.propTypes = {
  fullPage: PropTypes.bool,
};
