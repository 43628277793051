import React from "react";
import useAxios from "axios-hooks";
import { useParams } from "react-router";
import useGaTracker from "hooks//useGaTracker";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Banners from "components/Custom/Banners";
import CustomCardProfile from "components/Custom/CustomCardProfile";

import ProcessStepSkeleton from "./ProcessStepSkeleton";
import ProcessStepStatus from "./Status/ProcessStepStatus";
import ProcessStepCompany from "./Company/ProcessStepCompany";

export default function MyProcess() {
  useGaTracker();
  const { id } = useParams();

  const [{ data, loading }] = useAxios({
    url: process.env.REACT_APP_API_URL + "my-processes/step",
    params: {
      id: id,
    },
  });

  if (loading) return <ProcessStepSkeleton />;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={9}>
        <GridContainer>
          <GridItem xs={12}>
            <ProcessStepStatus
              step_friendly_name={data.cvneto_step_data.step_friendly_name}
              title={data.cvneto_step_data.position_name}
              description={data.cvneto_step_data.step_description}
              update_datetime={data.cvneto_step_data.Date}
              interview_datetime={data.cvneto_step_data.interview_date || ""}
              forms={data.wp_step_data.forms}
            />
          </GridItem>

          <GridItem xs={12}>
            <ProcessStepCompany
              title={data.cvneto_step_data.customer_name}
              description={data.cvneto_step_data.customer_description}
            />
          </GridItem>

          <GridItem xs={12}>
            <GridContainer justifyContent="center">
              <GridItem xs={12} sm={4} style={{ marginTop: "30px" }}>
                <CustomCardProfile
                  name={data.wp_step_data.owner.full_name}
                  role={data.wp_step_data.owner.role || "רכז/ת גיוס מטפל/ת"}
                  src={data.wp_step_data.owner.profile_image.url}
                  email={data.wp_step_data.owner.email}
                  phone={data.wp_step_data.owner.phone}
                  cell={data.wp_step_data.owner.cell}
                  whatsapp={`שלום, בקשר למשרה ${data.cvneto_step_data.position_name}`}
                ></CustomCardProfile>
              </GridItem>

              <GridItem xs={12} sm={4} style={{ marginTop: "30px" }}>
                <CustomCardProfile
                  name={data.wp_step_data.manager.full_name}
                  role={data.wp_step_data.manager.role || "מנהל/ת לקוח"}
                  src={data.wp_step_data.manager.profile_image.url}
                  email={data.wp_step_data.manager.email}
                  phone={data.wp_step_data.manager.phone}
                  cell={data.wp_step_data.manager.cell}
                  whatsapp={`שלום, בקשר למשרה ${data.cvneto_step_data.position_name}`}
                ></CustomCardProfile>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem xs={12} sm={12} md={3}>
        <Banners banners={data.wp_step_data.banners_sidebar} />
      </GridItem>
    </GridContainer>
  );
}
