import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Box } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

export default function SummarySkeleton() {
  return (
    <Box style={{ marginBottom: "30px" }}>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Skeleton variant="rect" height={200} />
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Skeleton variant="rect" height={200} />
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Skeleton variant="rect" height={200} />
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Skeleton variant="rect" height={200} />
        </GridItem>
      </GridContainer>
    </Box>
  );
}
