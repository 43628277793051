import React from "react";
import useFormContext from "../../FormContext";
import TextareaField from "../Fields/TextareaField";

export default function Summary() {
  const { handleGetState } = useFormContext();
  const { privateDetails } = handleGetState();
  const charsLimit = 400;

  return (
    <TextareaField
      fieldProps={{
        label: `תמצית (*)`,
        name: "summary",
        id: privateDetails.summary.id,
        value: privateDetails.summary.value,
        type: "text",
        rows: 5,
        required: true,
        error: privateDetails.summary.error,
        charsLimit: charsLimit,
      }}
      itemProps={{
        id: privateDetails.id,
        name: "privateDetails",
      }}
    />
  );
}
