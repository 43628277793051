import React, { useState } from "react";
import CircularProgressButton from "components/Buttons/CircularProgressButton";
import PreviewDialog from "components/Dialogs/PreviewDialogDocx";
import useFormContext from "../../FormContext";
import CustomTooltip from "components/Custom/CustomTooltip";

import { makeStyles } from "@material-ui/core/styles";
import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

function PreviewButton() {
  const classes = useStyles();
  const { handleGetState } = useFormContext();
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  return (
    <div className={classes.sidebarButtonContainer}>
      <div className={classes.sidebarButtonTooltipContainer}>
        <CustomTooltip
          title={"כאן תוכלו לראות את מסמך קורות החיים לפני הפקתו."}
          placement="bottom"
        />
      </div>
      <CircularProgressButton
        onClick={() => setOpenPreviewDialog(true)}
        color="warning"
        block
        title="תצוגה מקדימה"
        isLoading={false}
      />
      <PreviewDialog
        open={openPreviewDialog}
        setOpen={setOpenPreviewDialog}
        data={handleGetState()}
      />
    </div>
  );
}

export default PreviewButton;
