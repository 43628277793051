import React from "react";
import PropTypes from "prop-types";
import { FaBuilding } from "react-icons/fa";
import CustomCardIcon from "components/Custom/CustomCardIcon";

export default function ProcessStepCompany(props) {
  const { title, description } = props;

  return (
    <div className="process-card-company">
      <CustomCardIcon
        title={title}
        description={<div className="description">{description}</div>}
        icon={<FaBuilding />}
      />
    </div>
  );
}

ProcessStepCompany.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
