import React from "react";
import { Switch, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PrivateRoute from "../helpers/PrivateRoute";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import SessionTimeout from "components/Custom/SessionTimeout.js";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/rtlStyle.js";

import bgImage from "assets/img/logi-thumbnail.png";
//import bgImage from "assets/img/friends.png";
import logo from "assets/img/log-on.png";

const overrides = {
  MuiSvgIcon: {
    root: {
      "body[dir=rtl] &": {
        transform: "scaleX(-1)",
      },
    },
  },
};

const themeRtl = createTheme({
  direction: "rtl",
  overrides,
});

let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/u") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/u" to="/u/my-processes" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function RTL({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  document.body.setAttribute("dir", "rtl");

  let filteredRouts = routes.filter((item) => item.sidebar === true);

  return (
    <div className={classes.wrapper}>
      <SessionTimeout />
      <Sidebar
        routes={filteredRouts}
        logoText=""
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        rtlActive
        {...rest}
      />

      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          color={"danger"}
          rtlActive
          {...rest}
        />
        <ThemeProvider theme={themeRtl}>
          <main className={classes.content} id="main" role="main">
            <div className={classes.container}>{switchRoutes}</div>
          </main>
        </ThemeProvider>
        <Footer />
      </div>
    </div>
  );
}
