import React from "react";
import PropTypes from "prop-types";

import CustomDialog from "components/Custom/CustomDialog";
import Button from "components/CustomButtons/Button.js";

export default function ConfirmDialog({
  open,
  setOpen,
  title,
  description,
  handleConfirm,
}) {
  return (
    <>
      <CustomDialog
        handleCloseDialog={() => setOpen(false)}
        open={open}
        title={title}
        description={description}
        closeButtonLabel="סגירה"
        submitButton={
          <Button onClick={() => handleConfirm()} color="info">
            <span>אישור</span>
          </Button>
        }
      ></CustomDialog>
    </>
  );
}

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  submitButton: PropTypes.elementType,
  handleConfirm: PropTypes.func,
};
