const circularProgressButtonStyle = {
  progress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-12px",
    marginLeft: "-12px",
  },
};

export default circularProgressButtonStyle;
