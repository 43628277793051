import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";
import Moment from "moment";
import { isMobile } from "react-device-detect";

import Button from "components/CustomButtons/Button";
import FetchTable from "components/Table/FetchTable";
import CustomCard from "components/Custom/CustomCard";

import FriendTableDrawer from "./FriendTableDrawer";
import FriendTermsDialog from "components/Dialogs/FriendTermsDialog";
import TableSkeleton from "components/Skeletons/TableSkeleton";

const tableColumnConfig = [
  {
    Header: "תאריך שליחה",
    accessor: "source_date",
    sortable: true,
    Cell: ({ cell }) => {
      return Moment(cell.value).local().format("DD/MM/YYYY HH:MM");
    },
  },
  {
    Header: "שם המועמד ",
    accessor: "candidate_name",
    sortable: true,
  },
  {
    Header: "האם נרשם על שמך?",
    accessor: "candidate_type",
    sortable: true,
  },
  {
    Header: "סטטוס התקדמות המועמד",
    accessor: "candidate_status",
    sortable: true,
  },
  {
    Header: "פרטים נוספים",
    accessor: "candidate_description",
    sortable: false,
  },
];

export default function FriendTable() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState(["source_date", "desc"]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openTermsDialog, setOpenTermsDialog] = useState(false);

  const [filter, setFilter] = useState({
    search: "",
    isAssign: [],
    status: [],
    date: [],
  });

  const [{ data, loading }] = useAxios({
    url: process.env.REACT_APP_API_URL + "friend/processes",
    params: {
      page,
      limit,
      orderValue: order[0],
      orderDirection: order[1],
      filter,
    },
  });

  useEffect(() => {
    if (!loading && isDrawerOpen) {
      setIsDrawerOpen(false);
    }
  }, [loading, data]);

  const updatePagination = async (page, limit) => {
    setLimit(limit);
    setPage(page);
  };

  const headerActions = (
    <Button
      variant="outlined"
      size="lg"
      color="info"
      onClick={() => setIsDrawerOpen(true)}
    >
      סינון
    </Button>
  );

  const terms = (
    <a
      onClick={(e) => {
        e.preventDefault();
        setOpenTermsDialog(true);
      }}
      style={{
        fontSize: "14px",
        color: "rgba(255, 255, 255,.62)",
        textDecoration: "underline",
        ":hover": {
          cusor: "pointer",
        },
      }}
      href="#"
    >
      לתקנון המבצע לחץ/י כאן.
    </a>
  );

  if (loading && !data) return <TableSkeleton />;

  const description = isMobile ? (
    <>
      {data.wp_table_section.subtitle_mobile} {terms}
    </>
  ) : (
    <>
      {data.wp_table_section.subtitle} {terms}
    </>
  );
  return (
    <>
      <CustomCard
        titleTag={"h2"}
        title={data.wp_table_section.main_title}
        description={description}
        color={"success"}
        headerActions={headerActions}
      >
        <FetchTable
          className={"my-processes-table-container"}
          columns={tableColumnConfig}
          total={data.cvneto_friend_process?.total_row || 0}
          data={data.cvneto_friend_process?.model || []}
          updatePagination={updatePagination}
          page={page}
          limit={limit}
          loading={loading}
          setOrder={setOrder}
          order={order}
          summary={"טבלה המפרטת את התהליכים של המועמדים שהפנית עד כה"}
        />
        <FriendTableDrawer
          filter={filter}
          setFilter={setFilter}
          setIsDrawerOpen={setIsDrawerOpen}
          isDrawerOpen={isDrawerOpen}
          setPage={setPage}
          loading={loading}
        />
      </CustomCard>
      <FriendTermsDialog open={openTermsDialog} setOpen={setOpenTermsDialog} />
    </>
  );
}
