import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import CardFooter from "../Card/CardFooter.js";
import CardIcon from "../Card/CardIcon.js";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";

const useStyles = makeStyles(styles);

export default function CustomCardIcon(props) {
  const { title, description, icon, stats } = props;
  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="success" icon>
        <CardIcon color="success">{icon}</CardIcon>
      </CardHeader>
      <CardBody>
        <h4 className={classes.cardTitle}>{title}</h4>
        <div className={classes.cardCategory}>{description}</div>
      </CardBody>
      {stats && (
        <CardFooter chart>
          <div className={classes.stats} style={{ width: "100%" }}>
            {stats}
          </div>
        </CardFooter>
      )}
    </Card>
  );
}

CustomCardIcon.propTypes = {
  title: PropTypes.string,
  description: PropTypes.any,
  icon: PropTypes.any,
  stats: PropTypes.any,
};
