import React from "react";
import useAxios from "axios-hooks";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import CustomButtonBot from "components/Custom/CustomButtonBot";
import CustomCardVideo from "components/Custom/CustomCardVideo";
import CustomCardContent from "components/Custom/CustomCardContent";
import Banners from "components/Custom/Banners";

import EmptyViewSkeleton from "components/Skeletons/EmptyViewSkeleton";

export default function InterviewEmpty() {
  const [{ data, loading }] = useAxios({
    url: process.env.REACT_APP_API_URL + "interview/empty",
  });

  if (loading) return <EmptyViewSkeleton />;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <CustomCardContent
          titleTag={"h2"}
          title={data.content_section.main_title}
          description={data.content_section.sub_title}
          footerActions={<CustomButtonBot label="בצעו שיחה עם לוגי" />}
          content={data.content_section.content}
        />
        <GridContainer>
          <GridItem sm={9}>
            <CustomCardVideo
              titleTag={"h3"}
              title={data.video_section.main_title}
              description={data.video_section.sub_title}
              youtube_id={data.video_section.youtube_id}
            />
          </GridItem>
          <GridItem sm={3}>
            <Banners banners={data.banners_sidebar} />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}
