import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import CardFooter from "components/Card/CardFooter";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";

const useStyles = makeStyles(styles);

export default function CustomCard(props) {
  const {
    title,
    description,
    children,
    headerActions,
    footerActions,
    color,
    titleTag,
    ...rest
  } = props;
  const classes = useStyles();

  const TitleTag = titleTag || "h2";
  return (
    <Card {...rest}>
      <CardHeader color={color || "success"}>
        <TitleTag
          className={classes.cardTitleWhite}
          style={{ fontSize: "1.3em", maxWidth: "80%" }}
        >
          {title}
        </TitleTag>
        {React.isValidElement(description) ? (
          description
        ) : (
          <p
            dangerouslySetInnerHTML={{
              __html: description,
            }}
            className={classes.cardCategoryWhite}
          />
        )}

        <div className={classes.cardActions}>{headerActions}</div>
      </CardHeader>
      <CardBody>{children}</CardBody>
      {footerActions && (
        <CardFooter chart>
          <div className={classes.stats} style={{ width: "100%" }}>
            {footerActions}
          </div>
        </CardFooter>
      )}
    </Card>
  );
}

CustomCard.propTypes = {
  titleTag: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.any,
  headerActions: PropTypes.any,
  footerActions: PropTypes.any,
  children: PropTypes.any,
  color: PropTypes.string,
};
