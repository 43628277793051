import React, { useCallback, createContext, useContext } from "react";
import PropTypes from "prop-types";
import { useImmerReducer } from "use-immer";
import { DEFAULT_STATE, FormReducer } from "./state";
import { validateInput, validateForm } from "./validation";

export const FormContext = createContext(null);

export const FormProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(FormReducer, DEFAULT_STATE);

  const handleChange = useCallback(
    (args) => {
      const { name, newValue, subName } = args;
      const error = validateInput(name, subName, newValue);
      dispatch({
        action: "UPDATE",
        payload: { error, ...args },
      });
    },
    [dispatch]
  );

  const handleAdd = useCallback(
    (args) => {
      const { name, ...rest } = args;
      dispatch({
        action: "ADD",
        payload: { name, ...rest },
      });
    },
    [dispatch]
  );

  const handleRemove = useCallback(
    (args) => {
      const { name, ...rest } = args;
      dispatch({
        action: "REMOVE",
        payload: { name, ...rest },
      });
    },
    [dispatch]
  );

  const handleClear = useCallback(() => {
    dispatch({
      action: "CLEAR",
      payload: {},
    });
  }, [dispatch]);

  const handleSet = useCallback(
    (args) => {
      dispatch({
        action: "SET",
        payload: args,
      });
    },
    [dispatch]
  );

  const handleValidateForm = () => {
    validateForm(state, dispatch);
  };

  const handleGetState = () => {
    return state;
  };
  return (
    <FormContext.Provider
      value={{
        handleGetState,
        handleChange,
        handleAdd,
        handleRemove,
        handleClear,
        handleSet,
        handleValidateForm,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

FormProvider.propTypes = {
  children: PropTypes.any,
};

export default function useFormContext() {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
}
