/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={classes.footer} role="contentinfo">
      <div className={classes.container}>
        <p className={classes.center}>
          <a
            href="https://b.log-on.com/"
            target="_blank"
            title="לוג-און - נפתח בחלון חדש"
            className={classes.a}
            style={{ textDecoration: "underline" }}
          >
            לוג-און
          </a>
          ,<span> כל הזכויות שמורות </span>
          <span className={classes.copyright}>&copy; </span>
          <span>{1900 + new Date().getYear()}</span>
        </p>
        <p
          className={classes.center}
          style={{ fontSize: "9px", lineHeight: "14px", marginTop: "15px" }}
        >
          This site is protected by reCAPTCHA and the Google
          <br />
          <a
            className={classes.a}
            style={{ textDecoration: "underline" }}
            title="גוגל קאפצ'ה תנאי פרטיות - נפתח בחלון חדש"
            target="_blank"
            href="https://policies.google.com/privacy"
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            className={classes.a}
            style={{ textDecoration: "underline" }}
            title="גוגל קאפצ'ה תנאי שימוש - נפתח בחלון חדש"
            target="_blank"
            href="https://policies.google.com/terms"
          >
            Terms of Service
          </a>{" "}
          apply.
        </p>
      </div>
    </footer>
  );
}
