import React from "react";
import useAxios from "axios-hooks";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import CustomCardContent from "components/Custom/CustomCardContent";
import CardSkeleton from "components/Skeletons/CardSkeleton";

function FormContent() {
  const [{ data, loading }] = useAxios({
    url: process.env.REACT_APP_API_URL + "cv/content",
  });

  if (loading) return <CardSkeleton />;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <CustomCardContent
          titleTag={"h2"}
          title={data.wp_cv_content.main_title}
          description={data.wp_cv_content.sub_title}
          content={data.wp_cv_content.content}
        />
      </GridItem>
    </GridContainer>
  );
}

export default FormContent;
