import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import useFormContext from "../../FormContext";

import Button from "components/CustomButtons/Button";
import DeleteIcon from "@material-ui/icons/Delete";

import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

function RepeaterRemoveButton({ name, nameId, subName, subNameId, label }) {
  const classes = useStyles();
  const { handleRemove } = useFormContext();
  return (
    <Button
      justIcon
      round
      className={classes.repeaterRemoveButton}
      type="button"
      color="danger"
      size="sm"
      onClick={() =>
        handleRemove({
          name: name,
          id: nameId,
          subName: subName,
          subNameId: subNameId,
        })
      }
    >
      <DeleteIcon classes={{ root: classes.repeaterRemoveButtonSvgicon }} />
    </Button>
  );
}

RepeaterRemoveButton.propTypes = {
  name: PropTypes.string,
  nameId: PropTypes.string,
  subName: PropTypes.string,
  subNameId: PropTypes.string,
  label: PropTypes.string,
};

export default RepeaterRemoveButton;
