import React, { useEffect } from "react";
import PropTypes from "prop-types";

import CustomDialog from "components/Custom/CustomDialog";
import MyDocx from "components/Custom/CreateDocx.js";
import { renderAsync } from "docx-preview";
import { Packer } from "docx";

export default function PreviewDialogDocx(props) {
  const { open, setOpen, data } = props;

  useEffect(() => {
    async function display() {
      const doc = MyDocx({ data: data, type: "preview" });
      const blob = await Packer.toBlob(doc);
      renderAsync(
        blob,
        window.document.getElementById("docx-container")
        // undefined,
        // {
        //   debug: true,
        // }
      );
    }

    if (open) {
      display();
    }
  }, [open]);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <CustomDialog
      fields={<div id="docx-container"></div>}
      handleCloseDialog={handleCloseDialog}
      open={open}
      title="תצוגה מקדימה"
      description=""
      closeButtonLabel="סגירה"
      maxWidth="lg"
      fullWidth={true}
    ></CustomDialog>
  );
}

PreviewDialogDocx.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.any,
};
