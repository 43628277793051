import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function PublicFooter(props) {
  const classes = useStyles();
  const { fluid, white, rtlActive } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white,
    });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white,
  });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.center}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                className={block}
                href="https://b.log-on.com/about-log-on/?ref=zira-footer"
                target="_blank"
                rel="noopener noreferrer"
                title="אודות - נפתח בחלון חדש"
              >
                {rtlActive ? "אודות" : "About"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://b.log-on.com/category/%d7%91%d7%9c%d7%95%d7%92-%d7%90%d7%95%d7%9f/?ref=zira-footer"
                target="_blank"
                rel="noopener noreferrer"
                className={block}
                title="בלוג - נפתח בחלון חדש"
              >
                {rtlActive ? "בלוג" : "Blog"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://b.log-on.com/%d7%97%d7%99%d7%a4%d7%95%d7%a9-%d7%9e%d7%a9%d7%a8%d7%95%d7%aa/?ref=zira-footer"
                target="_blank"
                rel="noopener noreferrer"
                className={block}
                title="חיפוש משרות - נפתח בחלון חדש"
              >
                {rtlActive ? "חיפוש משרות" : "Search jobs"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://b.log-on.com/%d7%97%d7%91%d7%a8-%d7%9e%d7%91%d7%99%d7%90-%d7%97%d7%91%d7%a8/?ref=zira-footer"
                target="_blank"
                rel="noopener noreferrer"
                className={block}
                title="חבר מביא חבר - נפתח בחלון חדש"
              >
                {rtlActive ? "חבר מביא חבר" : "Friend"}
              </a>
            </ListItem>
          </List>
        </div>
        <p
          className={classes.center}
          style={{ marginBottom: "5px", fontSize: "11px" }}
        >
          <a
            href="https://b.log-on.com/?ref=zira-footer"
            target="_blank"
            rel="noopener noreferrer"
            title="לוג-און - נפתח בחלון חדש"
            className={classes.a}
            style={{ textDecoration: "underline" }}
          >
            לוג-און
          </a>
          ,<span> כל הזכויות שמורות </span>
          <span className={classes.copyright}>&copy; </span>
          <span>{1900 + new Date().getYear()}</span>
        </p>
        <p
          className={classes.center}
          style={{ fontSize: "9px", lineHeight: "14px", direction: "ltr" }}
        >
          This site is protected by reCAPTCHA and the Google{" "}
          <a
            className={classes.a}
            style={{ textDecoration: "underline" }}
            title="גוגל קאפצ'ה תנאי פרטיות - נפתח בחלון חדש"
            target="_blank"
            rel="noopener noreferrer"
            href="https://policies.google.com/privacy"
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            className={classes.a}
            style={{ textDecoration: "underline" }}
            title="גוגל קאפצ'ה תנאי שימוש - נפתח בחלון חדש"
            target="_blank"
            rel="noopener noreferrer"
            href="https://policies.google.com/terms"
          >
            Terms of Service
          </a>{" "}
          apply.
        </p>
      </div>
    </footer>
  );
}

PublicFooter.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
};
