import React, { useEffect, useReducer, useContext, createContext } from "react";
import useAxios, { clearCache } from "axios-hooks";
import PropTypes from "prop-types";

const initialState = {
  isAuthenticated: undefined,
  user: null,
  token: null,
  refUrl: "",
  isTimedOut: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    case "LOGOUT":
      return {
        isAuthenticated: false,
        user: null,
        token: null,
        refUrl: action.payload.refUrl,
        isTimedOut: action.payload.isTimedOut,
      };
    case "UPDATE_USER":
      return { ...state, user: action.payload.user };
    default:
      return state;
  }
};

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleLogout = (refUrl = "", isTimedOut = false) => {
    sessionStorage.clear();
    clearCache();
    dispatch({
      type: "LOGOUT",
      payload: { refUrl: refUrl, isTimedOut: isTimedOut },
    });
  };

  const handleLogin = (token, user) => {
    sessionStorage.setItem("token", JSON.stringify(token));
    dispatch({
      type: "LOGIN",
      payload: { user: user, token: token },
    });
  };

  const handleUpdateUser = (user) => {
    dispatch({
      type: "UPDATE_USER",
      payload: { user: user },
    });
  };

  const getToken = () => {
    return state.token;
  };

  const isAuthenticated = () => {
    return state.isAuthenticated;
  };

  const getUser = () => {
    return state.user;
  };

  const getRefUrl = () => {
    return state.refUrl;
  };

  const isTimedOut = () => {
    return state.isTimedOut;
  };

  const [, execute] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user/load",
    },
    { manual: true }
  );

  useEffect(() => {
    async function loadUser() {
      const result = await execute();
      //console.log("user", result.data.user);
      handleLogin(token, result.data.user);
    }

    const token = JSON.parse(sessionStorage.getItem("token"));
    if (token) {
      loadUser();
    } else {
      handleLogout(window.location.pathname, false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        handleLogin,
        handleLogout,
        handleUpdateUser,
        getToken,
        isAuthenticated,
        getUser,
        getRefUrl,
        isTimedOut,
        state,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

AuthProvider.propTypes = {
  children: PropTypes.any,
};
