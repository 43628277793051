import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";

import { makeStyles } from "@material-ui/core/styles";

import { IoMdClose } from "react-icons/io";
import { GrDocumentWord, GrDocumentPdf, GrUpload } from "react-icons/gr";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { useNotify } from "context/Notify";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    top: "-20px",
    right: "10px",
    padding: "2px",
  },
}));

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "40px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  margin: 10,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
};

const thumbInner = {
  textAlign: "center",
  overflow: "hidden",
  width: "100%",
};

const fileIcon = {
  fontSize: "60px",
};
const fileName = {
  maxWidth: "100%",
  margin: "0",
  padding: "0",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontSize: "12px",
};
const boxComment = {
  textAlign: "center",
};
const boxIcon = {
  textAlign: "center",
  fontSize: "35px",
  margin: "0px",
};

export default function CustomDropZone(props) {
  const { files, setFiles } = props;
  const classes = useStyles();
  const { setNotification } = useNotify();

  const style = useMemo(() => ({
    ...baseStyle,
  }));

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".pdf,.doc,.docx",
    maxFiles: 0,
    multiple: true,
    maxSize: 20000000,
    onDrop: useCallback(
      (acceptedFiles) => {
        if (acceptedFiles.length + files.length <= 10) {
          setFiles([...files, ...acceptedFiles]);
        } else {
          setNotification({
            show: true,
            type: "error",
            msg: "מספר הקבצים גדול מ-10.",
            onClose: null,
          });
        }
      },
      [files]
    ),
  });

  const handleRemoveFile = useCallback(
    (key) => {
      const filteredFiles = files.filter((value, index) => {
        return index !== key;
      });
      setFiles(filteredFiles);
    },
    [files]
  );

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={index}>
      <div style={thumbInner}>
        {file.type == "application/pdf" ? (
          <GrDocumentPdf style={fileIcon} />
        ) : (
          <GrDocumentWord style={fileIcon} />
        )}
        <div style={fileName}>{file.name}</div>
      </div>

      <Button
        onClick={() => handleRemoveFile(index)}
        color="danger"
        aria-label="הסרת קובץ"
        justIcon
        round
        size="sm"
        className={classes.root}
      >
        <IoMdClose />
      </Button>
    </div>
  ));

  return (
    <section className="dropzone-container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p style={boxIcon}>
          <CloudUploadIcon style={{ fontSize: "45px", color: "#324149" }} />
        </p>
        <p style={boxComment}>
          ניתן לגרור ולשחרר קבצים או להקליק כאן לבחירת קבצים.
          <br />
          פורמט קובץ מורשה: .pdf, doc, docx
          <br />
          ניתן לעלות עד 10 קבצים בבת אחת.
          <br />
          גודל קובץ מקסימאלי: 20 מגה.
        </p>
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
}

CustomDropZone.propTypes = {
  files: PropTypes.any,
  setFiles: PropTypes.any,
};
