import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";

import { CircularProgress } from "@material-ui/core";

import {
  swr,
  isValidIdNumber,
  isEmpty,
  hasNoDigits,
  validateCaptcha,
} from "helpers/functions";

import CustomInput from "components/CustomInput/CustomInput";
import CustomCard from "components/Custom/CustomCard";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import CardSkeleton from "components/Skeletons/CardSkeleton";

import CustomDatePicker from "components/Custom/CustomDatePicker";

export default function CardPrivate() {
  const [isValidForm, setIsValidForm] = useState(false);
  const [isDataLoadedFirstTime, setIsDataLoadedirstTime] = useState(true);
  const [privateSection, setPrivateSection] = useState();
  const [privateData, setPrivateData] = useState({
    firstName: "",
    lastName: "",
    birthdate: "",
    city: "",
    idCardNumber: "",
  });

  const [{ data, loading }, refetch] = useAxios({
    url: process.env.REACT_APP_API_URL + "user-profile/private",
  });

  useEffect(() => {
    if (data) {
      setPrivateData({
        firstName: data.cvneto_private_data.firstName,
        lastName: data.cvneto_private_data.lastName,
        birthdate: data.cvneto_private_data.birthdate
          ? new Date(data.cvneto_private_data.birthdate)
          : "",
        city: data.cvneto_private_data.city || "",
        idCardNumber: data.cvneto_private_data.idCardNumber || "",
      });
      setPrivateSection(data.wp_private_section);
    }
  }, [data]);

  useEffect(() => {
    if (data && isDataLoadedFirstTime) {
      setIsDataLoadedirstTime(false);
      swr(refetch);
    }
  }, [data, isDataLoadedFirstTime]);

  const [{ loading: postLoading }, execute] = useAxios(
    {
      url: process.env.REACT_APP_API_URL + "user-profile/private",
      method: "POST",
    },
    { manual: true }
  );

  const handleSubmit = async () => {
    const token = await validateCaptcha("handleSubmit");
    const result = await execute({
      data: {
        privateData: privateData,
        grecaptchaToken: token,
      },
    });
  };

  const handleChange = (event) => {
    setPrivateData({
      ...privateData,
      [event.target.name]: event.target.value || "",
    });
  };

  const handleChangeDate = (date) => {
    setPrivateData({
      ...privateData,
      birthdate: date ? date : "",
    });
  };

  useEffect(() => {
    if (
      (!isEmpty(privateData.idCardNumber) &&
        !isValidIdNumber(privateData.idCardNumber)) ||
      isEmpty(privateData.firstName) ||
      isEmpty(privateData.lastName) ||
      !hasNoDigits(privateData.firstName) ||
      !hasNoDigits(privateData.lastName) ||
      !hasNoDigits(privateData.city)
    ) {
      setIsValidForm(false);
    } else {
      setIsValidForm(true);
    }
  }, [privateData]);

  if (loading && isDataLoadedFirstTime) return <CardSkeleton />;

  return (
    <CustomCard
      titleTag={"h2"}
      title={privateSection?.main_title}
      description={privateSection?.subtitle}
    >
      <form>
        <GridContainer>
          {/* first name */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="שם פרטי (*)"
              id="firstName"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "firstName",
                type: "text",
                value: privateData.firstName,
                onChange: (event) => handleChange(event),
                inputProps: {
                  "aria-required": true,
                },
              }}
              error={isEmpty(privateData.firstName)}
            />
          </GridItem>

          {/* last name */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="שם משפחה (*)"
              id="lastName"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "lastName",
                type: "text",
                value: privateData.lastName,
                onChange: (event) => {
                  handleChange(event);
                },
                inputProps: {
                  "aria-required": true,
                },
              }}
              error={isEmpty(privateData.lastName)}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          {/* id number */}
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="ת.ז (9 ספרות)"
              id="idCardNumber"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "idCardNumber",
                type: "text",
                value: privateData.idCardNumber,
                onChange: (event) => handleChange(event),
              }}
              error={
                privateData.idCardNumber != "" &&
                !isValidIdNumber(privateData.idCardNumber)
              }
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <CustomDatePicker
              value={privateData?.birthdate || null}
              onChange={handleChangeDate}
              formControlProps={{
                fullWidth: true,
              }}
              id="birthdate"
              labelText="תאריך לידה (dd/mm/yyyy)"
              inputProps={{
                name: "birthdate",
              }}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </GridItem>

          {/* address */}
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="עיר מגורים (ללא ספרות)"
              id="city"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "city",
                type: "text",
                value: privateData.city,
                onChange: (event) => handleChange(event),
              }}
              error={privateData.city != "" && !hasNoDigits(privateData.city)}
            />
          </GridItem>

          {/* submit */}
          <GridItem container justifyContent="flex-end">
            <Button
              onClick={() => handleSubmit()}
              style={{ marginTop: "25px" }}
              color={"info"}
              disabled={!isValidForm || postLoading}
            >
              <span>עדכון</span>
              {postLoading && (
                <CircularProgress size={24} className="buttonProgress" />
              )}
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    </CustomCard>
  );
}
