import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import useFormContext from "../../FormContext";
import { educationStudyTypeOptions, generateYearsOptions } from "../../options";

import InputField from "../Fields/InputField";
import SelectField from "../Fields/SelectField";
import Repeater from "../Fields/Repeater";
import AddButton from "../Buttons/AddButton";
import RemoveButton from "../Buttons/RemoveButton";

import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

export default function Educations() {
  const classes = useStyles();
  const { handleGetState } = useFormContext();
  const { educations } = handleGetState();
  return (
    <>
      {educations.map((item, i) => (
        <GridContainer key={i} className={classes.border}>
          {i > 0 && (
            <GridItem xs={12} sm={12}>
              <RemoveButton
                name="educations"
                id={item.id}
                label="השכלה נוספת"
              />
            </GridItem>
          )}

          <GridItem xs={12} sm={12} md={6}>
            <InputField
              fieldProps={{
                label: "מוסד - תיכון/מכללה/אוניברסיטה",
                name: "institution",
                id: item.institution.id,
                value: item.institution.value,
                type: "text",
              }}
              itemProps={{
                id: item.id,
                name: "educations",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <SelectField
              fieldProps={{
                label: "סוג לימודים",
                name: "studyType",
                id: item.studyType.id,
                value: item.studyType.value,
                options: educationStudyTypeOptions,
              }}
              itemProps={{
                id: item.id,
                name: "educations",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <InputField
              fieldProps={{
                label: "מגמה/תואר ב-",
                name: "degree",
                id: item.degree.id,
                value: item.degree.value,
                type: "text",
              }}
              itemProps={{
                id: item.id,
                name: "educations",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <InputField
              fieldProps={{
                label: "התמחות ב-",
                name: "specialization",
                id: item.specialization.id,
                value: item.specialization.value,
                type: "text",
              }}
              itemProps={{
                id: item.id,
                name: "educations",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <SelectField
              fieldProps={{
                label: "שנת התחלה",
                name: "startYear",
                id: item.startYear.id,
                value: item.startYear.value,
                options: generateYearsOptions(),
              }}
              itemProps={{
                id: item.id,
                name: "educations",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <SelectField
              fieldProps={{
                label: "שנת סיום",
                name: "endYear",
                id: item.endYear.id,
                value: item.endYear.value,
                options: generateYearsOptions(),
              }}
              itemProps={{
                id: item.id,
                name: "educations",
              }}
            />
          </GridItem>

          <Repeater
            title="הישגים בולטים בתקופת הלימודים"
            items={item.achievements}
            fieldProps={{
              label: "הישג בולט",
              name: "achievements",
              type: "text",
              charsLimit: 100,
            }}
            itemProps={{
              id: item.id,
              name: "educations",
            }}
            tooltipProps={{
              title:
                "סעיף זה הוא המקום למלא פרטים אודות הישגים בתקופת הלימודים כגון הצטיינות, התמחויות והרחבות, פרסים, ממוצע ציונים, פרויקטים בולטים ועוד.",
            }}
            addButtonTitle="הוספת הישג"
          />
        </GridContainer>
      ))}

      {educations.length < 10 && (
        <AddButton name="educations" label="הוספת השכלה" />
      )}
    </>
  );
}
