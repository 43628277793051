import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useFormContext from "../../FormContext";
import cvGeneratorStyle from "assets/jss/material-dashboard-react/views/cvGeneratorStyle.js";
const useStyles = makeStyles(cvGeneratorStyle);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#009999",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#009999",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function SwitchField({ fieldProps, itemProps }) {
  const classes = useStyles();
  const { handleChange } = useFormContext();

  return (
    <FormControlLabel
      className={classes.switchLabel}
      control={
        <IOSSwitch
          className={classes.switchButton}
          checked={fieldProps.value}
          onChange={(e) =>
            handleChange({
              name: itemProps.name,
              newValue: e.target.checked || false,
              subName: fieldProps.name,
              id: itemProps.id,
              pos: fieldProps.pos,
            })
          }
          name={fieldProps.name}
        />
      }
      label="המלצות יינתנו על פי דרישה"
    />
  );
}

SwitchField.propTypes = {
  fieldProps: PropTypes.object,
  itemProps: PropTypes.object,
};

export default SwitchField;
